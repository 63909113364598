import { useEffect, useState } from "react";
import styles from "./wrapper/wrapper.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import OfflineProgram from "./programs/OfflineProgram";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import ReplyIcon from "@mui/icons-material/Reply";
import { Button, Box, TextField } from "@mui/material";
import DispProgram from "./programs/DispProgram";
import OnlineProgram from "./programs/OnlineProgram";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CreateProgram, fetchCoach } from "../../../Api";
import { notifyError, notifySuccess } from "../../../Notify";
interface NewUser {
  pImg: File | null;
}
const Programs = () => {
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [activescreen, setActivescreen] = useState(1);
  const [ptype, setptype] = useState(1);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [discost, setDiscost] = useState("");
  const [overallCost, setoverallCost] = useState("");
  const [duration, setDuration] = useState("");
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });

  const [programId, setProgramId] = useState();
  const [handleSeach, sethandleSearch] = useState("");
  const [coachData, setCoachData] = useState<string[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [AassignedCoach, setassignedCoach] = useState<any[]>([]);
  const [DataForm, setDataForm] = useState("");
  const [load, setUpLoad] = useState(false)

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });
    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };

  const handleAddCoach = (coachId: string) => {
    if (!AassignedCoach.includes(coachId)) {
      setassignedCoach([...AassignedCoach, coachId]);
    }
  };

  const handleRemoveCoach = (coachId: string) => {
    setassignedCoach(AassignedCoach.filter((id) => id !== coachId));
  };

  const assignedCoach = JSON.stringify(AassignedCoach);

  useEffect(() => {
    fetchCoach(cookie, 1, "", 1)
      .then((result: any) => {
        //console.log("Coach data:", result);
        setCoachData(result.data.history);
      })
      .catch((error: any) => {

        notifyError(error.response.data.message);
      });
  }, [cookie, load]);


  const handlSubmit = (e: any) => {
    e.preventDefault();
    setUpLoad(true)
    const formData: any = new FormData();
    formData.append("ptype", ptype);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("overallCost", overallCost);
    formData.append("disCost", discost);
    formData.append("assignedCoach", assignedCoach);
    formData.append("programImg", newUser.pImg);
    formData.append("duration", duration);
    CreateProgram(formData, cookie)
    .then((result: any) => {
        setUpLoad(false)
        //console.log(result);
        setDataForm(result);
        // notifySuccess(result.message);

        ptype === 1
          ? setProgramId(() => programId)
          : setProgramId(() => programId);

        ptype === 1
          ? notifySuccess("Live program Added Successfully!")
          : notifySuccess("Recorded program Added Successfully!");
        handleClose();
        setDescription('')
        setName('')
        setoverallCost('')

      })

      .catch((error) => {
        //console.log(error);
        notifyError(error.response.data.message);
        setUpLoad(false)
      });
  
  };

  return (
    <>
      <div className={styles.mainContiner}>
        <div className={styles.searchT}>
          <div className={styles.searchTex} style={{marginBottom:"30px"}}>
            {activescreen == 2 && (
              <FastRewindIcon
                className={styles.searchTexIcon}
                onClick={() => setActivescreen(1)}
              />
            )}
            {activescreen == 3 && (
              <FastRewindIcon
                className={styles.searchTexIcon}
                onClick={() => setActivescreen(1)}
              />
            )}

{activescreen == 1&& ( <input
              className={styles.container2}
              type="text"
              value={handleSeach}
              onChange={(e) => sethandleSearch(e.target.value)}
              placeholder="Search Program here"
              style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
            />)}
            <div style={{ display: "flex", flexDirection: "row", marginBottom:'20px' }}></div>
          </div>

          <div className="addButton" style={{marginRight:'20px'}}>
            <div className={styles.upgradeButton} >
            {activescreen == 1&&(  <Button onClick={handleOpen}>
                <AddIcon className={styles.upgradeBtn} />
                Add new Program
              </Button>)}
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: "scroll", overflowY: "scroll" }}
              >
                <Box className={styles.BoxStyle}>
                  <span onClick={handleClose} className={styles.HandleClose}>
                    <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    <h3> Create New Program</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={styles.typoDesc}
                  >
                    <div className={styles.ProgramProp}>
                      <div className={styles.onlinePro}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleProfile}
                          id="fileInput"
                          name="profile"
                        />

                        {!previewImage ? (
                          <>
                            <img
                              src="/images/Admin/unnamed.jpg"
                              className={styles.progProfile}
                            />
                            <div className={styles.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={previewImage}
                              alt="Preview"
                              className={styles.MainImgPre}
                            />
                            <div className={styles.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        )}
                        <div className={styles.onlineRadioBtn}>
                          <p
                            onClick={() => setptype(1)}
                            className={`${styles.RadioNonActive} ${ptype === 1 && styles.RadioActive
                              }`}
                          >
                            <p></p> Add Live Class{" "}
                          </p>
                          <p
                            className={`${styles.RadioNonActive} ${ptype === 2 && styles.RadioActive
                              }`}
                            onClick={() => setptype(2)}
                          >
                            <p></p>Add Recorded Class{" "}
                          </p>
                        </div>
                        {ptype === 1 && (
                          <>
                            <form
                              className={styles.programForm}
                              onSubmit={handlSubmit}
                            >
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label="Program Topic"
                                variant="outlined"
                                name="name"
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                sx={{
                                  my: 1,
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                                required
                              />

                              <TextArea
                                id="outlined-basic"
                                placeholder="Description"
                                name="description"
                                value={description}
                                onChange={(e: any) =>
                                  setDescription(e.target.value)
                                }
                                rows={5}
                              />
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label=" Cost"
                                variant="outlined"
                                value={discost}
                                onChange={(e: any) =>
                                  setDiscost(e.target.value)
                                }
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                                required
                              />
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label="Discount Cost"
                                variant="outlined"
                                name="overallCost"
                                value={overallCost}
                                onChange={(e: any) =>
                                  setoverallCost(e.target.value)
                                }
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                                required
                              />
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label="Program Duration (in Days)"
                                variant="outlined"
                                name="overallCost"
                                value={duration}
                                onChange={(e: any) =>
                                  setDuration(e.target.value)
                                }
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                                required
                              />

                              <h6>Add Coaches</h6>
                              <div className={styles.AddCoachList}>
                                {coachData.length > 0 ? (
                                  coachData.map((coach: any, index: number) => (
                                    <>
                                      <div className={styles.CoachlstP}>
                                        <p>
                                          <img
                                            src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                                            className={styles.coachImg}
                                          />
                                          {coach.name}{" "}
                                          <p className={styles.Coachlstemal}>
                                            {" "}
                                            {coach.email}
                                          </p>
                                        </p>

                                        <p>
                                          {AassignedCoach.includes(
                                            coach._id
                                          ) ? (
                                            <CloseTwoToneIcon
                                              className={
                                                styles.CoachlstIconClose
                                              }
                                              onClick={() =>
                                                handleRemoveCoach(coach._id)
                                              }
                                            />
                                          ) : (
                                            <AddIcon
                                              className={styles.CoachlstIcon}
                                              onClick={() =>
                                                handleAddCoach(coach._id)
                                              }
                                            />
                                          )}
                                        </p>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <p>No coach data available</p>
                                )}
                              </div>
                              <button className={styles.SaveButton}>
                                Next
                              </button>
                            </form>
                          </>
                        )}
                        {ptype === 2 && (
                          <>
                            <form
                              className={styles.programForm}
                              onSubmit={handlSubmit}
                            >
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label="Program Topic"
                                variant="outlined"
                                // value={newUser.phoneNo}
                                // onChange={handleChange}
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                                required
                              />

                              <TextArea
                                id="outlined-basic"
                                placeholder="Description"
                                name="description"
                                rows={5}
                              />

                              <TextField
                                type="text"
                                id="outlined-basic"
                                label=" Cost"
                                variant="outlined"
                                // value={newUser.phoneNo}
                                // onChange={handleChange}
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                required
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                              />
                              <TextField
                                type="text"
                                id="outlined-basic"
                                label="Discount Cost"
                                variant="outlined"
                                name="overallCost"
                                value={overallCost}
                                onChange={(e: any) =>
                                  setoverallCost(e.target.value)
                                }
                                className={styles.Inputfield}
                                InputProps={{
                                  className: `${styles.muiInput}`,
                                }}
                                required
                                sx={{
                                  my: 1,
                                  borderRadius: "20px",
                                  "& .MuiInputBase-input": {
                                    width: "100%",
                                  },
                                  "& .MuiInputLabel-root": {
                                    backgroundColor: "#fff",
                                  },
                                }}
                              />

                              <button className={styles.SaveButton}>
                                Submit
                              </button>
                              <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                              Cancel</button>
                            </form>
                            {/* onClick={()=>{setActivescreen(3);handleClose();}} */}
                          </>
                        )}
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
        {activescreen === 1 && (
          <DispProgram
            setActivescreen={setActivescreen}
            setProgramId={setProgramId}
            handleSeach={handleSeach}
          />
        )}
        {activescreen === 2 && (
          <OnlineProgram
            setActivescreen={setActivescreen}
            programId={programId}
          />
        )}
        {activescreen === 3 && (
          <OfflineProgram
            setActivescreen={setActivescreen}
            programId={programId}
          />
        )}
        <div></div>
      </div>
    </>
  );
};

export default Programs;
{
  /* <div className={styles.ProgramProp}>
                      <div className={styles.onlinePro} onClick={onlineServe}>
                        <img src="/images/Admin/onlineLect.png" />
                        <p>Online Program</p>
                      </div>
                      <div className={styles.onlinePro} onClick={offlineServe}>
                        <img src="/images/Admin/ofllineLect.png" />
                        <p>Recorded Program</p>
                      </div>
                    </div> */
}
