import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../redux/userSlice';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch(logout());
        navigate('/')
    };

    useEffect(() => {
        handleLogout()
    }, [])
    return <></>
}

export default Logout