import { useEffect, useState } from "react";
import style from "./landing.module.css";
import Home from "../Home";
import AboutUs from "../AboutUs";
import BehaveGame from "../BehaveGame";
import Services from "../Services";
import Blogs from "../Blogs";
import ContactUs from "../ContactUs";
import { useNavigate } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { TextField } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TermsCondi from "../TermsCondi";
import PrivPoli from "../PrivPoli";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@mui/icons-material/Menu";
const LandingWrapper = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState: any) => !prevState);
  };
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = () => {
    navigate("/signup");
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.bkTop}>
          <img
            src="/images/svg/Coach/shapebk.svg"
            className={style.bkTopInner}
            alt=""
          />
        </div>
        <div className={style.foreGround}>
          <>
            <div className={style.HamburgderContent}>
              <button className="hamburger" onClick={toggleMenu}>
                <MenuIcon />
              </button>
              <div
                className={style.hamburger}
                style={{ display: isOpen ? "block" : "none" }}
              >
                <nav>
                  <a
                    id="home"
                    onClick={() => {
                      setActiveTab(1);
                      setIsOpen(false);
                    }}
                  >
                    Home
                  </a>
                  <a
                    id="about"
                    onClick={() => {
                      setActiveTab(2);
                      setIsOpen(false);
                    }}
                  >
                    About Us
                  </a>
                  <a
                    id="about"
                    onClick={() => {
                      setActiveTab(4);
                      setIsOpen(false);
                    }}
                  >
                    Services
                  </a>
                  <a
                    id="contact"
                    onClick={() => {
                      setActiveTab(5);
                      setIsOpen(false);
                    }}
                  >
                    Blogs
                  </a>
                  <a
                    id="contact"
                    onClick={() => {
                      setActiveTab(6);
                      setIsOpen(false);
                    }}
                  >
                    Contact
                  </a>
                  <button className={style.letsBtn1} onClick={handleClick}>
                    Let's get started!
                  </button>
                </nav>
              </div>
            </div>
            <div className={style.navbar}>
              <img
                src="/images/Coach/login/white-logo.png"
                className={style.navbarImg}
                alt=""
              />

              <ul className={style.TopNavContent}>
                <li
                  onClick={() => setActiveTab(1)}
                  className={` ${activeTab === 1 && style.tabActive1}`}
                >
                  Home
                </li>
                <li
                  onClick={() => setActiveTab(2)}
                  className={` ${activeTab === 2 && style.tabActive1}`}
                >
                  About Us
                </li>
                <li
                  onClick={() => setActiveTab(3)}
                  className={` ${activeTab === 3 && style.tabActive1}`}
                >
                  Behavioural Games
                </li>
                <li
                  onClick={() => setActiveTab(4)}
                  className={` ${activeTab === 4 && style.tabActive1}`}
                >
                  Services
                </li>
                <li
                  onClick={() => setActiveTab(5)}
                  className={` ${activeTab === 5 && style.tabActive1}`}
                >
                  Blogs
                </li>
                <li
                  onClick={() => setActiveTab(6)}
                  className={` ${activeTab === 6 && style.tabActive1}`}
                >
                  Contact Us
                </li>
              </ul>

              <button className={style.letsBtn} onClick={handleClick}>
                Let's get started!
              </button>
            </div>
            <div></div>
          </>
          {activeTab === 1 && <Home setActiveTab={setActiveTab} />}
          {activeTab === 2 && <AboutUs setActiveTab={setActiveTab} />}
          {activeTab === 3 && <BehaveGame setActiveTab={setActiveTab} />}
          {activeTab === 4 && <Services setActiveTab={setActiveTab} />}
          {activeTab === 5 && <Blogs setActiveTab={setActiveTab} />}
          {activeTab === 6 && <ContactUs setActiveTab={setActiveTab} />}
          {activeTab === 7 && <TermsCondi setActiveTab={setActiveTab} />}
          {activeTab === 8 && <PrivPoli setActiveTab={setActiveTab} />}
        </div>
      </div>
      <footer className={style.footer}>
        <div className={style.footerLogo}>
          <img src="/images/Coach/login/white-logo.png" alt="" />
        </div>
        <div className={style.footerIcons}>
          <div className={style.footerIconsContent}>
            <div className={style.LeftfooterIcons}>
              <h1>We help you to grow, so get started</h1>
              <p>
                <PinDropIcon className={style.LeftImgIconFoot} />
                4517 Washington Ave. Manchester, Kentucky 39495
              </p>
              <p>
                <LocalPhoneIcon className={style.LeftImgIconFoot} />
                12345 67890
              </p>
            </div>
            <div className={style.RightfooterIcons}>
              <div className={style.RightfooterContent}>
                <TextField
                  // id="full-width-text-field"
                  type="email"
                  label="Enter Your Email "
                  sx={{
                    my: 1,
                    "& .MuiInputBase-input": {
                      width: "100%",
                    },
                    "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    },
                  }}
                  // variant="standard"
                  className={style.Rightfooterinput}
                />
                <button className={style.RightfooterBtn}>Subscribe</button>
              </div>
              <div className={style.Rightfootertabs}>
                <div className={style.RightfootertabsContent}>
                  <p
                    onClick={() => {
                      setActiveTab(1);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    {" "}
                    Home
                  </p>
                  <p
                    onClick={() => {
                      setActiveTab(2);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    About Us
                  </p>
                </div>

                <div className={style.RightfootertabsContent}>
                  <p
                    onClick={() => {
                      setActiveTab(4);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Services
                  </p>
                  <p
                    onClick={() => {
                      setActiveTab(5);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Blogs
                  </p>
                  <p
                    onClick={() => {
                      setActiveTab(6);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Contact Us
                  </p>
                </div>
              </div>
              <div
                className={style.Rightfootertabsb}
                style={{ justifyContent: "end", marginRight: "20px" }}
              >
                <p onClick={() => setActiveTab(3)}>Behavioural Games</p>
              </div>
              <div
                className={style.Rightfootertabsb}
                style={{ marginRight: "-20px" }}
              >
                <p onClick={() => setActiveTab(7)}>Terms & Conditions</p>
                <p onClick={() => setActiveTab(8)}>Privacy Policy</p>
              </div>

              <div className={style.RightfooterIconsb}>
                <FacebookIcon className={style.RightfooterIconsU} />
                <InstagramIcon className={style.RightfooterIconsU} />
                <TwitterIcon className={style.RightfooterIconsU} />
                <YouTubeIcon className={style.RightfooterIconsU} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.footercopyRight}>
          <p>© Copyright 2024, All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default LandingWrapper;
