import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { fetchCoachByCoachee, fetchCoachByCoacheeCopy } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import ShowCoach from "./Recommend/ShowCoach";
import Pagination from "../../Pagination/Pagination";
const MyCoach = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, seCoachData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [coachId, setCoachId] = useState("")
  const[page,setPage]=useState(1);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  useEffect(() => {
    fetchCoachByCoacheeCopy(search,data.page,cookie)
      .then((result: any) => {
       
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };
        setData(x);
        seCoachData(result.data.history)


      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie,page]);
 
  const handleShow = (uId: any) => {
    setActiveTab(1)
    setCoachId(uId)

  }
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  return (
    <>
      {activeTab == 0 && (<>

        <div className={styles.dashcontainer}>
          <div className={styles.UpcomingContainer}>
            <div className={styles.UpcomingP}>
              <p>My Coaches list</p>
            </div>

          </div>
          <div className={styles.DashForRecom}>
            <div className={styles.Recommond}>
              {coachData.length > 0 ? (coachData.map((cdata: any) =>
                <div className={styles.coaching} onClick={() => handleShow(cdata.uid._id)} key={`${cdata.uid._id}_Coach_Details`}>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.uid.profileImg}`}
                    className={styles.coachingImg}
                  />
                  <div className={styles.coachingTxt} >
                    <p style={{fontSize:'20px',fontWeight:'500'}}>{cdata.uid.name}</p>
                    <div className={styles.category} style={{marginTop:'-10px'}}>
                      <p style={{fontWeight:'500', color:'#F89C72' }}> {cdata.behavioralIdentity.slice(0,2).join("  ")}</p>
                    </div>
                    <div className={styles.coachEmail} style={{marginTop:'-10px'}}>
                      <p style={{fontWeight:'500', color:'#1A5298' }}>{cdata.uid.email.slice(0, 18)}..</p>
                    </div>

                  </div>
                </div>

              )) : (<>No coach Data Found!</>)}
            </div>
          </div>
          <div className={styles.pagination}>
          <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
        </div>


      </>)} {activeTab === 1 && (<ShowCoach setActiveTab={setActiveTab} coachId={coachId} />)}

    </>
  );
};

export default MyCoach;
