import React, { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { findMaxOccurrence } from "../../../utils/MaxOccurance";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { sendQuadrentCoach, sendQuadrentCoachee } from "../../../Api";
import { notifyError, notifySuccess } from "../../../Notify";
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';
const Assessment = () => {
  const loaderUrl = `/Build/abcd.loader.js`;
  const dataUrl = `/Build/abcd.data.unityweb`;
  const frameworkUrl = `/Build/abcd.framework.js.unityweb`;
  const codeUrl = `/Build/abcd.wasm.unityweb`;
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const role = user?.role;

  const [isLoading, setIsLoading] = useState(true);
  const [optionsSelected, setOptionsSelected] = useState<any[]>([]);
  const [isFullScreen, setIsFullScreen] = useState(false); 
  const { unityProvider, loadingProgression } = useUnityContext({
    loaderUrl: loaderUrl,
    dataUrl: dataUrl,
    frameworkUrl: frameworkUrl,
    codeUrl: codeUrl,
  });

  useEffect(() => {
    const checkLoadingProgress = () => {
      if (loadingProgression >= 1) {
        setIsLoading(false);
      }
    };

    const interval = setInterval(checkLoadingProgress, 100);
    return () => clearInterval(interval);
  }, [loadingProgression]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data && event.data.type === "SelectedOption") {
        //console.log("Option Received From Unity:", event.data.index);
        setOptionsSelected((prevState: any) => [
          ...prevState,
          event.data.index,
        ]);
      }
      if (
        event.data &&
        event.data.type === "GameEnd" &&
        event.data.index === "true"
      ) {
        setTimeout(() => {
          SendQuadrantToServer();
        }, 5000);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [unityProvider, optionsSelected]);

  const QuardentMap: any = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
  };

  const SendQuadrantToServer = () => {
    const { maxChar }: any = findMaxOccurrence(optionsSelected);
    const quadrant = QuardentMap[maxChar];
    //console.log(quadrant);
    if (role === 1) {
      sendQuadrentCoach(cookie, quadrant)
        .then((res: any) => {
          notifySuccess(res.data.message);
        })
        .catch((err: any) => {
          notifyError(err.response.data.message);
        });
    }
    if (role === 2) {
      sendQuadrentCoachee(cookie, quadrant)
        .then((res: any) => {
          notifySuccess(res.data.message);
        })
        .catch((err: any) => {
          notifyError(err.response.data.message);
        });
    }
  };

  // Full-Screen Toggle Function
  const toggleFullScreen = () => {
    const unityContainer:any = document.getElementById("unity-container");
    
    if (unityContainer) {
      if (!isFullScreen) {
      
        if (unityContainer.requestFullscreen) {
          unityContainer.requestFullscreen();
        } else if (unityContainer.mozRequestFullScreen) {
          unityContainer.mozRequestFullScreen(); // Firefox
        } else if (unityContainer.webkitRequestFullscreen) {
          unityContainer.webkitRequestFullscreen(); // Chrome and Safari
        } else if (unityContainer.msRequestFullscreen) {
          unityContainer.msRequestFullscreen(); // IE/Edge
        }
      }
      setIsFullScreen(!isFullScreen); // Toggle the full-screen state
    } else {
      console.error("Unity container not found");
    }
  };
  

  return (
    <div className="container">
      <div className="unity-container" id="unity-container" style={{position:'relative'}}>
        {isLoading && (
          <div className="loading-overlay">
            Loading... {Math.round(loadingProgression * 100)}%
          </div>
        )}
        <Unity unityProvider={unityProvider} className="unity-canvas" />
      </div>
     
      <button className="fullscreen-toggle" onClick={toggleFullScreen} style={{position:'absolute',bottom:0,right:0,backgroundColor:'#0b3467',border:'none',borderRadius:'20px',color:'white',padding:'10px'}} >
      <FullscreenTwoToneIcon/>
      </button>
    </div>
  );
};

export default Assessment;
