import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { truncate } from "fs/promises";

type props = {
  children: React.ReactNode;

};
const NotAuthenticatedLayout = ({ children }: props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigator = useNavigate();
 //console.log()
  const [allowView, setAllowView] = useState(false);
  //console.log("from NotAuthenticated status:", user?.profileSetup);
  useEffect(() => {
    if (user) {
     
      if (user?.account_status == 0 || !user?.account_status) {
        navigator("/verify");
      } else if (!user?.profileSetup || user?.profileSetup != true ) {
        
        navigator("/setup");
      } else if (user?.account_status === 1) {
        navigator("/setup");
      } else if (user?.account_status === 2) {
        //console.log("from NotAuthenticated status:", user?.profileSetup);
        navigator("/dashboard");
      }
     
      else {
        navigator("/");
      }
    } else {
      setAllowView(true);
      
    }
  
  }, [user]);

  return <>{allowView && <>{children}</>}</>;
};

export default NotAuthenticatedLayout;
