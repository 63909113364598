import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import styles from "./css/AdminManage.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "./wrapper/wrapper.module.css";
import style from "./programs/programs.module.css";
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { Box, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AddAdmin, DeleteAdmin, fetchAdmin } from "../../../Api";
import dayjs from "dayjs";
import { cdn_Link } from "../../../config";
import { Cancel } from "@mui/icons-material";
import { notifyError, notifySuccess } from "../../../Notify";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Pagination from "../../Pagination/Pagination";

interface NewUser {
  pImg: File | null;
}

const AdminManagement = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [adminData, setAdminData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Adminid, setAdminId] = useState('');
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [updata, setUpdata] = useState(false);
  const [myTimeOut, setMytimeOut] = useState<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });


  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const deleteAdmin = (e: any, adminId: any) => {
    e.preventDefault();
    setAdminId(adminId);
    handleOpen1();
  };

  const DeleteAdminId = (e: any) => {
    e.preventDefault();
    DeleteAdmin(Adminid, cookie)
      .then((res: any) => {
        notifySuccess(res.message);
        setUpdata(true);
        handleClose1()
      })
      .catch((err: any) => {
        notifyError(err.response.data.message);
        setUpdata(true);
        handleClose1()
      });
    setUpdata(false);
  };

  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });
    //console.log("files", file);

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  // Formik setup
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
    }),
    onSubmit: (values) => {
      //console.log("Form values: ", values);
      const formData: any = new FormData();
      formData.append("firstName", values.firstName)
      formData.append("lastName", values.lastName)
      formData.append("email", values.email)
      formData.append("phoneno", values.phone)
      formData.append("role", user?.role)
      formData.append("profile", newUser.pImg)
      AddAdmin(formData, cookie)
        .then((res: any) => {
          notifySuccess(res.message)
          //console.log("user Added")


          handleClose();
        })
        .catch((Err: any) => {
          notifyError(Err.response.data.message)
        })
    },
  });
  const fetchAdmins = () => {

    fetchAdmin(0, search, data.page, cookie)
      .then((result: any) => {
        //console.log(result)
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };
        setAdminData(x.history)
        setData(x);

      })
  }

  useEffect(() => {
    fetchAdmins();
  }, [cookie, updata, data.page]);

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    if (myTimeOut) clearTimeout(myTimeOut);
    setMytimeOut(
      setTimeout(() => {
        fetchAdmins();
      }, 500)
    );
  };

  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };


  return (
    <>
      <div className={styles.AdminManage}>
        <div className={styles.AdminManageHead}>
          <input
            className={styles.container2}
            type="text"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search user name here"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <button onClick={handleOpen}><AddIcon /> Add New Admin</button>
        </div>

        {/* Create New Admin Modal */}
        <div className={styled.upgradeButton}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={styled.BoxStyle}>
              <span onClick={handleClose} className={styled.HandleClose}>
                <CloseTwoToneIcon className={styled.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={styled.thisData}>
                  <h5>Create New Admin</h5>
                </div>
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styled.typoDesc}
              >
                <form className={styled.programForm} onSubmit={formik.handleSubmit}>
                  <div className={styled.ProgramProp}>
                    <div className={styled.onlinePro} >
                      <div className={styled.profileImg}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleProfile}
                          id="fileInput"
                          name="profile"
                        />
                        {!previewImage ? (
                          <>
                            <img
                              src="/images/Admin/unnamed.jpg"
                              className={styled.progProfile}
                            />
                            <div className={styled.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={previewImage}
                              alt="Preview"
                              className={styled.MainImgPre}
                            />
                            <div className={styled.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.inputTxtContent}>
                    <TextField
                      type="text"
                      id="firstName"
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                      className={styles.Inputfield}
                      InputProps={{
                        className: `${styled.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                      required
                    />
                    <TextField
                      type="text"
                      id="lastName"
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                      className={styles.Inputfield}
                      InputProps={{
                        className: `${styled.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                      required
                    />
                  </div>

                  <div className={styles.inputTxtContent}>
                    <TextField
                      type="email"
                      id="email"
                      label="Email"
                      variant="outlined"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      className={styles.Inputfield}
                      InputProps={{
                        className: `${styled.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                      required
                    />
                    <TextField
                      type="text"
                      id="phone"
                      label="Phone No"
                      variant="outlined"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      className={styles.Inputfield}
                      InputProps={{
                        className: `${styled.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                      required
                    />
                  </div>
                  <br />
                  <button className={styled.SaveButton} type="submit">
                    Save
                  </button>
                  <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                  Cancel</button>
                </form>
              </Typography>
            </Box>
          </Modal>
        </div>

        {/* Admin List and Delete Modal */}
        <div className={styles.AdminManageDisp}>
          <div className={style.programContent} >
            {adminData.length > 0 ? (
              adminData.map((admin: any, index: number) => (
                <div className={style.DisplayProg} key={admin._id}>

                  <div className={admin.account_status == 4 ? style.progContentOpacity : style.progContent}>
                    {admin.profileImg ? (
                      <img src={`${cdn_Link}/${admin.profileImg}`} />
                    ) : (
                      <img src="./images/Coachee/p6.png" />
                    )}

                    <div className={style.programText}>
                      <div className={styles.adminData}>
                        {/* {admin.account_status} */}

                        <h6>{admin.name.length > 20 ? `${admin.name.slice(0, 19)}...` : admin.name}</h6>
                        {/* {user?._id && admin._id != user._id &&
                          <DeleteIcon className={styles.adminDataIcon} onClick={(e: any) => deleteAdmin(e, admin._id)} />
                        } */}
                      </div>

                      <p className={styles.adminDataclr}><EmailIcon /> {admin.email.length > 20 ? `${admin.email.slice(0, 19)}...` : admin.email}</p>
                      <p className={styles.adminDatacl}><CheckCircleIcon /> {dayjs(admin.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styled.upgradeButton}>

          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={styled.BoxStyle}>
              <span onClick={handleClose1} className={styled.HandleClose}>
                <CloseTwoToneIcon className={styled.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={styled.thisData}>
                  <h5> Delete Admin</h5>

                </div>

              </Typography>
              <Typography id="modal-modal-description"
              >

                Are you sure Delete Admin? <br />
                <button className={styles.delete} onClick={(e: any) => DeleteAdminId(e)}><DeleteIcon /> Delete </button>
                <button className={styles.DontDelete} onClick={handleClose1}>Cancel </button>
              </Typography>
            </Box>
          </Modal>
        </div>
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
    </>
  );
};

export default AdminManagement;
