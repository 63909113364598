import style from "./wrapper/wrapper.module.css";
import styles from "./css/Home.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Cancel } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { Button, Box, TextField } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import TextArea from "antd/es/input/TextArea";
import { notifyError, notifySuccess } from "../../../Notify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CreateFAQ, DeleteFAQ, fetchFAQ, UpdateFAQ } from "../../../Api";
const FAQEdit = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setData] = useState({
    title: '',
    content: ''
  })
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("")
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [quest, setQuest] = useState(false);
  const [gid, setGid] = useState("")
  const [fid, setFid] = useState('')

  const [faq, setFaq] = useState<any[]>([]);

  const handleDelete = (e: any, gid: any) => {
    e.preventDefault();
    setFid(gid)
    handleOpen2()

  }
  const deleteFAQ = (e: any) => {
    e.preventDefault();
    DeleteFAQ(fid, cookie)
      .then((result: any) => {
        //console.log(result);
        setQuest(prev => !prev);
        notifySuccess(result.message)
        handleClose2()
      })

      .catch((error) => {
        //console.log(error);
        handleClose2()
      });
  }
  const updateFAQ = (e: any) => {
    e.preventDefault();
    UpdateFAQ(gid, data.title, data.content, cookie)
      .then((result: any) => {
        setQuest(prev => !prev);
        notifySuccess(result.message)

        handleClose1()
      })

      .catch((error) => {
        //console.log(error);
      });
  }
  const handleGid = (e: any, id: any, title: any, content: any) => {
    e.preventDefault();
    handleOpen1();
    setGid(id)
    setData({
      title: title,
      content: content
    })
  }

  const programFAQ = (e: any) => {
    e.preventDefault();

    CreateFAQ(question, answer, cookie)
      .then((result: any) => {
        setQuest(prev => !prev);
        notifySuccess(result.message)
        handleClose();
        setAnswer("");
        setQuestion("")
      })

      .catch((error) => {
        //console.log(error);
        notifyError(error.response.data.message)
      });
  }

  useEffect(() => {
    fetchFAQ(cookie)
      .then((result: any) => {
        setFaq(result.data.FAQ);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error.response.data.message);
      });
  }, [cookie, quest]);

  return (
    <>
      <div className={style.mainContiner}>
        <div className={style.addButton}>
          <h6>All FAQ's List</h6>
          <div className={style.upgradeButton}>

            <Button onClick={handleOpen}>
              <AddIcon className={style.upgradeBtn} />
              Add new FAQ
            </Button>
            <div className={style.upgradeButton}>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    <div className={styles.thisData}>
                      <h5> Create New FAQs</h5>

                    </div>

                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                    <div className={style.ProgramProp}>

                      <form className={style.programForm} onSubmit={programFAQ}>
                        <TextField
                          type="text"
                          id="outlined-basic"
                          label="FAQ Title"
                          variant="outlined"
                          name="groupTopic"
                          value={question}
                          onChange={(e: any) => setQuestion(e.target.value)}
                          className={styles.Inputfield}
                          InputProps={{
                            className: `${styles.muiInput}`,

                          }}
                          sx={{
                            my: 1,
                            "& .MuiInputBase-input": {
                              width: "100%",

                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                          required
                        />
                        <TextArea
                          id="outlined-basic"
                          placeholder="Answer"
                          name="description"
                          value={answer}
                          onChange={(e: any) => setAnswer(e.target.value)}
                          rows={5}
                          required
                        />
                        <br />
                        <button className={style.SaveButton} >
                          Save
                        </button>
                      </form>

                    </div>

                  </Typography>
                </Box>
              </Modal>
            </div>

          </div>

        </div>

        {faq.length > 0 ? (<>
          {faq.map((fq: any, index: number) => (
            <>
              <div className={style.faqcontent}>
                <div className={style.faqcontentLeft}>
                  <h6>{fq.question}</h6>
                  <p>{fq.answer}</p>
                  {/* AddBtn  AccStatus4*/}
                </div>
                <div className={style.faqcontentRight}>
                  <img src="/images/svg/Admin/EditIcon.svg" onClick={(e: any) => handleGid(e, fq._id, fq.question, fq.answer)} />
                  <img src="/images/svg/Admin/DeleteIcon.svg" onClick={(e: any) => handleDelete(e, fq._id)} />

                </div>


              </div>

            </>
          )
          )}
        </>) : <></>}

        <div className={style.upgradeButton}>

          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={styles.thisData}>
                  <h5> Update FAQs</h5>

                </div>

              </Typography>
              <Typography
                id="modal-modal-description"
                className={style.typoDesc}
              >
                <div className={style.ProgramProp}>

                  <form className={style.programForm} onSubmit={updateFAQ}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="FAQ Title"
                      variant="outlined"
                      name="groupTopic"
                      value={data.title}
                      onChange={(e: any) => setData({ ...data, title: e.target.value })}
                      className={styles.Inputfield}
                      InputProps={{
                        className: `${styles.muiInput}`,

                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",

                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                    <TextArea
                      id="outlined-basic"
                      placeholder="Answer"
                      name="description"
                      value={data.content}
                      onChange={(e: any) => setData({ ...data, content: e.target.value })}
                      rows={5}
                    />
                    <br />
                    <button className={style.SaveButton} >
                      Update
                    </button>
                  </form>

                </div>

              </Typography>
            </Box>
          </Modal>
        </div>
        <div className={style.upgradeButton}>

          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={styles.thisData}>
                  <h5> Delete FAQs</h5>

                </div>

              </Typography>
              <Typography
                id="modal-modal-description"




                className={style.typoDesc}
              >
                <div className={style.ProgramProp}>

                  Are you sure to Delete FAQ?<br />
                  <button className={styles.delete} onClick={(e: any) => deleteFAQ(e)}><DeleteIcon /> Delete </button>
                  <button className={styles.DontDelete} onClick={handleClose2}><Cancel /> Cancel </button>


                </div>

              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  )
}
export default FAQEdit;