import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { facebookLoginapi } from '../Api';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from '../Notify';
import { login } from '../redux/userSlice';

function FacebookCall() {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryString = location.search.split("?")
    const accessToken = queryString[1].split('=')[1];
    const role = queryString[2].split('=')[1];

    const loginUsingFacebook = () => {
        facebookLoginapi({ accessToken, role: parseInt(role) }).then((response: any) => {
            let user: any = {
                ...response.data.user,
                token: response.data.token,
            };
            dispatch(login(user));
            notifySuccess("Login Successfully");
            navigate('/dashboard')
        })
            .catch((error: any) => {
                notifyError(error.response.data.message);
                navigate('/login')
            });
    }

    useEffect(() => {
        loginUsingFacebook()
    }, [])

    return (
        <div>Redirecting to dashboard...</div>
    )
}

export default FacebookCall