import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GetAllMsg, SendMsg } from "../../../../Api";
import { notifyError } from "../../../../Notify";
import styles from "../css/AdminManage.module.css";
import style from "../css/Support.module.css";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";
type PropType = {
  activechat: any;
  sender: any;
};

const Chats = ({ activechat, sender }: PropType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [msg, setmsg] = useState<any>("");
  const[updata,setUpdata]=useState(false)

  const [chatData, setChatData] = useState<any>({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const ref:any = useRef(null);
  const chatHistoryRef:any = useRef<any>([]);
  const[userActiveChat,setUserActiveChat]=useState(activechat)
  const GetMsgs = async (pageNum: number) => {
    if (activechat) {
      try {
        const data: any = await GetAllMsg(cookie, activechat, pageNum);
        const res = data;
        chatHistoryRef.current = [...res.history, ...chatHistoryRef.current];
        const updatedData = {
          ...data,
          page: pageNum ? pageNum : chatData.page,
          history: chatHistoryRef.current, 
        };
        setChatData(updatedData);
      } catch (error) {
        notifyError("Failed to fetch messages");
      }
    }
  };


  const handleScroll = () => {
    const node = ref.current;
    if (node && node.scrollTop === 0 && chatData.next) {
      GetMsgs(chatData.page + 1);
    }
  };
  const SendMsgs = async () => {
    setUpdata(true);
    try {
      const data = await SendMsg(cookie, activechat, msg);
      setUpdata(false);
      setmsg("");
      GetMsgs(chatData.page); 
    } catch (error) {
      setUpdata(false);
      notifyError("Failed to send message");
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      SendMsgs();
    }
  };

  useEffect(() => {
    if(userActiveChat==activechat){
      GetMsgs(chatData.page)
    }
    else if(userActiveChat!=activechat){
      GetMsgs(1); 
    }
    chatHistoryRef.current = [];
  }, [cookie, activechat]);

  return (
    <div className={style.ChatpartsRight}>
      <div className={style.ChatPageHead}>
        {activechat&& sender && (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${sender?.profileImg}`}
              alt="profile"
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              <h6>{sender?.name}</h6>
              <span style={{ color: "gray", fontSize: "10px" }}>{sender?.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className={style.ChatPage}>
      <div >
          {chatData.history && chatData.history.length > 0 ? (
            <div className={chatData.history.length < 7 ? style.chatingSection1 : style.chatingSection} ref={ref} onScroll={handleScroll} style={{ overflowY: "scroll", height: "500px" }}>
              {chatData.history.map((msg: any, index: number) => (
                <div key={index} className={style.DisplayMsg}>
                  {msg.from === user?._id ? (
                    <div className={style.msgTextLeft}>
                      <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>
                      <p>{msg.text}</p>
                    </div>
                  ) : (
                    <div className={style.msgTextRight}>
                      <p>{msg.text}</p>
                      <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      {activechat ? (
                <div className={style.SendMsgButton}>
                  <input
                    type="text"
                    value={msg}
                    onChange={(e: any) => setmsg(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <button onClick={SendMsgs}>
                    <SendIcon />
                  </button>
                </div>
              ) : (
                'No Ticket Is Selected!'
              )}

    </div>
  );
};

export default Chats;
