import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { fetchCoacheeEnrollProgram, fetchCoacheeProgram } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import ShowProgram from "./Program/ShowProgram";
import LiveProgram from "./Program/LiveProgram";
import dayjs from "dayjs";
import EnrollProg from "./Program/EnrollProg";
import Pagination from "../../Pagination/Pagination";

const Programs = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [programData, setprogramData] = useState([]);
  const [progImg, setProgImg] = useState("")
  const [activeTab, setActiveTab] = useState(0);
  const[program_type,setProgram_type]=useState(1)

  const [progType, setProgType] = useState(1)
  const [progId, setProgId] = useState(1)
  const[page,setPage]=useState(1);
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  useEffect(() => {
    progType == 3 ?
      fetchCoacheeEnrollProgram(cookie, program_type,data.page)
        .then((result: any) => {
          let res = result.data;
          let x: any = { ...data };
          x = {
            ...x,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page || 1,
            history: [...res.history],
          };
          setData(x);
          setprogramData(x.history)
          setProgImg(x.history)
        
        }
        )
        .catch((error: any) => {
          console.error("Error fetching Program data:", error);
          notifyError(error);
        })
      :
      fetchCoacheeProgram(cookie, progType,page)
        .then((result: any) => {

          setprogramData(result.data.history)
        })
        .catch((error: any) => {
          console.error("Error fetching Program data:", error);
          notifyError(error);
        });

  }, [cookie, progType,program_type,page]);
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };

  const handleShow = (uId: any) => {
    setActiveTab(1)
    setProgId(uId)



  }
  //console.log("this is programImg", progImg)
  return (<>
    {activeTab == 0 && (<>

      <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
          <div className={styles.UpcomingPList}>
            <div className={styles.UpcomingPListLeft} style={{maxWidth:'500px'}}>
            <p onClick={() => setProgType(1)} className={`${styles.tab1} ${progType === 1 && styles.setliactive}`}>Live Program</p>
            <p onClick={() => setProgType(2)} className={`${progType === 2 && styles.setliactive}`}>Recorded Program</p>
            <p onClick={() => setProgType(3)} className={`${progType === 3 && styles.setliactive}`}>Enrolled Program</p>
            </div>
            <div className={styles.UpcomingPListRight}>
              {progType==3?(<>
                <select onChange={(e:any) => setProgram_type(e.target.value)}className={styles.PorgrmaTypes}>
  <option value="1">Live Programs</option>
  <option value="2">Recorded Programs</option>
</select>

</>):(<></>)}
            </div>
          </div>

        </div>
        <div className={styles.DashForRecom}>
          <div className={styles.Recommond}>

            {programData.length > 0 ? (programData.map((pdata: any) => <>


              <div className={styles.coaching}>
                <img
                  src={`https://dxe2g9i1k0e2c.cloudfront.net/${pdata.programImg || pdata.Program_Details?.programImg}`}
                  className={styles.coachingImg}
                />
               {progType==3 ? ( <div className={styles.coachingTxt} onClick={() => handleShow(pdata.Program_Details._id)}>
                  <p style={{fontWeight:'500',fontSize:'20px'}}>{ pdata.name>0?pdata.name.slice(0,5):pdata.name || pdata.Program_Details?.name.length>0?pdata.Program_Details?.name.slice(0,16):pdata.Program_Details?.name}</p>
                  <div className={styles.Description}>
                    <p style={{width:'230px',color:'gray',fontSize:'15px'}}>{ pdata.description>0?pdata.description.slice(0,5):pdata.description || pdata.Program_Details?.description.length>0?pdata.Program_Details?.description.slice(0,30):pdata.Program_Details?.description}</p>
                  </div>
                  <div className={styles.ProgramDate}>
                    <p><AccessAlarmTwoToneIcon /> {dayjs(pdata.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
                  </div>
                </div>):( <div className={styles.coachingTxt} onClick={() => handleShow(pdata._id)}>
                  <p>{pdata.name || pdata.Program_Details?.name}</p>
                  <div className={styles.Description}>
                    <p style={{width:'230px',color:'gray',fontSize:'15px'}}>{ pdata.description.length>20?pdata.description.slice(0,40):pdata.description || pdata.Program_Details?.description}</p>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div className={styles.ProgramDate}>
                    <p><AccessAlarmTwoToneIcon /> {dayjs(pdata.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
                  </div>
                  <div className={styles.ProgramConst}>
                    <p><MonetizationOnIcon /> {pdata?.disCost?<> <del style={{color:'gray'}}> {pdata.overallCost}</del> {pdata?.disCost}</>:pdata.overallCost}</p>
                  </div>
                  </div>
                </div>)}
              </div>



            </>)) : (<>No  Any Program Enrolled !</>)}
          </div>
        </div>
        <div className={styles.pagination}>
          <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
      </div>


    </>)} {activeTab === 1 && progType === 2 && (<ShowProgram progId={progId} setActiveTab={setActiveTab}/>)}
    {activeTab === 1 && progType === 1 && (<LiveProgram progId={progId}  setActiveTab={setActiveTab}/>)}
    {activeTab==1 && progType===3  && (<EnrollProg progId={progId} program_type={program_type} setActiveTab={setActiveTab}/>)}



  </>)
}
export default Programs;