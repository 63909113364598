import style from "../../../../styles/setting.module.css";
import styles from "../CSS/dashboardHome.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import { Box, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import { saveAs } from 'file-saver';
import { useEffect, useState } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AddCard, DeleteCards, fetchCard, fetchPayment } from "../../../../Api";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { notifyError, notifySuccess } from "../../../../Notify";
import { cdn_Link } from "../../../../config";
import configuration from "../../../../config/configuration";

const strp = loadStripe(configuration.stripePublicKey);
console.log("stripe account",configuration.stripePublicKey)
const CheckoutForm = ({
  onSave,
  setLoad,
  setOpen,
}: {
  onSave: () => void;
  setLoad: (loading: boolean) => void;
  setOpen: (loading: boolean) => void;
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const[loading,setLoading]=useState(false)
const[name,setName]=useState('')
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    setLoading(true)
    if (!stripe || !elements) {
      return;
    }
    const cardElement: any = elements.getElement(CardElement);

    try {
      setLoad(true);
      const { token, error }: any = await stripe.createToken(cardElement,{name});
    
      const cToken = token.id;
      console.log("this is card token",token)
      AddCard({ cToken: cToken, cookie: cookie })
        .then((result: any) => {
          //console.log(result);
          setLoad(true);
          setOpen(false);
          notifySuccess(result?.data.message)
        })
        .catch((error:any) => {
          notifyError(error.response.data.message)
          //console.log(error)
          setLoad(true);
          setOpen(true);
        });
      setLoad(false);
    } catch (error) {
      console.error("Error:", error);
     
    }
    finally{
      setLoading(false)
    }
  };
  

  return (
    <form>
      <div className={styles.cartContent}>
        <p>Card Information</p>

        <input
          type="text"
          id="outlined-basic"
          placeholder="Cardholder Name"
          name="name"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          className={style.InputfieldId}
        />
      </div>
      <div>
        <label htmlFor="card-element">Card Details</label>
        <CardElement id="card-element" />
        <br />
      </div>
      {error && <div style={{ color: "red" }}>{error}</div>}
     
        <button className={style.button} onClick={(e: any) => handleSubmit(e)}>{loading ? <>Saving....</>:<>Save</>}</button>
      
    </form>
  );
};

const SetTap2 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [load, setLoad] = useState(false);
  const [dta, setDta] = useState(false);
  const [open1, setOpen1] = useState(false);
  const[histData,sethistData]=useState<any>([])
  const[url,setUrl]=useState('')
  const [cID, setcID] = useState("");
  const handleOpen1 = (e: any, id: any) => {
    e.preventDefault();
    setcID(id);
    setOpen1(true);
  };

  const handleClose1 = () => setOpen1(false);

  const [cdata, setCardData] = useState<any>([]);
const  handlehistory=()=>{
  fetchPayment(cookie)
  .then((res:any)=>{
    sethistData(res.data.history)
  })
}
const fetchCards=()=>{
  if (!cookie) {
    console.error("No cookie available");
    return;
  } else {
    fetchCard(cookie)
      .then((result: any) => {
        setCardData(result.data);
        //console.log("Card details", result);
      })
      .catch((error) => {
        //console.log("from Card", error);
      });
  }
}
  useEffect(() => {
    handlehistory();
    fetchCards();
  }, [cookie, load, dta]);
  const handleSave = () => {
    handleClose();
    setDta(true);
    setLoad(false);
  };
  const handleDelete = (e: any, cardId: any) => {
    e.preventDefault();
    DeleteCards(cardId, cookie)
      .then((res: any) => {
        notifySuccess("Sucessfully Card Deleted");
        handleClose1();
        setDta(true);
      })
      .catch((err: any) => {
        notifyError(err.response.data.message);
      });
    setDta(false);
  };

  return (
    <>
      <div className={style.setTab2}>
        <div className={style.setTabHeader}>
          <h4>Add Payment Detail</h4>
          <h6>Saved Cards</h6>
        </div>
        {/* card Section */}
        <div className={style.CardContainer}>
          <div className={style.CardAddButton} onClick={() => handleOpen()}>
            <AddIcon />
          </div>
          <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.BoxStyle}>
              <span
                className={styles.HandleClose}
                onClick={() => handleClose()}
              >
                <CloseTwoToneIcon className={styles.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Add New Card
              </Typography>
              <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              >
                <div className={styles.UpgradeDiv}>
                  <form className={style.TypoForm}>
                    <Elements stripe={strp}>
                      <CheckoutForm
                        onSave={handleSave}
                        setLoad={setLoad}
                        setOpen={setOpen}
                      />
                    </Elements>
                  </form>
                </div>
              </Typography>
            </Box>
          </Modal>

          {/*      Card 1 */}
          {cdata?.length > 0 ? (
            cdata?.map((card: any, index: number) => (
              <>
                <div className={style.displayCard}>
                  <div className={style.CardContent}>
                    <img src="./images/Coachee/paycard.png" />
                    <p style={{padding:"0px",margin:'0px'}}>{card?.name}</p>
                    <p style={{padding:"0px",margin:'0px'}}>.... .... .... {card?.last4}</p>
                    <p style={{padding:"0px",margin:'0px'}}>Expire Date</p>
                    

                    <p style={{padding:"0px",margin:'0px'}}>
                      {card?.exp_month.toString().length === 1 ? (
                        <>0{card?.exp_month}</>
                      ) : (
                        <>{card?.exp_month}</>
                      )}
                      /{card?.exp_year.toString().slice(-2)}
                    </p>
                  </div>
                  <div className={style.CardDesign}>
                    <div className={style.cardEdit}>
                      <p onClick={(e: any) => handleOpen1(e, card.id)}>
                        <img src="/images/svg/Coachee/delete.svg" />
                        Delete
                      </p>
                    </div>
                    <div className={style.stleCSV}>
                      <p style={{padding:"0px",marginTop:'-10px',color:'white'}}>CSV/CVV</p>
                      <p style={{padding:"0px",marginTop:'-20px',color:'white'}}>...</p>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div style={{textAlign:'center',display:'flex',alignItems:'center',marginLeft:'10px'}}>Add Payment Cards</div>
          )}
          {/*  Card 2 */}

          {/* End Cards */}
        </div>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.BoxStyle}>
            <span onClick={handleClose1} className={styles.HandleClose}>
              <CloseTwoToneIcon className={styles.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Delete Card
            </Typography>
            <Typography
              id="modal-modal-description"
              className={styles.typoDesc}
            >
              {cdata?.length > 0 ? (
                cdata?.map((card: any, index: number) => (
                  <>
                    {cID != null && cID == card.id && (
                      <div className={styles.UpgradeDiv}>
                        <div className={styles.CardContent}>
                          <div className={styles.DeleteCardContent}>
                            <div className={styles.DeleteCard}>
                              <img
                                src="./images/Coachee/paycard.png"
                                className={styles.DeleteCardImg}
                              />
                              <p>{card?.name}</p>
                              <p>.... .... .... {card?.last4}</p>
                              <p>Expire Date</p>
                              <p></p>

                              <p>
                                {card?.exp_month.toString().length === 1 ? (
                                  <>0{card?.exp_month}</>
                                ) : (
                                  <>{card?.exp_month}</>
                                )}
                                /{card?.exp_year.toString().slice(-2)}
                              </p>
                            </div>
                            <div className={styles.DeleteCardOption}>
                              <p>Are You Sure Delete This card </p>
                              <div className={styles.DeleteCardBtn}>
                                <button
                                  className={styles.DeleteCardBtnRed}
                                  onClick={(e: any) => handleDelete(e, card.id)}
                                >
                                  <DeleteOutlineOutlinedIcon /> Delete
                                </button>
                                <button
                                  className={styles.DeleteCardBtnBlue}
                                  onClick={handleClose1}
                                >
                                  <HighlightOffOutlinedIcon /> Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <></>
              )}
              {/* <button className={style.enrolledRecordBtn}>Pay Now</button> */}
            </Typography>
          </Box>
        </Modal>
        <div className={style.transactionDetail}>
          <h5>Transactions</h5>
          <br />
          <div className={style.transactionList}>
            {/*     Left part       */}
            <div className={style.transactionist}>
            <table>
              <tr>
                <th>ORDER NUMBER</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
                <th></th>
              </tr>
              {histData.length>0?(histData.map((hist:any)=>(
                <tr>
                  <td>{hist.orderId}</td>
                  <td> $ {hist.amount}</td>
                  <td> {hist.status}</td>
                  <td>  <button className={styles.handleInvoiceBtn} onClick={() => window.open(hist.invoicePdf, "_blank")}>
      Invoice
    </button> </td>
                </tr>
              ))):'No any Payment History!'}
            </table>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default SetTap2;
