import { useEffect, useState } from "react";
import styles from "./session.module.css";
import styled from "./../../Admin/wrapper/wrapper.module.css";
import { FetchUcoveringSessions, SetUncoveringSession } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
import { DateRangeSharp } from "@mui/icons-material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { ClockCircleFilled } from "@ant-design/icons";
import Groups3Icon from '@mui/icons-material/Groups3';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Modal, TextField, Typography } from "@mui/material";
import { notifyError, notifySuccess } from "../../../../Notify";
import { DateTime } from "luxon";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

const AsTab4 = ({ setActiveTab }: propType) => {
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
const[updata,setUpdate]=useState(false)
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [activeState,setActiveState]=useState(0)

  const [manageData, setManageData] = useState<any>([]);

  useEffect(() => {
    FetchUcoveringSessions({ filter: 0, item_per_page: 1, cookie: cookie }).then(
      (res: any) => {
        //console.log(res);
        setManageData(res.data.history);
      }
    );
  }, [cookie,updata]);

  const [data, setData] = useState({
    sessionId: '',
    date: '',
    startTime: '',
    meetingLink: ''
  });
  // const endTime = DateTime.fromFormat(sessionET, 'HH:mm');
  // const endtime=endTime.toFormat('hh:mm a');
  const handleSetMeet = (e: any, sessionId: string, date: any, meetlink: string) => {
    e.preventDefault();
    handleOpen1();
    
     
    setData({
      ...data,
      sessionId: sessionId,
      date: date,
      startTime: dayjs(date).format('hh:mm A'), 
      meetingLink: meetlink
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleManageRequest=(e:any)=>{
    e.preventDefault();
    const startTime = DateTime.fromFormat(data.startTime, 'HH:mm');
  const starttime=startTime.toFormat('hh:mm a');
  const date=dayjs(data.date).format('DD-MM-YYYY')
    setUpdate(true)
    // {sessionId:sessionId,date:date,startTime:startTime,meetingLink:meetingLink },
    SetUncoveringSession({sessionId:data.sessionId,date:date,startTime:starttime,meetingLink:data.meetingLink,cookie})
    .then((res:any)=>{
      notifySuccess(res.message)
      setUpdate(false)
      handleClose1()

    })
    .catch((err:any)=>{
      notifyError(err.response.data.message)
      setUpdate(false)
      handleClose1()

    })
    
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <thead>
                <tr>
                  <th>CLIENT NAME</th>
                  <th>REQUEST SENT DATE</th>
                  <th>REQUEST STATUS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {manageData.length > 0 ? (
                  manageData.map((data: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', alignItems: 'center' }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <img src={`${cdn_Link}/${data?.CoacheeDetails?.profileImg}`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', marginLeft: '10px' }}>
                            <h6 style={{ color: 'black', fontWeight: 'bold' }}>{data?.CoacheeDetails?.name}</h6>
                            <p style={{ color: 'orange' }}>{data?.CoacheeDetails.email}</p>
                          </div>
                        </div>
                      </td>
                      <td><DateRangeSharp style={{ marginRight: '10px' }} />{dayjs(data?.CoacheeDetails.createdAt).format('DD MMM YYYY')}</td>
                      <td>
                        {data?.isApproved === true && (
                          <button
                            className={styles.StartSessBtn}
                            onClick={(e: any) => handleSetMeet(e, data?._id, data?.appointmentDate, data?.meetingLink)}
                          >
                            <EventAvailableIcon style={{ textAlign: 'left', color: 'white' }} /> Manage Request
                          </button>
                        )}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {data?.isApproved === true && (
                          <>
                            <h6><DateRangeSharp style={{ marginRight: '10px' }} />{dayjs(data?.appointmentDate).format('DD MMM YYYY')}</h6>
                            <h6><ClockCircleFilled style={{ marginRight: '10px' }} /> {dayjs(data?.appointmentDate).format('hh:mm A')}</h6>
                            <a href={data?.meetingLink} target="_blank"><Groups3Icon style={{ marginRight: '10px' }} /> JOIN MEET</a>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No Request Available!</td> {/* Adjust colspan to the number of columns in your table */}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styled.upgradeButton}>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={styled.BoxStyle}>
              <span onClick={handleClose1} className={styled.HandleClose}>
                <CloseTwoToneIcon className={styled.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                <div className={styled.thisData}>
                  <h5>{manageData?.isApproved ? "Edit Your Appointment" : "Create New Appointment"}</h5>
                </div>
              </Typography>
              <Typography id="modal-modal-description" className={styled.typoDesc}>
                <div style={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
                  <TextField
                    type="date"
                    id="date"
                    label="Session Date"
                    variant="outlined"
                    name="date"
                    value={dayjs(data.date).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    className={styles.Inputfield}
                    InputProps={{
                      className: `${styled.muiInput}`,
                    }}
                    sx={{
                      my: 1,
                      "& .MuiInputBase-input": { width: "100%" },
                      "& .MuiInputLabel-root": { backgroundColor: "#fff" },
                    }}
                  />
                  <TextField
                    type="time"
                    id="startTime"
                    label="Session Time"
                    variant="outlined"
                    name="startTime"
                    value={data.startTime}  // Use HH:mm format here
                    onChange={handleChange}
                    className={styles.Inputfield}
                    InputProps={{
                      className: `${styled.muiInput}`,
                    }}
                    sx={{
                      my: 1,
                      "& .MuiInputBase-input": { width: "100%" },
                      "& .MuiInputLabel-root": { backgroundColor: "#fff" },
                    }}
                  />
                  <TextField
                    type="text"
                    id="phone"
                    label="Session Link"
                    variant="outlined"
                    name="meetingLink"
                    value={data.meetingLink}
                    onChange={handleChange}
                    className={styles.Inputfield}
                    InputProps={{
                      className: `${styled.muiInput}`,
                    }}
                    sx={{
                      my: 1,
                      "& .MuiInputBase-input": { width: "100%" },
                      "& .MuiInputLabel-root": { backgroundColor: "#fff" },
                    }}
                  />
                   <button
                            className={styles.StartSessBtn}
                           onClick={(e:any)=>handleManageRequest(e)}
                          >
                           Update
                          </button>
                          <button  onClick={handleClose1} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >   Cancel</button>

                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AsTab4;
