import { useEffect, useState } from "react";
import { ApproveCoach, fetchUsersId, getUserProgram } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import style from "./usermanage.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import styles from "../../../Dashboard/Coach/all-session/session.module.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Document, Page  } from 'react-pdf';
import { notifyError, notifySuccess } from "../../../../Notify";
import dayjs from 'dayjs';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { cdn_Link } from "../../../../config";


type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  coachUid: any;
};
interface DocumentLoadSuccessProps {
  numPages: number;
}
const UM3 = ({ setActiveTab, coachUid }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  const [data, setData] = useState<any>({
    uid:'',
    email: "",
    name: "",
    accountState: "",
    profileImg: "",
    introvideo:"",
    createdAt: "",
    location: "",
    certificationDetails: [],
    behavioralIdentity: [],
    commpower: [],
    facebook:'',
    instagram:'',
    linkedIn:'',
    phone:'',
    yearExp:'',
    topTalents:'',
    currentprofession:'',
  
    birthDate:'',
    ssNumber:''
  
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
 const [userState,setUserState]=useState(false)
 const[roleShow,setroleShow]=useState(1);
const[filter,setFilter]=useState(1)
const[programData,setProgramData]=useState<any>([])
const[accountStatus,setAccountStatus]=useState(0)


const [udata, setUdata] = useState({
  prev: false,
  next: false,
  search: "",
  page: 1,
  total_page: 1,
  history: [],
});
const handleAccDel=(e:any,action:boolean)=>{
  e.preventDefault()
  setUserState(true)
  ApproveCoach(data.uid,action,cookie)
  .then((res:any)=>{
    setUserState(false)
    //console.log(res)
    notifySuccess("User Status Approved Now!")
    handleClose()
  })
  .catch((err:any)=>{
    setUserState(false)
    notifyError(err.response.data.message);
    handleClose()
  })

}
  useEffect(() => {
    const formData = new FormData();
    formData.append("uid", coachUid);
    fetchUsersId(cookie, formData)
      .then((result: any) => {
        //console.log("details of userInfo", result.data.data.user._id);
        setroleShow(result.data.data.user.role)
        //console.log("coach data",result.data.data)
        setAccountStatus(result.data.data.user.account_status)
        setData({
          uid: result.data.data.user._id,
          email: result.data.data.user.email,
          name: result.data.data.user.name,
          accountState: result.data.data.user.account_status,
          profileImg: result.data.data.user.profileImg,
          introvideo:result.data.data.userInfo.introvideo,
          createdAt: result.data.data.user.createdAt,
          location: result.data.data.userInfo.location,
          certificationDetails: result.data.data.userInfo.certificationDetails,
          behavioralIdentity: result.data.data.userInfo.behavioralIdentity,
          commpower: result.data.data.userInfo.commpower,
          facebook:result.data.data.userInfo.facebookLink,
          instagram:result.data.data.userInfo.instagramLink,
          linkedIn:result.data.data.userInfo.linkedinLink,
          phone:result.data.data.userInfo.phoneNo,
          yearExp:result.data.data.userInfo.yearExp,
          topTalents:result.data.data.userInfo.topTalents,
          currentprofession:result.data.data.userInfo.currentprofession,
         
          birthDate:result.data.data.userInfo.birthDate,
          ssNumber:result.data.data.userInfo.ssNumber,
          
          
          
          
        });
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie, coachUid,userState,udata.page,accountStatus]);
  useEffect(()=>{
    // uid:any,filter:any,role:any,
    getUserProgram(coachUid,filter,roleShow,cookie)
    .then((result:any)=>{
      //console.log(result)
      let res = result.data;
      let x: any = { ...data };
      x = {
        ...x,
        next: res.next,
        prev: res.prev,
        total_page: res.total_page || 1,
        history: [...res.history],
      };
      setProgramData(x.history)
      setUdata(x)
      
     

    })
  },[cookie,roleShow,userState,filter,accountStatus])

  return (
    <>
    {roleShow == 1 &&(<>
      <div className={styles.container}>
          <FastRewindIcon style={{color:'#1A5298',cursor:'pointer'}} onClick={()=>setActiveTab(()=>2)}/>
        <div className={style.containerTop}>
          <div className={style.topImg}>
            {data.profileImg ? (
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                className={style.coachImg}
              />
            ) : (
              <AccountCircleIcon className={style.coachImg} />
            )}

            <div className={style.coachName}>
              <h6>{data.name}</h6>
       
              <h6>
                <p className={style.emailIcon}>
                  <EmailIcon /> {data.email} 
                </p>
              </h6>
              <h6>
                <p className={style.emailIcon}>
                  <AccessTimeFilledIcon /> {dayjs(data.createdAt.slice(0,10)).format('DD MMM YYYY')}
                </p>

              </h6>
              <h6>
                <p className={style.emailIcon}>
                  <LocalPhoneIcon/> {data.phone} </p>
              </h6>
            </div>
          </div>
          <div className={style.topTotalSect}>
            <div className={style.totalsec}>
              <span>{filter==1 ?'Total Programs' :'Total Groups'}</span>
              <h5>{filter==1 ?programData.length :programData.length}</h5>
            </div>
           
         
          </div>
          <div className={style.topDisp}>
          {accountStatus == 1 &&(
            <button className={styles.topBtn3} onClick={handleOpen}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>)}
          {accountStatus == 4&&(
            <button className={styles.topBtn3} onClick={handleOpen}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>)}
            {accountStatus==2 &&(
           <button className={style.topBtn1} onClick={handleOpen}>
           <DeleteIcon className={style.topBtn2} /> Delete
         </button>)}
            {/* <button className={style.topBtn1}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button> */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={style.profile}>
                    {data.profileImg ? (
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                        className={style.profileImg}
                      />
                    ) : (
                      <AccountCircleIcon className={style.profileImg} />
                    )}
                    <h3> Details of {data.name}</h3>
                  </div>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <div className={style.ProgramProp}>
                  <hr />
                    <div >
                      
                      <div className={style.onlinePro}>
                      <h6>Common Power:<p>{data?.commpower}</p></h6>

                      </div>
                      <div className={style.onlinePro}>
                      <h6>Location:<p>{data?.location.streetName}, {data?.location.city},{data?.location.state},{data?.location.country||data?.location.postalCode}</p></h6>

                      
                      <h6>Behavioral Identity:<p>{data?.behavioralIdentity}</p></h6>

                      </div>
                    <div className={style.onlinePro}>
                      <p><FacebookIcon  className={style.onlineProic1}/> {data.facebook}</p>
                      <p><InstagramIcon className={style.onlineProic2}/>{data.instagram}</p>
                      <p><LinkedInIcon className={style.onlineProic1}/>{data.linkedIn}</p>
                    </div>
                    <div className={style.onlineProBtn}>
                   {  accountStatus==1&& (
                    <button className={styles.topBtn3} style={{width:'100%'}} onClick={(e:any)=>{handleAccDel(e,true)}}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>)}
            {  accountStatus==4 && (
                    <button className={styles.topBtn3} style={{width:'100%'}} onClick={(e:any)=>{handleAccDel(e,true)}}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>)}
            {accountStatus==2 && (
            <button className={style.topBtn1} style={{width:'100%'}} onClick={(e:any)=>{handleAccDel(e,false)}}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button> )}
                    </div>
                    </div>

                    <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} > Cancel</button>
                  </div>
                </Typography>
              </Box>
            </Modal>
         
          <div className={style.topDispIntroVideo}>
         
          <video src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.introvideo}`} width="200" height="100" controls />
          </div>
          
          </div>
        </div>
        
<div className={styles.showDetailsCoach}>
<div className={styles.showDetails}>
  <p>Years of Experience</p>
  <h6>{data?.yearExp} </h6>
</div>
<div className={styles.showDetails}>
  <p>Behavioral Superpowers Identity</p>
  <h6>{data?.behavioralIdentity}</h6>
</div>
<div className={styles.showDetails}>
  <p>Top Talents</p>
  <h6>{data?.topTalents} </h6>
  
</div>
<div className={styles.showDetails}>
  <p>location</p>
  <h6>{data?.location.streetName}, {data?.location.city},{data?.location.state},{data?.location.country||data?.location.postalCode}</h6>
  {/* <h6>{data?.location?.map((loc:any)=>(loc.streetName,loc.city,loc.country,loc.state))}</h6> */}
  
  </div>

</div>
<hr/>
<div className={styles.showDetailsCoach}>

<div className={styles.showDetails}>
  <p>Communication SuperPower</p>
  <h6> {data?.commpower}</h6>
</div>
<div className={styles.showDetails}>
  <p>Current Profession</p>
  <h6> {data?.currentprofession}</h6>
</div>
<div className={styles.showDetails}>
  <p>BirthDate</p>
  
  <h6>{data.birthDate=='Invalid Date' ? '':data.birthDate}</h6>
</div>

</div>
<hr/>
<div className={styles.showDetailsCoach}>


</div>
<hr/>
<div className={styles.showCertificates}>
{data.certificationDetails && data.certificationDetails.length>0?(data.certificationDetails.map((certi:any,index:number)=>(<>
  <div className={styles.certicontent}>
 {/* <div> 
  <p>coach School </p>
  <h6>{certi.coachSchool}</h6>
  </div>
  <div> 
  <p>coach speciality </p>
  <h6>{certi.speciality}</h6>
  </div> */}
<h5><a href={`${cdn_Link}/${certi.certificateImg}`} style={{color:'white',textDecoration:'none'}} target="_blank">View Certificate</a> </h5>
{/* <iframe src={} /><br/> */}
 {/* <button onClick={()=>window.open(certi.certificateImg,'_blank')} className={style.certificateInfo}>View Certificate </button> */}
                   
<Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose1} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={style.profile}>
                    {data.profileImg ? (
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                        className={style.profileImg}
                      />
                    ) : (
                      <AccountCircleIcon className={style.profileImg} />
                    )}
                    <h3> Certificates  of {data.name}</h3>
                   
                    
                  </div>
                  <h6><b>School Name: </b> {certi.coachSchool}</h6>
                    <h6><b>Topic Specialization:</b> {certi.speciality}</h6>
                   
                 
                  
                      
                    
                </Typography>
                <Typography   id="modal-modal-description"
                  className={style.typoDesc}>
                  <button  onClick={handleClose1} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  <CloseTwoToneIcon className={styles.HandleCloseIcon} /> Cancel</button>

                </Typography>
                </Box>
                </Modal>
 
  
</div>


  </>))):(<>No Any Certicate Added!</>)}
</div>

        <div className={styles.upcomeAppContent}>
          <ul>
            <li onClick={()=>setFilter(1)} className={filter == 1 ? style.filterName : ''}>Program Enrollment</li>
            <li onClick={()=>setFilter(2)} className={filter == 2 ? style.filterName : ''}>Group Engagement</li>
          </ul>
        </div>
        <table>
          {filter==1 && (
          <tr>
            <th>PROGRAM NAME</th>
            <th>NUMBER OF COACHEES</th>
            <th>PROGRAM COST</th>
            <th>CREATED AT</th>

          </tr>
          )}
           {filter==2 && (
          <tr>
            <th>GROUP TOPIC</th>
            <th>NUMBER OF COACHEES</th>
            <th>CREATED AT</th>

          </tr>
          )}
          {filter==1 && (
          programData.length > 0 ? (
  programData.map((program: any) => (
    <tr key={program.id}> 
      <td style={{textAlign:'left',alignItems:'center'}}>
        <img src={`${cdn_Link}/${program.programImg}`} style={{width:'40px',height:'40px',borderRadius:'50%',marginRight:'10px'}}/>
        {program?.name}</td>
        <td>
          {program.coacheIds?.length}
        </td>
        <td>$ {program.overallCost}</td>
        <td>{dayjs(program.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
    </tr>
  ))
) : (
  'No Program Found'
))}
{filter==2 && (
          programData.length > 0 ? (
  programData.map((program: any) => (
    <tr key={program.id}> 
      <td style={{textAlign:'left',alignItems:'center'}}>
        <p style={{borderRadius:'50%',display: 'inline-block',padding:'8px',marginRight:'10px',fontSize:'15px',backgroundColor:'#7aa146',color:'white'}}>{program?.groupTopic?.slice(0,2).toUpperCase()}</p>
        {program?.groupTopic}</td>
        <td>
          {program.coacheeIds?.length}
        </td>
       
        <td>{dayjs(program.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
    </tr>
  ))
) : (
  'No  Group Found'
))}
          
        </table>
       
        <div>
    
    </div>

      </div>
    </>)}
    {
      roleShow ==2 &&(<>
        <div className={styles.container}>
        <div className={style.containerTop}>
          <div className={style.topImg}>
            {data.profileImg ? (
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${data.profileImg}`}
                className={style.coachImg}
              />
            ) : (
              <AccountCircleIcon className={style.coachImg} />
            )}

            <div className={style.coachName}>
              <h6>{data.name}</h6>
       
              <h6>
                <p className={style.emailIcon}>
                  <EmailIcon /> {data.email}
                </p>
              </h6>
              {/* <h6>
                <p className={style.emailIcon}>
                  <AccessTimeFilledIcon />22 {dayjs(data.createdAt.slice(0,10)).format('DD MMM YYYY')}
                </p>

              </h6> */}
              {/* <h6>
                <p className={style.emailIcon}>
                  <LocalPhoneIcon/> {data.phone} </p>
              </h6> */}
            </div>
          </div>
          <div className={style.topTotalSect}>
          <div className={style.totalsec}>
              <span>{filter==1 ?'Total Programs' :'Total Groups'}</span>
              <h5>{filter==1 ?programData.length :programData.length}</h5>
            </div>
           
            {/* <div className={style.totalsec}>
              <span>Total Earnings</span>
              <h5>34</h5>
            </div> */}
          </div>
          <div className={style.topDisp}>
          <div className={style.topBtn}>
            {/* <button className={styles.topBtn3} onClick={handleOpen}>
              <CheckCircleIcon className={style.topbtn2} /> Accept
            </button>
            <button className={style.topBtn1}>
              <DeleteIcon className={style.topBtn2} /> Delete
            </button> */}

          
          </div>
          
          
          </div>
        </div>
 

         <div className={styles.upcomeAppContent}>
          <ul>
            <li onClick={()=>setFilter(1)} className={filter == 1 ? style.filterName : ''}>Program Enrollment</li>
            <li onClick={()=>setFilter(2)} className={filter == 2 ? style.filterName : ''}>Group Engagement</li>
          </ul>
        </div>
        <table>
          {filter==1 && (
          <tr>
            <th>PROGRAM NAME</th>
            <th>NUMBER OF COACHEES</th>
            <th>PROGRAM COST</th>
            <th>CREATED AT</th>

          </tr>
          )}
           {filter==2 && (
          <tr>
            <th>GROUP TOPIC</th>
            <th>NUMBER OF COACHEES</th>
            <th>CREATED AT</th>

          </tr>
          )}
          {filter==1 && (
          programData.length > 0 ? (
  programData.map((program: any) => (
    <tr key={program.id}> 
      <td style={{textAlign:'left',alignItems:'center'}}>
        <img src={`${cdn_Link}/${program?.pId?.programImg}`} style={{width:'40px',height:'40px',borderRadius:'50%',marginRight:'10px'}}/>
        {program?.pId?.name}</td>
        <td>
          {program.pId?.description.slice(0,20)}
        </td>
        <td>$ {program?.pId?.overallCost}</td>
        <td>{dayjs(program.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
    </tr>
  ))
) : (
  'No Program Found'
))}
{filter==2 && (
          programData.length > 0 ? (
  programData.map((program: any) => (
    <tr key={program.id}> 
      <td style={{textAlign:'left',alignItems:'center'}}>
        <p style={{borderRadius:'50%',display: 'inline-block',padding:'8px',marginRight:'10px',fontSize:'15px',backgroundColor:'#7aa146',color:'white'}}>{program?.groupTopic?.slice(0,2).toUpperCase()}</p>
        {program?.groupTopic}</td>
        <td>
          {program.coacheeIds?.length}
        </td>
       
        <td>{dayjs(program.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
    </tr>
  ))
) : (
  'No  Group Found'
))}
          
        </table>
       
        <div>
    
    </div>

      </div>
    </>)}
   
     
    </>
    
  );
}

export default UM3;
