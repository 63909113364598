import React, { useEffect, useState } from "react";
import Style from "../../../styles/session.module.css";
import AS1 from "./AllSessions/AS1";
import AS2 from "./AllSessions/AS2";
import AS3 from "./AllSessions/AS3";
import { FetchSessionAdmin } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";
import Pagination from "../../Pagination/Pagination";
import { cdn_Link } from "../../../config";
import { ClockCircleFilled } from "@ant-design/icons";
import { CalendarMonth } from "@mui/icons-material";

const Session = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchSession = () => {
    FetchSessionAdmin({ cookie, filter: activeTab,page:data.page })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setData(x);
      })
      .catch((err) => {});
  };
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  const MappedData: any = {
    1: "Program Session",
    2: "Group Session",
  };
  useEffect(() => {
    fetchSession();
  }, [activeTab]);
  return (
    <div className={Style.Artist}>
      <ul className={Style.tabCnt1}>
        <li
          className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
          onClick={() => setActiveTab(1)}>
          All Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
          onClick={() => setActiveTab(2)}>
          Upcoming Sessions<p> </p>
        </li>
        <li
          className={`${Style.tab1} ${activeTab === 3 && Style.tabActive1}`}
          onClick={() => setActiveTab(3)}>
          Completed Sessions<p> </p>
        </li>
      </ul>
      <div className={Style.maindiv} style={{ overflowX: "auto" }}>
        <table className={Style.curvedbordertable}>
          <tr >
            <th style={{textTransform:'uppercase'}}>Coachee Name </th>
            <th style={{textTransform:'uppercase'}}>Coach Name</th>
            <th style={{textTransform:'uppercase'}}>Program Name</th>
            <th style={{textTransform:'uppercase'}}>Session Type</th>
            <th style={{textTransform:'uppercase'}}>Date & time </th>
            <th style={{textTransform:'uppercase'}}>Duration</th>
      
          </tr>

          {data.history.length > 0 &&
            data.history.map((elem: any, index: number) => (
              <tr
                className={Style.tableTr1}
                key={`${elem?._id}_Sessions_${index}`}>
                <td><img src={`${cdn_Link}/${elem?.coacheeData?.profileImg}`} style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'10px'}}/>{elem?.coacheeData?.name}</td>
                <td><img src={`${cdn_Link}/${elem?.coachData?.profileImg}`} style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'10px'}}/>{elem?.coachData?.name}</td>
                <td><img src={`${cdn_Link}/${elem?.programData?.programImg}`} style={{width:'30px',height:'30px',borderRadius:'50%',marginRight:'10px'}}/> {elem?.programData?.name}</td>
                <td>{MappedData[elem?.AppType]}</td>
                <td style={{textAlign:'left'}}>
                 {/* <CalendarMonth style={{color:'#ef643ecd',marginRight:'10px',fontSize:'20px'}}/> */}
                  {dayjs(elem?.startdate).format("DD MMM ")} -{" "}
                  {dayjs(elem?.enddate).format("DD MMM YYYY")}
                </td>
                <td style={{textAlign:'left'}}>
                {/* <ClockCircleFilled style={{color:'#ef643ecd',marginRight:'10px',fontSize:'20px'}}/> */}
                  {dayjs(elem?.startdate).format("hh:mmA")} -{" "}
                  {dayjs(elem?.enddate).format("hh:mm A")} ({elem?.duration}{" "}
                  Min)
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
    </div>
  );
};

export default Session;
