import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { fetchCoachByCoachee, fetchRecommendCoach } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import RecommendCoach from "./Recommend/RecommendCoach";
import Pagination from "../../Pagination/Pagination";
type propType={
  setSubActiveTab:(val: (v: number) => number) => void;
}
const Recommend = ({setSubActiveTab}:propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[coachData,seCoachData]=useState<any[]>([]);
  const[activeTab,setActiveTab]=useState(0);
  const[coachId,setCoachId]=useState("")
  const[progType,setProgType]=useState(1)
  const[page,setPage]=useState(1);
  const filter=progType;
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  useEffect(()=>
  {
    fetchRecommendCoach(filter,cookie,data.page)
    .then((result: any) => {
    //console.log(result)
    let res = result.data;
    let x: any = { ...data };
    x = {
      ...x,
      next: res.next,
      prev: res.prev,
      total_page: res.total_page || 1,
      history: [...res.history],
    };
   
    setData(x);
    seCoachData(x.history)
    

    
    })
    .catch((error: any) => {
      console.error("Error fetching coach data:", error);
      notifyError(error);
    });
}, [cookie,progType,page]);

const handleShow=(uId:any)=>{
  setActiveTab(1)
  setCoachId(uId)
  
}

const decreasePage = () => {
  setData({ ...data, page: data.page - 1 });
};

const IncreasePage = () => {
  setData({ ...data, page: data.page + 1 });
};
  return (
    <>
    {activeTab==0 && (<>
    
      <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
        <div className={styles.UpcomingPListRecommendt}>
            <div className={styles.UpcomingPListLeft} style={{maxWidth:'340px'}}>
            <p onClick={() => setProgType(1)} className={`${styles.tab1} ${progType === 1 && styles.setliactive}`}>Coach Like Me</p>
            <p onClick={() => setProgType(2)} className={`${progType === 2 && styles.setliactive}`}>Coach Not  Like Me</p>
            
            </div>
            </div>
            
            </div>
            <div className={styles.DashForRecom}>
            <div className={styles.Recommond}>
         {coachData.length>0?(coachData.map((cdata:any)=><>
         
        
          <div className={styles.coaching}>
          <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.uid.profileImg}`}
                        className={styles.coachingImg}
                      />
              <div className={styles.coachingTxt} onClick={() => handleShow(cdata.uid._id)}>
                <p>{cdata.uid.name}</p>
                <div className={styles.category}>
                 
                 <span> {cdata.behavioralIdentity.slice(0, 2)}</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>{cdata.uid.email.slice(0,18)}..</span>
                </div>
              </div>
              </div>
          


         </>)):(<>No coach Data Found!</>)}
        </div>
        </div>
        <div className={styles.pagination}>
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
       </div>
          

    </>)} {activeTab===1 &&( <RecommendCoach setActiveTab={setActiveTab} coachId={coachId} />)}
  
   
    </>
    
  );
};  

export default Recommend;
