import ReactQuill from 'react-quill';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import style from "./blogs.module.css";
import { TextField } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import styles from "../wrapper/wrapper.module.css";
import { CreateNewBlog, EditNewBlog, fetchblogs } from '../../../../Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { notifyError, notifySuccess } from '../../../../Notify';

type propType = {
  blogId: any;
  setActiveTab: (val: (v: number) => number) => void;
  setUpdata: any
}

interface NewUser {
  pImg: File | null;
}

const EditBlogs = ({ blogId, setActiveTab, setUpdata }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  // States for the blog data
  const [blogImg, setBlogImg] = useState('');
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDesc, setBlogDesc] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [editorHtml, setEditorHtml] = useState('');

  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });

  const validateEditBlog = () => {
    if (blogTitle === "") {
      notifyError("Please enter title");
      return false;
    }
    else if (blogDesc === "") {
      notifyError("Please enter description");
      return false;
    }
    else if (blogContent.trim() == "" || blogContent == '<p><br></p>') {
      notifyError("Please enter content");
      return false;

    }
    else if (newUser.pImg === null && blogImg.trim() == "") {
      notifyError("Please select Blog image");
      return false;
    }
    else {
      return true
    }
  }



  useEffect(() => {
    fetchblogs(blogId)
      .then((res: any) => {
        setBlogTitle(res.data.title);
        setBlogDesc(res.data.description);
        setBlogImg(res.data.img);
        setBlogContent(res.data.content);
        setEditorHtml(res.data.content);
      })
      .catch((err: any) => console.error('Error fetching blog:', err));
  }, [blogId, cookie]);

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };


  const handleChange = (html: any) => {
    setEditorHtml(html);
    setBlogContent(html);
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateEditBlog()) {
      const formData = new FormData();
      formData.append("_id", blogId)
      formData.append("title", blogTitle);
      formData.append("description", blogDesc);
      formData.append("content", blogContent);
      if (newUser.pImg) {
        formData.append("blogImg", newUser.pImg);
      }
      else {
        formData.append("img", blogImg)
      }

      EditNewBlog(formData, cookie)
        .then((result: any) => {
          notifySuccess(result.message || "blog Updated Successfully");
          setActiveTab(() => 1);
          setUpdata(true)
        })
        .catch((error: any) => {
          //console.log(error);
          notifyError(error.response?.data?.message || "blog Not Updated");

        });
      setUpdata(false)

    }
  };

  return (
    <div>
      <div className={style.BlgContainer}>
        <form className={style.BlgContent} onSubmit={handleSubmit}>
          <div className={style.blgConleft}>
            <h6>Edit Blog Body</h6>
            <ReactQuill value={editorHtml} onChange={handleChange} className={style.blogContent} />
          </div>

          <div className={style.blgConright}>
            <h4>Edit a Blog Post</h4>

            <div className={styles.ProgramProp}>
              <div className={styles.onlinePro}>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleProfile}
                  id="fileInput"
                  name="profile"
                />

                {!previewImage ? (
                  <>
                    <img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${blogImg}`}
                      className={styles.MainImgPre}
                      alt="Current Blog Image"
                    />
                    <div className={styles.IconImg}>
                      <img
                        src="/images/svg/Coach/group-8291.svg"
                        onClick={handleProfileClick}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={previewImage}
                      alt="Preview"
                      className={styles.MainImgPre1}
                    />
                    <div className={styles.IconImg1}>
                      <img
                        src="/images/svg/Coach/group-8291.svg"
                        onClick={handleProfileClick}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <TextField
              type="text"
              id="outlined-basic"
              label="Blog Topic"
              variant="outlined"
              name="title"
              value={blogTitle}
              onChange={(e: any) => setBlogTitle(e.target.value)}
              className={style.Inputfield}
              InputProps={{
                className: `${style.muiInput}`,
              }}
              sx={{
                my: 1,
                "& .MuiInputBase-input": {
                  width: "100%",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "#fff",
                },
              }}
              required
            />

            <TextArea
              id="outlined-basic"
              placeholder="Blog Description"
              name="description"
              value={blogDesc}
              onChange={(e: any) => setBlogDesc(e.target.value)}
              rows={5}
              required
            />

            <button className={style.blogContentBtn}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBlogs;
