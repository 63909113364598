import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../@types/user";

export interface userState {
  user: null | UserType;
}

const initialState: userState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      //console.log("from redux",action.payload)
      sessionStorage.setItem('user',JSON.stringify(action.payload))
      // localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.user = null;
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
    },
    setProfileSetup: (state, action: PayloadAction<boolean>) => {
      state.user!.profileSetup = action.payload;
    },

    accountstatus: (state, action: PayloadAction<number>) => {
      state.user!.account_status = action.payload;
    },
  },
});

export const { login, logout, setProfileSetup, accountstatus } =
  userSlice.actions;

export default userSlice.reducer;
