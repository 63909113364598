import { useEffect, useState } from "react";
import style from "./wrapper.module.css";
import styles from "../CSS/dashboardHome.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/userSlice";
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { RootState } from "../../../../redux/store";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  setHead:any;
};
function SideBar({ activeTab, setActiveTab,setHead }: propType) {
  const user:any = useSelector((state: RootState) => state.user.user);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose= () => setOpen(false);
  const [hasShownModal, setHasShownModal] = useState(false);
 
  const handleClick = (index: number) => {
    setActiveTab(index);
    if (!user || !user.name) {
      return;
    }
    
    // Open modal based on user's status
   if(activeTab==0 || activeTab==1 || activeTab == 2 ||activeTab==5 || activeTab==6){
    if (!user?.quadrant || user?.upgrade === false && hasShownModal==false) {
      handleOpen();
      setHasShownModal(false);
    }
  }
  

  };
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const [showSidebar, setShowSidebar] = useState(true);
 
  const renderHead = () => {
    const titles = [
      "Welcome Dashboard",
      "Upcoming Coachings",
      "My Coaches",
      "Recommendations",
      "My Reports",
      "All Programs",
      "My Settings",
      "Assessment",
      "My Certificates",
      "FAQ's",
      "My Settings",
      "Group Engagement",
      "Messages",
    
    ];
    setHead(titles[activeTab - 1] || ""); 
  };
  useEffect(()=>{
    renderHead()
  },[activeTab])

  return (
    <>
      {/* main content */} <button onClick={toggleSidebar} className={style.toggleButton}>
        {showSidebar ? (
          <ViewSidebarIcon className={style.HideButton} />
        ) : (
          <ViewSidebarIcon />
        )}
      </button>
     
      <div className={style.sidebarContainer}>
        <div className={style.MenuToggleOpen}>
          <ViewSidebarIcon />
        </div>
        {showSidebar ? (
      <div className={style.sidebar}>
        <div className={style.smartlogo}>
          <div className={style.smarthlogo1}>
            <img
              src="./images/Coach/login/white-logo.png"
              className={style.whitebg}
            />
          </div>
          <div className={style.siderbarcontent}>
            
            <div className={style.content}>
              <div className={style.profile}  onClick={() => handleClick(11)}>
                {/* profile css  */}
                {/* profileImg */}
                {/* <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`} className={style.profileImg}/>
                 */}
                   <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`} className={style.profileImg}/>
                <div className={style.uname}>
                  <h2>{user?.name.slice(0,24)}</h2>
                  <p>{user?.email.slice(0,24)}</p>
                </div>
              </div>
              <div className={style.contentitem}>
                <ul className={style.tabCnt}>
                  <li
                    onClick={() => handleClick(1)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 1 && style.lidesign}`}>
                      {activeTab === 1 ? (
                        <img src="./images/Coach/login/dashboardwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/dashboard.svg" />
                      )}
                      Dashboard

                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(2)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 2 && style.lidesign}`}>
                      {activeTab === 2 ? (
                        <img src="./images/Coach/login/sessionwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/session.svg" />
                      )}
                      Upcoming Coaching
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(3)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 3 && style.lidesign}`}>
                      {activeTab === 3 ? (
                        <img src=" /images/svg/Coachee/myCoachWhite.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myCoach.svg" />
                      )}
                      My Coaches
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(6)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 6 && style.lidesign}`}>
                      {activeTab === 6 ? (
                        <img src="/images/svg/Coachee/whiteMyrepo.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                    All Programs
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(12)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 12 && style.lidesign}`}>
                      {activeTab === 12 ? (
                          <img src="./images/Coach/login/groupwhite.svg" />
                        ) : (
                          <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                    Group Engagement
                    </div>
                  </li>
                  {user?.upgraded==true && (
                  <li
                    onClick={() => handleClick(4)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 4 && style.lidesign}`}>
                      {activeTab === 4 ? (
                        <img src="/images/svg/Coachee/whitelike.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/Like.svg" />
                      )}{" "}
                      Recommendations
                    </div>
                  </li>
                  )}

                  <li
                    onClick={() => handleClick(5)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 5 && style.lidesign}`}>
                      {activeTab === 5 ? (
                        <img src="/images/svg/Coachee/whiteMyrepo.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                      My Reports
                    </div>
                  </li>
                 
                  <li
                    onClick={() => handleClick(7)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 7 && style.lidesign}`}>
                      {activeTab === 7 ? (
                        <img src="./images/Coach/login/settingwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/settings.svg" />
                      )}{" "}
                      My Settings
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(8)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 8 && style.lidesign}`}>
                      {activeTab === 8 ? (
                        <img src="/images/svg/Coachee/whiteSub.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myAssess.svg" />
                      )}{" "}
                      Assessment
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(9)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 9 && style.lidesign}`}>
                      {activeTab === 9 ? (
                        <img src="/images/svg/Coachee/whiteMyCerti.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myCertificate.svg" />
                      )}{" "}
                      My Certificates
                    </div>
                  </li>
                </ul>
                
                <hr />
                <div className={style.about}>
                  <ul>
                    <li>About us</li>
                    <li>How it works</li>
                    <li>Resources and articles</li>
                    <li onClick={() => dispatch(logout())}>logout</li>
                  </ul>
                </div>
              </div>
            </div>
         
          </div>

          <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                 {!user?.quadrant ? (<>Take Free Assessment</>):user?.upgraded==false?(<>Upgrade your Plan</>):(<></>)}   
                              </Typography>
                <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              ></Typography>
                {!user?.quadrant ? (<>
                <h6> Start your Free Assessment </h6>
                <p>Lorem Ipsum is simply dummy text of the printing and<br/> typesetting industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s, </p>
                <button onClick={()=>{setActiveTab(8);handleClose()}} className={styles.DeleteCard1}> Start Now </button>
                </>):user?.upgraded==false?(<>
                  <h6> Upgrade your Free Acccount </h6>
                <p>Lorem Ipsum is simply dummy text of the printing and<br/> typesetting industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s, </p>
                <button onClick={()=>{setActiveTab(1); handleClose()}} className={styles.DeleteCard1}> Upgrade Now </button></>):(<></>)}
                </Box>
                </Modal>
        </div>
      </div>
       ) : (
        <div className={style.Sidebar2}></div>
      )}
      </div>
    </>
  );
}

export default SideBar;
