import { Checkbox } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GetNotificationSetting, SendNotificationSetting } from "../../../../Api";
import style from "../../../../styles/setting.module.css";
import { notifySuccess } from "../../../../Notify";

const SetTab3 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[updata,setUpdata]=useState(false)

  // Initial state of checkboxes
  const [checked, setChecked] = useState({
    message: false,
    question: false,
    session: false,
  });

  // Fetch notification settings on mount
  const getSettings = () => {
  

    GetNotificationSetting(cookie)
      .then((res: any) => {
        // Check the structure of the response and update state accordingly
        //console.log("Fetched settings:", res.data);
        setChecked({
          message: res.data.message,
          question: res.data.question,
          session: res.data.session,
        });
      })
      .catch((error: any) => {
        console.error("Error fetching notification settings", error);
      });
  };

  useEffect(() => {
    if (cookie) {
      getSettings();
    }
  }, [cookie,updata]);

  // Handle changes in checkbox state
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { checked } = event.target;
    setChecked((prevChecked) => ({
      ...prevChecked,
      [name]: checked,
    }));

    SendNotificationSetting(cookie,checked,name)
      .then((res: any) => {
        //console.log("Settings updated:", res);
      })
      .catch((err: any) => {
        console.error("Error updating settings:", err);
      });
  };

  
  

  const handleUpdate=()=>{
    setUpdata(true|| false)
    notifySuccess("Update Recorded Successfully")
  }
  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>Notification</header>
        <div className={style.notification}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. Lorem Ipsum has been the industry's standards
          </p>
          <div className={style.NotifyType}>
            <h6>Type</h6>
            <h6>Notification</h6>
          </div>
          <div className={style.NotifyContainer}>
            <div className={style.NotifyData}>
              <p>Inbox Messages</p>
              <p>Lesson Question</p>
              <p>Session Reminder</p>
            </div>
            <div className={style.NotifyData1}>
              <Checkbox
                checked={checked.message}
                onChange={(e) => handleCheckboxChange(e, "message")}
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
              <Checkbox
                checked={checked.question}
                onChange={(e) => handleCheckboxChange(e, "question")}
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
              <Checkbox
                checked={checked.session}
                onChange={(e) => handleCheckboxChange(e, "session")}
                sx={{
                  color: "#0D386E",
                  "&.Mui-checked": {
                    color: "#0D386E",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.formbutton}>
          <button onClick={handleUpdate}>Update</button>
        </div>
      </div>
    </>
  );
};

export default SetTab3;
