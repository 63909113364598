import { useDispatch, useSelector } from "react-redux";
import styles from "./complete.module.css";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

import { logout } from "../../redux/userSlice";

const SetupComplete = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  //console.log("pro status", user?.profileSetup);
  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(logout());
    navigator("/")
  };
  return (
    <>
      <div className={styles.firstdiv}>
        <center>
          <h5>Your Profile Completed</h5>
        </center>
      </div>
      <center>
        <div className={styles.imagediv}>
          <img src={"/images/svg/Coach/profileclick.svg"} alt="" />
        </div>
      </center>

      <div className={styles.profilediv}>
        {/* </div>
      <div className={styles.inputfield}>
        <center>
          <p>"https://www.mastermindlounge.com/username"</p>
        </center>
      </div>
      <center>
        <div className={styles.sharediv}>
          <p>Share on social platforms</p>
        </div>
      </center>

      <div> */}
        <center>
          <h4>
            <b>Your profile is Under Observation !</b>
          </h4>
          
            <button className={styles.button} onClick={handleClick}>
              Continue to Login
            </button>
          
        </center>
      </div>
    </>
  );
};

export default SetupComplete;
