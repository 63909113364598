import React, { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { findMaxOccurrence } from "../../utils/MaxOccurance";
import { postQuardentDashboard } from "../../Api";
import "./Assessment.css";
import { notifyError } from "../../Notify";
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
const Assessment = () => {
  const loaderUrl = `/Build/abcd.loader.js`;
  const dataUrl = `/Build/abcd.data.unityweb`;
  const frameworkUrl = `/Build/abcd.framework.js.unityweb`;
  const codeUrl = `/Build/abcd.wasm.unityweb`;

  const [isLoading, setIsLoading] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [guestUser, setGuestUser] = useState({
    email: "",
    name: "",
  });
  const [optionsSelected, setOptionsSelected] = useState<any[]>([]);
  const { unityProvider, loadingProgression } = useUnityContext({
    loaderUrl: loaderUrl,
    dataUrl: dataUrl,
    frameworkUrl: frameworkUrl,
    codeUrl: codeUrl,
  });

  useEffect(() => {
    const checkLoadingProgress = () => {
      if (loadingProgression >= 1) {
        setIsLoading(false);
      }
    };

    const interval = setInterval(checkLoadingProgress, 100);
    return () => clearInterval(interval);
  }, [loadingProgression]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data && event.data.type === "SelectedOption") {
        //console.log("Option Received From Unity:", event.data.index);
        setOptionsSelected((prevState: any) => [
          ...prevState,
          event.data.index,
        ]);
      }
        if (
          event.data &&
          event.data.type === "GameEnd" &&
          event.data.index == "true"
        ) {
          //console.log("GameEnd message received. Waiting for 5 seconds...");
          setTimeout(() => {
           
            if (guestUser.name.trim() && guestUser.email.trim()) {
              SendQuadrantToServer();
            } else {
              console.error("Guest user data is incomplete.");
            }
          }, 5000);
        }
        return()=>{setIsEnd(true)}
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [guestUser, unityProvider, optionsSelected]);

  const QuardentMap: any = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
  };

  const SendQuadrantToServer = () => {
    const { maxChar }: any = findMaxOccurrence(optionsSelected);
    const quadrant = QuardentMap[maxChar];

    //console.log(optionsSelected, guestUser);

    postQuardentDashboard({
      email: guestUser.email,
      name: guestUser.name,
      quadrant,
    })
      .then((res) => {
        //console.log(res);
        setGuestUser({ email: "", name: "" });
        setOptionsSelected([]);
        // setIsEnd(!isEnd);
      })
      .catch((err)=>{}
      );
  };
  const toggleFullScreen = () => {
    const unityContainer: any = document.getElementById("unity-container");
    if (!unityContainer) {
      console.error("Unity container not found!");
      return;
    }

    if (!isFullScreen) {
      if (unityContainer.requestFullscreen) {
        unityContainer.requestFullscreen();
      } else if (unityContainer.mozRequestFullScreen) {
        unityContainer.mozRequestFullScreen();
      } else if (unityContainer.webkitRequestFullscreen) {
        unityContainer.webkitRequestFullscreen();
      } else if (unityContainer.msRequestFullscreen) {
        unityContainer.msRequestFullscreen();
      }
    } else {
      const doc: any = document;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }

    setIsFullScreen(!isFullScreen);
  };
  const handleStartGame = () => {
    if (guestUser.name.trim() == "") {
      notifyError("Enter Your Full Name!");
      return;
    }
    if (guestUser.email.trim() == "") {
      notifyError("Enter Your Correct Email!");
      return;
    }
    if (guestUser.name.trim() != "" && guestUser.email.trim() != "") {
      setIsEnd(false);
    }
  };

  return (
    <div className="container">
      {!isEnd ? (
        <div
          className="unity-container"
          id="unity-container"
          style={{ position: "relative" }}
        >
          {isLoading && (
            <div className="loading-overlay">
              Loading... {Math.round(loadingProgression * 100)}%
            </div>
          )}
          <Unity unityProvider={unityProvider} className="unity-canvas" />
          <button
            className="fullscreen-toggle"
            onClick={toggleFullScreen}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#0b34672a",
              border: "none",
              borderRadius: "20px",
              color: "white",
              padding: "10px",
            }}
          >
            {isFullScreen ?  <FullscreenExitIcon/>: <FullscreenTwoToneIcon/>}
          </button>
        </div>
      ) : (
        <div className="form-container">
          <input
            className="form-input"
            type="text"
            name="name"
            value={guestUser.name}
            placeholder="Enter Your Name"
            onChange={(e) =>
              setGuestUser({ ...guestUser, name: e.target.value })
            }
          />
          <input
            className="form-input"
            type="email"
            name="email"
            value={guestUser.email}
            placeholder="Enter Your Email"
            onChange={(e) =>
              setGuestUser({ ...guestUser, email: e.target.value })
            }
          />
          <button className="form-button" onClick={handleStartGame}>
            Discover Your Super Power
          </button>
        </div>
      )}
    </div>
  );
};

export default Assessment;
