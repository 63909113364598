import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ReplyIcon from '@mui/icons-material/Reply';
import { DeleteBlog, FetchBlogs } from "../../../Api";
import { RootState } from "../../../redux/store";
import style from "./wrapper/wrapper.module.css";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CreateBlog from "./Blogs/CreateBlog";
import { Box, Typography } from "@mui/material";
import { notifyError, notifySuccess } from "../../../Notify";
import ShowBlog from "./Blogs/ShowBlog";
import dayjs from 'dayjs';
import EditBlogs from "./Blogs/EditBlogs";
import Pagination from "../../Pagination/Pagination";

const Blogs = () => {
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [showBlogs, setShowBlogs] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState(1);
    const [updata, setUpdata] = useState(false)
    const [bname, setBname] = useState('')
    const [bdesc, setBdesc] = useState('')
    const [bid, setBid] = useState('')
    const [blogId, setBlogId] = useState()
    const [open, setOpen] = useState(false);
    const [myTimeOut, setMytimeOut] = useState<NodeJS.Timeout | null>(null);
    const [search, setSearch] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data, setData] = useState({
        prev: false,
        next: false,
        search: "",
        page: 1,
        total_page: 1,
        history: [],
    });
    const sendId = (e: any, gid: any) => {
        e.preventDefault()
        setBlogId(gid)
        setActiveTab(3)
    }
    const getId = (e: any, id: any, bname: string, desc: string) => {
        handleOpen()
        e.preventDefault();
        setBid(id)
        setBname(bname)
        setBdesc(desc)
    }
    const deleteBlog = (e: any) => {
        e.preventDefault();
        if (bid) {
            DeleteBlog(bid, cookie)
                .then((res: any) => {
                    notifySuccess(res.message)
                    setUpdata(true)
                    handleClose()

                })
                .catch((err: any) => {
                    notifyError(err.response.data.message);
                    handleClose()
                })
        }

        setUpdata(false)
    }

    const decreasePage = () => {
        setData({ ...data, page: data.page - 1 });
    };

    const IncreasePage = () => {
        setData({ ...data, page: data.page + 1 });
    };

    const handleSearch = () => {
        if (myTimeOut) clearTimeout(myTimeOut);
        setMytimeOut(
            setTimeout(() => {
                fetchBlogs();
            }, 500)
        );
    };

    useEffect(() => {

        handleSearch()

    }, [search])

    const fetchBlogs = () => {
        FetchBlogs(search, data.page, cookie)
            .then((result: any) => {
                //console.log(result)
                let res = result.data;
                let x: any = { ...data };
                x = {
                    ...x,
                    next: res.next,
                    prev: res.prev,
                    total_page: res.total_page || 1,
                    history: [...res.history],
                };
                setShowBlogs(x.history)
                setData(x)
            })
    }

    useEffect(() => {
        fetchBlogs()
    }, [cookie, updata, data.page])

    return (<>
        <div className={style.mainContiner}>
            <div className={style.addButton}>
                {activeTab == 1 && <div className={style.container11}>
                    <input
                        className={style.container2}
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search Blog Name here"
                        style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
                    /></div>} {activeTab == 2 && <>Add New Blog</>} {activeTab == 3 && <>View Blog</>} {activeTab == 4 && <>Edit Blog</>}
                <div className={style.upgradeButton} >
                    {activeTab === 1 && <button onClick={() => setActiveTab(2)}>
                        <AddIcon className={style.upgradeBtn} />
                        Add new Blog
                    </button>}
                    {activeTab === 2 && <span className={style.activeTb2} onClick={() => setActiveTab(1)}>
                        <ReplyIcon className={style.activeTab2} /> Back
                    </span>}
                    {activeTab == 3 && <button onClick={() => setActiveTab(4)}>
                        <AddIcon className={style.upgradeBtn} />
                        Edit Blog
                    </button>}
                    {activeTab === 4 && <span className={style.activeTb2} onClick={() => setActiveTab(1)}>
                        <ReplyIcon className={style.activeTab2} /> Back
                    </span>}

                    <br /><br />
                </div>
            </div>
            {activeTab === 1 && (
                <div className={style.displayTableCon}>
                    <table>

                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Date</th>

                            <th></th>
                        </tr>
                        {showBlogs.length > 0 ? (showBlogs.map((blogs: any) => (
                            <tr >
                                {/*  */}
                                <td className={style.blogsCnte}><p className={style.blogsCnteP}>{(blogs.title.split(" ").length > 1 ? (blogs.title.split(" ").map((word: any) => word.charAt(0)).join("").substring(0, 2).toUpperCase()) : (blogs.title.slice(0, 2).toUpperCase()))} </p><p>{blogs.title}</p></td>
                                <td style={{ textAlign: 'start' }}>{blogs.description.slice(0, 30)}</td>
                                <td>{dayjs(blogs.createdAt.slice(0, 10)).format('DD MMM YYYY')}</td>

                                <td className={style.editIcon}><img src="/images/svg/Admin/viewIcon.svg" onClick={(e: any) => sendId(e, blogs._id)} />

                                    <img src="/images/svg/Admin/deletIcon.svg" onClick={(e: any) => { getId(e, blogs._id, blogs.title, blogs.description.slice(0, 130)) }} /></td>
                            </tr>

                        ))) : (<tr><p>Data Not Available</p></tr>)}

                    </table>
                    <div className={style.upgradeButton}>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
                        >
                            <Box className={style.BoxStyle}>
                                <span onClick={handleClose} className={style.HandleClose}>
                                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                                </span>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h5"
                                    component="h2"
                                >
                                    <div className={style.thisData}>
                                        <h5>  Are you sure to Delete Blog?</h5>

                                    </div>

                                </Typography>
                                <Typography
                                    id="modal-modal-description"
                                    className={style.typoDesc}
                                >

                                    <div className={style.bdescContent}>
                                        <h6>{bname}</h6>
                                        <p>{bdesc} .....</p>
                                    </div>
                                    <div className={style.deleBgContent}>
                                        <button onClick={deleteBlog} className={style.deleBgred}><CheckBoxIcon /> Yes</button>
                                        <button className={style.deleBgblue} onClick={handleClose}><DisabledByDefaultIcon /> No</button>
                                    </div>
                                </Typography>
                            </Box>
                        </Modal>
                    </div>

                </div>
            )}
            {activeTab === 2 && (<CreateBlog setActiveTab={setActiveTab} setUpdata={setUpdata} />)}
            {activeTab === 3 && (<ShowBlog blogId={blogId} />)}
            {activeTab == 4 && (<EditBlogs blogId={blogId} setActiveTab={setActiveTab} setUpdata={setUpdata} />)}
            {activeTab == 1 && <Pagination
                next={data.next}
                page={data.page}
                prev={data.prev}
                decreasePage={decreasePage}
                IncreasePage={IncreasePage}
                total_page={data.total_page}
            />}
        </div>
    </>)
}
export default Blogs;