import { useEffect, useState } from "react";
import style from "../../../../styles/setting.module.css";
import { TextField } from "@mui/material";
import { certificates, certificatesPatch, FetchCoachInfo } from "../../../../Api";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { cdn_Link } from "../../../../config";

const SetTab2 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [isChecked1, setIsChecked1] = useState(false);
  const [isYesNo1, setisYesNo1] = useState(false);
  const [data, setData] = useState({
    certificationDetails: [],
    certified: false,
    ethics: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [certiIndex, setCertiIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [multiForms, setMultiForms] = useState<any[]>([
    {
      coachSchool: "",
      speciality: "",
      othercerti: "",
      certificateImg: null,
    },
  ]);
  const [userForm, setUserForm] = useState<any>(
    {
      coachSchool: "",
      speciality: "",
      certificateImg: null,
    }
  );
  const [loader, setLoader] = useState(false);

  const handleToggle = (type: string, checkboxNumber: number) => {
    if (type === "yesbox") {
      setIsChecked1(checkboxNumber === 1 ? true : false);
    } else if (type === "nobox") {
      setisYesNo1(checkboxNumber === 1 ? true : false);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserForm((prevForm: any) => ({
      ...prevForm,
      [name]: value, // Update the specific field of the form
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    setUserForm((prevForm: any) => ({
      ...prevForm,
      [name]: files ? files[0] : null, // Handle file input
    }));
  };
  const certified: any = isChecked1;
  const ethics: any = isYesNo1;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("certified", certified);
    formData.append("ethics", ethics);

      formData.append(`certificates[${certiIndex}][coachSchool]`, userForm.coachSchool);
      formData.append(`certificates[${certiIndex}][speciality]`, userForm.speciality);
      formData.append(`certificateImg`, userForm.certificateImg);
   

    setLoader(true);

    certificatesPatch(formData, cookie)
      .then((result: any) => {
        //console.log(result);
        setLoader(false);
      })
      .catch((error: any) => {
        //console.log(error);
        setLoader(false);
      });
  };

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const { certificationDetails, certified, ethics }: any = result.data;
        setData({ certificationDetails, certified, ethics });
      })
      .catch((err: any) => {});
  };



  const handleUpdate = (e: any, coachSchool: string, speciality: string, visib: boolean, index: any) => {
    e.preventDefault();
    
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setVisible(visib);
    setCertiIndex(index);

    setUserForm(
      {
        coachSchool: coachSchool,
        speciality: speciality
      }
    );
    setIsSubmitting(false);
  };


  useEffect(() => {
    fetchCoachDetails();
  }, [loader]);

  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>
          Coach and Related Certification
        </header>
        <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <header>Coaching Certificate</header>
            {/* <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked1}
                  onChange={() => handleToggle("yesbox", 1)}
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!isChecked1}
                  onChange={() => handleToggle("yesbox", 2)}
                  className={style.YesNo}
                />
                No
              </label>
            </div> */}
          </div>
          <div className={style.rightabcontent}>
            {/* <header>Understanding of coaching ethics</header> */}
            {/* <div className={style.choicetag}>
              <label>
                <input
                  type="checkbox"
                  checked={isYesNo1}
                  onChange={() => handleToggle("nobox", 1)}
                  className={style.YesNo}
                />
                Yes
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={!isYesNo1}
                  onChange={() => handleToggle("nobox", 2)}
                  className={style.YesNo}
                />
                No
              </label>
            </div> */}
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px", padding: "4px", flexDirection: "column" }}>
          {data.certificationDetails.length > 0 &&
            data.certificationDetails.map((elem: any, index: number) => (
              <div className={style.certificateDetails}>
                <div><b>Coach Training School name: </b> {elem?.coachSchool} </div>
                <div><b> Speciality Training: </b> {elem?.speciality} </div>
                {elem?.certificateImg && (
                  <>
                    <iframe src={`${cdn_Link}/${elem?.certificateImg}`} style={{ width: "400px" }} />
                    
                  </>
                )}
                <div className={style.formbutton}>
                      {/* <button onClick={(e: any) => {
                        if (isSubmitting) return;
                        handleUpdate(e, elem?.coachSchool, elem?.speciality, true, index);
                      }}>Click To Update</button> */}
                    </div>
              </div>
            ))}
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            {/* <h6>Update your Certificate</h6> */}

            {visible && (
              <>
              
                  <div  className={style.InputDataContainer}>
                    
                    <div className={style.leftInputData}>
                      <TextField
                        type="text"
                        id="outlined-basic"
                        label="Coach Training School"
                        variant="outlined"
                        name="coachSchool"
                        value={userForm.coachSchool}
                        onChange={handleInputChange}
                        className={style.Inputfield}
                        InputProps={{
                          className: `${style.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      />
                      <TextField
                        type="text"
                        id="outlined-basic"
                        label="Specialty Experience/Sweet Spot"
                        variant="outlined"
                        name="speciality"
                        
                        value={userForm.speciality}
                        onChange={handleInputChange}
                        className={style.Inputfield}
                        InputProps={{
                          className: `${style.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                      />
                    </div>
                    <div className={style.RightInputData}>
                      <TextField
                        type="file"
                        name="certificateImg"
                        onChange={handleFileChange}
                        className={style.Inputfield}
                        InputProps={{
                          className: `${style.muiInput}`,
                        }}
                        sx={{
                          mr: 1,
                          my: 1,
                          borderRadius: "20px",
                          "& .MuiInputBase-input": {
                            width: "100%",
                          },
                        }}
                        inputProps={{
                          accept: ".pdf",  
                        }}
                      />
                    </div>
                  </div>
             
              </>
            )}
          </div>

          {visible && (
            <div className={style.formbutton}>
              <button disabled={loader}>
                {loader ? "Loading" : "Update Certificate"}
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default SetTab2;
