import { useSelector } from "react-redux";
import { FetchPaymentList, PayoutCoach } from "../../../Api";
import { RootState } from "../../../redux/store";
import styles from "./wrapper/wrapper.module.css";
import { useEffect, useState } from "react";
import { cdn_Link } from "../../../config";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { notifyError, notifySuccess } from "../../../Notify";
import Pagination from "../../Pagination/Pagination";
const PaymentBill = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const[coachId,setCoachId]=useState('')
  const[amount,setAmount]=useState('')
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [data1, setdata1] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchPayment = () => {
    FetchPaymentList(cookie,data.page )
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };

        setdata(x);
      })
      .catch((err) =>{});
  };
  const fetchPaymentHistory = () => {
    FetchPaymentList(cookie,data1.page )
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data1 };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };

        setdata1(x);
      })
      .catch((err) => {});
  };


  useEffect(() => {
    fetchPayment();
    fetchPaymentHistory()
  }, [cookie,data.page,data1.page]);
  const decreasePage = () => {
    setdata({ ...data, page: data.page - 1 });
    setdata1({ ...data1, page: data1.page - 1 });
  };

  const IncreasePage = () => {
    setdata({ ...data, page: data.page + 1 });
    setdata1({ ...data1, page: data1.page + 1 });
  };
const handleTogleOpen=(e:any,coachId:any)=>{
  e.preventDefault()
  setCoachId(coachId)
  handleOpen1()
}
const payNow=(e:any,amount:any)=>{
  e.preventDefault();
  PayoutCoach(coachId,amount,cookie)
  .then((res:any)=>{
    notifySuccess(res.message)
    handleClose1()
    setAmount('')
  })
  .catch((err:any)=>{
    notifyError(err.response.data.message)
    handleClose1()
  })
}
  return (
    <>
      <div className={styles.mainContiner}>
        <table>
          <br />
          <tr>
            <th>Coach Name</th>
            <th>Coach Email</th>
            <th>Total Session</th>
            <th>Total Programs</th>
            <th>Total Revenue</th>
            <th></th>
          </tr>
          {data.history.map((elem: any, ind: number) => (
            <tr>
              <td style={{textAlign:'left'}}>
                {" "}
                <img
                  src={`${cdn_Link}/${elem?.coachProfileImg}`}
                  alt=""
                  style={{
                    height: "3rem",
                    width: "3rem",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                />
                {elem.coachName}
              </td>
              <td>{elem?.coachEmail}</td>
              <td>{elem?.totalsessions}</td>
              <td>{elem?.programData.length}</td>
              <td>
                {elem?.programData.reduce((sum: number, chp: any) => {
                  return sum + chp.overallCost;
                }, 0)}
              </td>
              <td><button onClick={(e)=>{handleTogleOpen(e,elem._id)}} className={styles.SaveButton}>Pay Now</button></td>
            </tr>
          ))}
        </table>
         <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
        <div className={styles.upgradeButton}>
            
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose1} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={styles.thisData}>
                  <h5> Pay to coach</h5>
                  
                  </div>
               
                </Typography>
                <Typography   id="modal-modal-description"
                 className={styles.typoDesc}>
                   <TextField
                type="text"
                id="outlined-basic"
                label="Enter Amount"
                variant="outlined"
                name="amount"
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}
                className={styles.Inputfield}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  my: 1,
                  borderRadius: "20px",
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  },
                }}
                required
              />
              <button className={styles.SaveButton} onClick={(e)=>payNow(e,amount)}>Make Payment </button>
              <button  onClick={handleClose1} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}}>  
              Cancel</button>
 
                </Typography>
              </Box>
            </Modal>  
            </div>
            <div>
              <h3>Payment History</h3>
              <table>
          <br />
          <tr>
            <th>Coach Name</th>
            <th>Coach Email</th>
            <th>Total Session</th>
            <th>Total Programs</th>
            <th>Total Revenue</th>
            <th> Payout Amount</th>
           
          </tr>
          {data1.history.map((elem: any, ind: number) => (
            <tr>
              <td style={{textAlign:'left'}}>
                {" "}
                <img
                  src={`${cdn_Link}/${elem?.coachProfileImg}`}
                  alt=""
                  style={{
                    height: "3rem",
                    width: "3rem",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                />
                {elem.coachName}
              </td>
              <td>{elem?.coachEmail}</td>
              <td>{elem?.totalsessions}</td>
              <td>{elem?.programData.length}</td>
              <td>
                {elem?.programData.reduce((sum: number, chp: any) => {
                  return sum + chp.overallCost;
                }, 0)}
              </td>
              <td>{elem?.amount}</td>
          
            </tr>
          ))}
        </table>

            </div>
            <Pagination
          next={data1.next}
          page={data1.page}
          prev={data1.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data1.total_page}
        />
      </div>
      
    </>
  );
};
export default PaymentBill;
