import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { createOnProgram, CreateSessionByCoachee, fetchCard, fetchCoachByCoachee, fetchCoacheeProgramByID, FetchSessionCoachee } from "../../../../Api";
import style from "./showPro.module.css";
import Modal from "@mui/material/Modal";
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EmailIcon from '@mui/icons-material/Email';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import styles from "../CSS/dashboardHome.module.css";
import { Box, MenuItem, Select, Typography, duration } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ShowRecordProgram from "./ShowRecordProgram";
import { notifyError, notifySuccess } from "../../../../Notify";
import dayjs from "dayjs";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ClockCircleFilled } from "@ant-design/icons";
import configuration from "../../../../config/configuration";
import FastRewindIcon from '@mui/icons-material/FastRewind';
type propType = {
  progId: any
  setActiveTab: (val: (v: number) => number) => void;
}

const stripepromise = loadStripe(configuration.stripePublicKey)
const PaymentFun = ({ progId,setActiveTab}: propType) => {
  const stripe = useStripe();
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [proImg, setProgImg] = useState("")
  const [pdate, setPdate] = useState("")
  const [desc, setDesc] = useState("");
  const [pname, setPname] = useState("")
  const [progType, setProgType] = useState()
  const [overallCost, setoverallCost] = useState("")
  const [disCost, setdisCost] = useState("")
  const [duration, setduration] = useState("")
  const [cardId, setCardId] = useState("")
  const [cards, setCards] = useState<any[]>([]);
  const [coachIdMatch, setCoachIDMatch] = useState('')
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [coachData, setCoachData] = useState<any[]>([]);
  useEffect(() => {
    fetchCoacheeProgramByID(cookie, progId)

      .then((res: any) => {
        setProgImg(res.data.programImg)
        setPname(res.data.name);
        setPdate(res.data.createdAt)
        setoverallCost(res.data.overallCost)
        setdisCost(res.data.disCost)
        setduration(res.data.duration)
        setDesc(res.data.description)
        setCoachData(res.data.coacheIds)
        setProgType(res.data.program_type)




      })
  }, [cookie, progId])
  // fetchCoachDataForCoachee
  // const 
  const fetchCards=()=>{
    fetchCard(cookie)
    .then((result: any) => {
      setCards(result.data)
      //console.log("Card details", result)

    })
    .catch((error: any) => {
      //console.log("from Card", error)
    })
  }
  const handleSession=()=>{
    FetchSessionCoachee(1,data.page,progId,cookie)
    .then((result:any)=>{
      let res = result.data;
      let x: any = { ...data };
      x = {
        ...x,
        next: res.next,
        prev: res.prev,
        total_page: res.total_page || 1,
        history: [...res.history],
      };
      
      setData(x)
      
    
    })
    .catch((error:any)=>{
      //console.log("from Card",error)
    })
  }
  useEffect(() => {
    handleSession();
    fetchCards();
  }, [cookie])
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const appType = "3"
    //  progId
    // coachIdMatch
    //  createOnProgram
    // cardId
    // progId: any,cardId:any,coachIdMatch:any,appType:any, cookie: string
    createOnProgram(progId, cardId, coachIdMatch, appType, cookie)
      .then(async (res: any) => {
       
        const { error, paymentIntent }: any = await stripe!.confirmCardPayment(
          res.data.clientSecret,
          {
            payment_method: cardId,
          }
        );
        if (paymentIntent && paymentIntent.status === "succeeded") {
          notifySuccess("Payment Successful");
           
        handleClose()

        }
        if (error && error.payment_intent.status === "succeeded") {
          notifySuccess("Payment Successful");
          setActiveTab(()=>0)
          handleClose()
        } else if (error) {
          //console.log(error);
          handleClose()
        }

      })
      .catch((err: any) => {
        notifyError(err.response.data.message)
      })


  }

  const handleCardVal = (event: any) => {

    setCardId(event.target.value)
  }
  const selectCoach = (coachId: any) => {

    setCoachIDMatch(coachId)
  }

  const convertDaysToMonthsAndDays = () => {
    const days:any=duration
    const months = Math.floor(days / 30);  
    const remainingDays = days % 30;

    return `${months} months  ${remainingDays} days`;
  };
  return (
    <>
      <div className={style.Allcontent}>
          <FastRewindIcon style={{cursor:'pointer',color:'#0C5899'}} onClick={()=>setActiveTab(()=>0)}/>
        <div className={style.ProgContent}>
          <div className={style.ProgContentLeft}>
            <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`} className={style.ProgImg} />
            <div>
              <h6 style={{fontWeight:'500',fontSize:'20px'}}>{pname}</h6>
              <p style={{color:'#0C5899'}}><AccessAlarmTwoToneIcon/> {dayjs(pdate.slice(0, 10)).format('DD MMM YYYY')}</p>
              <div style={{display:'flex'}}>
              <p style={{color:'#EF633E'}}> <MonetizationOnIcon/> {disCost?<> <del style={{color:'gray'}}>${overallCost}</del> ${disCost}</>:overallCost}</p>
              <p style={{color:'#7AA146',marginLeft:'20px'}}><ClockCircleFilled style={{marginRight:'6px'}}/> {convertDaysToMonthsAndDays()} </p>
              </div>
             
              <p></p>
            </div>
          </div>
          <div className={style.ProgContentRight}>
            <button onClick={handleOpen} style={{marginRight:'20px'}}>
              Enroll Now
            </button>
            <div className={styles.upgradeButton}>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styles.BoxStyle}>
                  <span onClick={handleClose} className={styles.HandleClose}>
                    <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                  </span>
                  <Typography id="modal-modal-title" variant="h5" component="h2">
                    {progType === 2 && <>Make Payment</>}
                    {progType === 1 && <>Enroll Live Class</>}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={styles.typoDesc}
                  >
                    {progType === 1 && (<>
                      <div className={style.PaymentDetails}>

                      </div>
                      <h6>Select Coach</h6>
                      <div className={style.coachDataModal}>

                        {coachData.length > 0 ? (coachData.map((coach: any) => (
                          <>
                            <div className={`${style.ModalContent} ${coachIdMatch == coach._id ? style.matchCoachId : style.notmatchCoachId}`} onClick={() => selectCoach(coach._id)}>
                              <img
                                src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                                className={style.ModalcoachingImg}
                              />
                              <h6>{coach.name}</h6>
                              <p><EmailIcon /> {coach.email}</p>


                            </div>
                          </>
                        ))) : (<></>)}

                      </div>
                      <div className={style.PaymentDetails}>
                        <h6>Total Payment </h6>
                        <p><MonetizationOnIcon /> {disCost?<> <del style={{color:'gray'}}> {overallCost}</del> {disCost}</>:overallCost}</p>
                      </div>
                      <h6> Select Payment </h6>
                      <Select
                        onChange={handleCardVal}
                        className={styles.cardDesignSelect}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Card"
                        style={{width:'100%'}}
                      >
                        {cards && cards.length > 0 ? (
                          cards.map(cinfo => (
                            <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
                              <div className={styles.MenuItems}>
                                <p><CreditCardIcon /> John Doe</p>
                                <p className={styles.MenuItemsIC}>
                                  <FormatListNumberedIcon className={styles.MenuItemsIC} />
                                  {' '}.... .... .... {cinfo.last4}
                                </p>

                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
                          </MenuItem>
                        )}
                      </Select>


                      <div className={style.ProgContentRight}>
                        <button onClick={handleSubmit} style={{width:'100%'}}>Pay Now </button></div>

                    </>)}
                    {progType === 2 && (<>
                      <div className={style.enrolledRecord}>
                        <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`} className={style.enrolledRecordImg} />
                        <div className={style.enrolledRecordData}>
                          <h6>{pname}</h6>
                          <p>{pdate.slice(0, 10)}</p>
                          <p>$  {overallCost}</p>
                        </div>

                      </div>
                      <div className={style.enrolledRecordPrice}>

                        <h4>Total Payment </h4>
                        <h6> $ {overallCost}</h6>
                      </div>
                      <Select
                        onChange={handleCardVal}
                        className={styles.cardDesignSelect}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Card"
                      >
                        {cards && cards.length > 0 ? (
                          cards.map(cinfo => (
                            <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
                              <div className={styles.MenuItems}>
                                <p><CreditCardIcon /> John Doe</p>
                                <p className={styles.MenuItemsIC}>
                                  <FormatListNumberedIcon className={styles.MenuItemsIC} />
                                  {' '}.... .... .... {cinfo.last4}
                                </p>

                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
                          </MenuItem>
                        )}
                      </Select>
                      <button className={style.enrolledRecordBtn}>Pay Now</button>
                    </>)}


                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>

        </div>
        <hr />
        <div className={style.ProgDescr}>
          <div className={style.ProgramDesc}>
            <h6> Description </h6>
            <p>{desc}</p>
           <br />
              <br />
            
          </div>
          <div className={style.ProgEnroll}>
            <p style={{color:'black',fontSize:'15px',fontWeight:'600'}}>Coaches Involved</p>
            <div className={styles.DashForRecom}>
              <div className={styles.Recommond}>
                
                {coachData.length > 0 ? (coachData.map((cdata: any) => (
                  <>
                  {/* <ShowCoach setActiveTab={setActiveTab} coachId={coachId} /> */}
                    <div className={styles.coaching} >
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.profileImg}`}
                        className={styles.coachingImg}
                      />
                      <div className={styles.coachingTxt} style={{cursor:'text',maxHeight:'130px'}}>
                        <p style={{fontWeight:'700',fontSize:'20px',color:''}}>{cdata.name}</p>
                        <div className={styles.Description}>

                          <p style={{color:'#0C5899',fontWeight:'400',marginTop:'-10px',fontSize:'13px'}}> {cdata.email}</p>
                          {/* <p>Know More..</p> */}
                        </div>

                      </div>
                    </div>
                  </>
                ))) : (<p>No coach Available</p>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function LiveProgram({ progId,setActiveTab }: propType) {
  return (
    <Elements stripe={stripepromise}>
       <PaymentFun progId={progId} setActiveTab={setActiveTab}/>
    </Elements>
  )
}
export default LiveProgram;