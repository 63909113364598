import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "../programs/programs.module.css"
import { RootState } from "../../../../redux/store";
import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Cancel } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "../programs/programs.module.css";
import styled from "../wrapper/wrapper.module.css";
import { Box, Modal, TextField, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import {  DeleteGroup, fetchAgroup, fetchCoach, UpdateNewGroup } from "../../../../Api";
import { notifyError, notifySuccess } from "../../../../Notify";
import Pagination from "../../../Pagination/Pagination";
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  upd:any;
  setPid:any;
  searchGroup:any
};
interface NewUser {
  coacheeIds: File | null;
  
}
const DispGroup = ({ setActivescreen,upd,setPid,searchGroup }: propType) => {
  const [groupData1, setGroupdata] = useState<any>([]);
  const [coachId, setCoachIds] = useState<any>([]);
  const [coacheeId, setCoacheeIds] = useState<any>([]);
  const[updata,seUpdata]=useState(false)
  const user = useSelector((state: RootState) => state.user.user);
  const [update, setUpdate] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [select,setSelect]=useState(1);
  const [open2, setOpen2] = useState(false);
  const [assignedCoach, setassignedCoach] = useState<any[]>([]);
  const [assignedCoachee, setassignedCoachee] = useState<any[]>([]);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [coacheeData, setCoacheeData] = useState<any>([]);
  const [newUser, setNewUser] = useState<NewUser>({
      coacheeIds:null
    });
    const [coachData, setCoachData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const[model,setModel]=useState({
    gId:'',
    gTopic:'',
    coachData:[],
    coacheeData:[]
  })
  const[DeleId,setDeleId]=useState('')
  const cookie: any = user?.token;
  const handlePid = (groupId:any) => {
    setActivescreen(()=>3);
    setPid(groupId);
  };
 const deleteGroupWId=(id:any)=>{
    handleOpen1()
    setDeleId(id)
  }
  const updateGroupData=(e:any,gId:any,gTopic:any,coachData:any,coacheeIds:any)=>{
    e.preventDefault()
    setModel({gId:gId,gTopic:gTopic,coachData:coachData,coacheeData:coacheeIds})
    handleOpen2()

  }
  const handleUpdate=(e:any)=>{
    e.preventDefault()
    seUpdata(true)
    UpdateNewGroup(model.gId,model.gTopic,coacheeIds,coachIds,cookie)
    .then((res:any)=>{
      seUpdata(false)
      notifySuccess(res.data.message||'Group Data Updated Successfully!')    
      handleClose2()
    })
    .catch((err:any)=>{
      seUpdata(false)
      handleClose2()
      notifyError(err.response.data.message)
    })
  }
 const DeleteAGroup=(e:any,gid:any)=>{
  e.preventDefault();
  if(gid){
 DeleteGroup(gid,cookie)
 .then((result: any) => {
  //console.log(result);
  setUpdate(prev => !prev)
  notifySuccess(result.response?.data?.message || "Group Deleted Successfully")
  handleClose1()
 
})

.catch((error) => {
  //console.log(error);
  notifyError(error.response?.data?.message || "Group Deleted Successfully")
  handleClose1()
});
}
 }
 const fetchGroup=()=>{
  
  fetchAgroup(searchGroup,data.page,cookie)
  .then((res: any) => {
    let x: any = { ...data };
    x = {
      ...x,
      next: res.next,
      prev: res.prev,
      total_page: res.total_page || 1,
      history: [...res.data.history],
    };
    setGroupdata(x.history);
    
    
  })
  .catch((error: any) => {
    console.error("Error fetching coach data:", error);
  });
 }
 useEffect(() => {
  fetchGroup()
}, [cookie,update,updata,upd,searchGroup,data.page]);
  //console.log(searchGroup)

  // alert(groupData);
  const coachIds=JSON.stringify(assignedCoach);
  const coacheeIds=JSON.stringify(assignedCoachee)
  const handleFile = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({ ...newUser, coacheeIds: file });
      //console.log(file)

    }
  };

  const handleAddCoach = (coachId: string) => {
    setassignedCoach([coachId]);
  };
  const handleRemoveCoach = (coachId: string) => {
    setassignedCoach([]);
  };

  const handleAddCoachee = (coacheId: string) => {
    if (!assignedCoachee.includes(coacheId)) {
      setassignedCoachee([...assignedCoachee, coacheId]);
    }
  };
  const handleRemoveCoachee = (coacheId: string) => {
    setassignedCoachee(assignedCoachee.filter((id) => id !== coacheId));
  };
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };
useEffect(()=>{
  // setCoacheeIds
  coachData.map((coach:any)=>{
    // //console.log(coach._id)
  })

})
  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  const handleChange = (e: any) => {
    setModel({ ...model, gTopic: e.target.value });
  };
  useEffect(() => {
    // role=${filter}&search=${search}&page=${page}
    fetchCoach(cookie,2,'',1)
      .then((result: any) => {
        //console.log("Coach data:", result);
        setCoacheeData(result.data.history);
        // setgroupTopic("")
        //console.log("coachee Data ", coacheeData);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error.response.data.message);
      });
  }, [cookie]);

  useEffect(() => {
    // // role=${filter}&search=${search}&page=${page}
    fetchCoach(cookie,1,'',1)
      .then((result: any) => {
        setCoachData(result.data.history);
      })
      .catch((err: any) => {
        console.error("Error fetching coach data:", err);
        notifyError(err.response.data.message);
      });
  }, [cookie]);

  return (
    <div >
      {" "}
      <table>
        <tr>
          <th> GROUP TOPIC </th>
          <th> ASSIGNED COACH </th>
          <th>TOTAL SESSIONS</th>
          <th>SESSION COMPLETED</th>
          <th>DATE</th>
          <th>TOTAL USERS</th>
          <th >  </th>
        </tr> 

        {groupData1?.length > 0 ? (
          groupData1.map((group: any, index: number) => (
            <>
              <tr>
                {/*  */}
              
                <td className={styles.groupInfo1}><span className={styles.groupIc}>{(group.groupTopic.split(" ").length>1?(group.groupTopic.split(" ").map((word:any) => word.charAt(0)).join("").substring(0,2) ):(group.groupTopic.slice(0,2)))}</span>
               <span> {group?.groupTopic}</span></td>
               <td className={styles.assignedCoach}>
                {group.coachIds.map((coach:any)=>(<>{coach.profileImg?(<>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach?.profileImg}`}
                    className={styles.groupIc1}/></>):(<><img src="/images/svg/Coach/setting/personal.svg" className={styles.groupIc1} /></>)}   {coach?.name} </>))}
               </td> 

<td>{group.totalSessions}</td>
<td>{group.completedSessions}</td>
               
                <td>{dayjs(group.createdAt).format('DD MMM,YYYY')}</td>
                <td>{group.coacheeIds.length}
</td>
<td className={styles.editIcon}>
<img src="/images/svg/Admin/viewIcon.svg" onClick={()=>handlePid(group._id)}/>
                      <img src="/images/svg/Admin/EditIcon.svg"  onClick={(e:any) =>updateGroupData(e,group._id,group.groupTopic,group.coachIds,group.coacheeIds)}/>
                      <img src="/images/svg/Admin/deletIcon.svg"   onClick={() =>deleteGroupWId(group._id)}/>
                      {/* onClick={(e:any) => DeleteAGroup(group._id,e)} */}
</td>
              </tr>
            </>
          ))
        ) : (
          <tr>
            <td>No Group data available</td>
            
          </tr>
        
        )}
      
      </table>

      <div className={styled.upgradeButton}>
            
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={styled.BoxStyle}>
                <span onClick={handleClose1} className={styled.HandleClose}>
                  <CloseTwoToneIcon className={styled.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={styled.thisData}>
                  <h5> Delete Group</h5>
                  
                  </div>
               
                </Typography>
                <Typography   id="modal-modal-description"
                 >
                    
 Are you sure Delete Group? <br/>
 {/*  */}
 <button className={styles.delete} onClick={(e:any)=>DeleteAGroup(e,DeleId)}><DeleteIcon /> Delete </button>
 <button className={styles.DontDelete} onClick={handleClose1}><Cancel /> Cancel </button>
                </Typography>
              </Box>
            </Modal>  
          </div>


          <div>
          <Modal  open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}>
             
              <Box className={styled.BoxStyle}>
                  <span onClick={handleClose2} className={styled.HandleClose}>
                    <CloseTwoToneIcon className={styled.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                   <h3> Update Group</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={styled.typoDesc}
                  >
                     <form className={styled.programForm} onSubmit={handleUpdate}>
                    <TextField
            type="text"
            id="outlined-basic"
            label="Group Topic"
            variant="outlined"
            name="gTopic"
            value={model.gTopic}
            onChange={handleChange}
            className={styled.Inputfield}
            InputProps={{
              className: `${styled.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}
            required
          />

           <h6>Add Coaches</h6>
          <div className={styled.AddCoachList}>
          {coachData.length > 0 ? (
                coachData.map((coach: any, index: number) => (<>
                <div className={styled.CoachlstP}>
                <p>
                  {coach.profileImg?
                  (
                    <img
                    src={`${cdn_Link}/${coach.profileImg}`}
                    className={styled.coachImg}
                  />
                  ):( <img src="/images/svg/Coach/setting/personal.svg" className={styled.coachImg2} />)}
               
                 {coach.name} <p className={styled.Coachlstemal}> {coach.email}</p></p>

<p >
{assignedCoach.includes(coach._id) ? ( <CloseTwoToneIcon className={styled.CoachlstIconClose}  onClick={() => handleRemoveCoach(coach._id)}/>)
:( <AddIcon className={styled.CoachlstIcon}  onClick={() => handleAddCoach(coach._id)}/>)}
 </p>
 
                 </div>
                </>
                ))
              ) : (
                <p>No Group data available</p>
              )}
          
          </div>
          <p>Add Coachee</p>
  <div className={styled.onlineRadioBtn1}> 
                      <p  onClick={()=>setSelect(1)} className={`${styled.RadioNonActive} ${select === 1 && styled.RadioActive}`}><p></p> By Mail </p>
                      <p className={`${styled.RadioNonActive} ${select === 2 && styled.RadioActive}`} onClick={()=>setSelect(2)}><p></p>By Excel File </p>
                      </div>

                      {select===2?(<>
                      <div className={style.addExcel}>
                      <div className={style.Filetext}>
            <FileCopyIcon  className={style.FileIcon}/>
            <h6>Upload Excel File</h6>
            <p className={style.Filep}>Lorem ipsom is simple dummy text that can people can that <br/> can under tool so can goes <br/>
              dotted on hiligter ipsom so not needed.</p>
            <input type="file" accept=".xls, .xlsx" onChange={handleFile}/>
            
          </div>
          <button  className={styled.SaveButton}>
              Save
           
            </button>
            <button  onClick={handleClose2} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
            Cancel</button>

         
                      </div>
                      </>):(
                        <>
<div className={styled.AddCoachList}>
          {coacheeData.length > 0 ? (
                coacheeData.map((coachee: any, index: number) => (<>
                <div className={styled.CoachlstP}>
                <p>
                  {coachee.profileImg?
                  (
                    <img
                    src={`${cdn_Link}/${coachee.profileImg}`}
                    className={styled.coachImg}
                  />
                  ):( <img src="/images/svg/Coach/setting/personal.svg" className={styled.coachImg2} />)}
               
                 {coachee.name} <p className={styled.Coachlstemal}> {coachee.email}</p></p>

<p >
{assignedCoachee.includes(coachee._id) ? ( <CloseTwoToneIcon className={styled.CoachlstIconClose}  onClick={() => handleRemoveCoachee(coachee._id)}/>)
:( <AddIcon className={styled.CoachlstIcon}  onClick={() => handleAddCoachee(coachee._id)}/>)}
 </p>
 
                 </div>
                </>
                ))
              ) : (
                <p>No Group data available</p>
              )}
          
          </div>
          <button  className={styled.SaveButton}>
              Save
           
            </button>

                        </>
                      )}
          </form>
          

                  </Typography>

              </Box>
            </Modal>
          </div>
          <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
    </div>
  );
};

export default DispGroup;
