import { Box, Button, MenuItem, Select } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { fetchCard, upgradePlans } from "../../../Api";
import { useSelector } from "react-redux";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { RootState } from "../../../redux/store";
import { notifyError, notifySuccess } from "../../../Notify";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import configuration from "../../../config/configuration";
const strp = loadStripe(configuration.stripePublicKey);


const HandleMyReport=()=>{
  const user = useSelector((state: RootState) => state.user.user);
  const stripe = useStripe();
  const cookie: any = user?.token;
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const[activeFree,setActiveFree]=useState(0)
  const[loading,setLoading]=useState(false)
  const [cardId, setCardId] = useState("")
  const [cards, setCards] = useState<any[]>([]);
  //console.log("upgrade is:",user?.upgraded)
  useEffect(() => {
    fetchCard(cookie)
      .then((result: any) => {
        setCards(result.data)
        //console.log("Card details", result)

      })
      .catch((error: any) => {
        //console.log("from Card", error)
      })
  }, [cookie])
  const handleCardVal = (event: any) => {

    setCardId(event.target.value)
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true)

    upgradePlans( cardId, cookie)
      .then(async (res: any) => {
       
        const { error, paymentIntent }: any = await stripe!.confirmCardPayment(
          res.data.clientSecret,
          {
            payment_method: cardId,
          }
        );
        if (paymentIntent && paymentIntent.status === "succeeded") {
          notifySuccess("Payment Successful! ");
          setLoading(false)
           
        handleClose()

        }
        if (error && error.payment_intent.status === "succeeded") {
          notifySuccess("Payment Successful");
          setLoading(false)
          handleClose()
        } else if (error) {
          //console.log(error);
          handleClose()
        }

      })
      .catch((err: any) => {
        notifyError(err.response.data.message)
      })


  }
  
  return (
    <>
    {activeFree==0 && (
     <div className={styles.dashcontainer}>
        <div className={styles.upgradeTap}>
          <div className={styles.upgradeTxt}>
            <h3>Upgrade to</h3>
            <h2>Comprehensive Report</h2>
            <br />
            <h4>Features</h4>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
          </div>
          <div className={styles.upgradeButton}>
            {user?.upgraded==false  && <Button onClick={handleOpen}> Upgrade Now</Button>}
            
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>

                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Make Payment
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={styles.typoDesc}
                >
                  <div className={styles.UpgradeDiv}>
                    <img src="./images/Coachee/placeholder.png" />
                    <div className={styles.UpgradeText}>
                      <br />
                      <h6>Upgrade to Comprehensive Report</h6>
                      <p>-Lorem Ipsum is simply dummy.</p>
                      <p>-Lorem Ipsum is simply dummy.</p>
                      <p>-Lorem Ipsum is simply dummy.</p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.TotalPay}>
                    <h6>Total Payment</h6>
                    <h6> $250.00 </h6>
                  </div>
                  <br />
                  <Select
                        onChange={handleCardVal}
                        className={styles.cardDesignSelect}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Card"
                      >
                        {cards && cards.length > 0 ? (
                          cards.map(cinfo => (
                            <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
                              <div className={styles.MenuItems}>
                                <p><CreditCardIcon /> John Doe</p>
                                <p className={styles.MenuItemsIC}>
                                  <FormatListNumberedIcon className={styles.MenuItemsIC} />
                                  {' '}.... .... .... {cinfo.last4}
                                </p>

                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
                          </MenuItem>
                        )}
                      </Select>
                  <Button
                    className={styles.TypoButton}
                    sx={{ m: 1, width: "100%" }}
                    onClick={(e:any)=>handleSubmit(e)}
                    disabled={loading}>
                    {loading==true?'Pay Now':'Please Wait..'}
                  </Button>
                  <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                Cancel</button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <div className={styles.LearnMore}>
          <h5>Learn More</h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
        </div>

        {/*                             Read More                             */}
         <div className={styles.UpcomingContainer}>
         <div className={styles.ShowLecture}>
         <div className={styles.Recommond}>
          <div className={styles.coaching}>
            
              <img src="./images/Coachee/p1.png" className={styles.coachingImg} />
             
            
              <div className={styles.coachingTxt}>
               <p>Get Free Consultation</p>
               <span>
                Lorem ipsom is simple dummy text that can used for add dummy type to them 
                and that can helps for on that people ok ndjsd jdjs
               </span><br/>
               <button className={styles.ConsultRead} onClick={()=>setActiveFree(1)}>Read More</button>
              </div>
            
             
              </div>
              
            
          </div>
           
        </div>
        </div>
        </div>
        )}
        {activeFree==1 && (<div className={styles.ReadContent}>

          <div className={styles.ReadMore}>
            <div className={styles.ReadMoreHead}>
          <img src="./images/Coachee/p1.png" className={styles.coacheeReportImg} />
          <div className="ReadMore">
          <h5>Get Free Consultation</h5>
          <p className={styles.coacheeReportPara}><AccessAlarmTwoToneIcon/> 15-Jan-2022 15:23 pm</p>
          </div>
              
            </div>
          <button className={styles.Phonewith}><PhoneRoundedIcon/> Schedule Call with DSBS</button>
          <hr/>
          </div>

<div>
  <h6>About Program</h6>
  <p>
  Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.

  </p>
</div>
        </div>)}
    </>
  )

}

const MyReport=() =>{
  return(
    <Elements stripe={strp}>
  <HandleMyReport />
</Elements>
  )
 
}

export default MyReport