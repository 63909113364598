import styles from "../styles/SignUp.module.css";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { googleLoginapi, SignUpApi } from "../Api";
import { UserType } from "../@types/user";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import AuthWrapper from "../components/layouts/authWrapper";
import { notifyError, notifySuccess } from "../Notify";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";


const Signup = () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const [activeSignUp, setActiveSignUp] = useState(1);
  const [rememberMe, setRememberMe] = useState(true);
  const role = activeSignUp;
  const rememberMeFun = (user: any) => {
     sessionStorage.setItem("user", JSON.stringify(user));
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const googleSuccess = (res: any) => {
    googleLoginapi({ access_token: res.access_token, role: role })
      .then((response: any) => {
        let user: UserType = {
          ...response.data.user,
          token: response.data.token,
        };
        rememberMeFun(user);
        dispatch(login(user));
        notifySuccess("Login Successfully");
      })
      .catch((error: any) => {
        notifyError(error.response.data.message);
      });
  };

  const googleFailure = (err: any) => {
    notifyError("Google Login Failed");
  };

  const Glogin = useGoogleLogin({
    onSuccess: googleSuccess,
    onError: googleFailure,
  });


  const validationSchema = yup.object({
  name:yup
  .string()
  .required('Name is required')
  .matches(/^[^\d]*$/, 'User Name must not contain  a number')
  .max(255, 'User Name must not exceed 255 characters'),
    email: yup
    .string()
    .email('Email is in an invalid format')
    .required('Email is required')
    .max(255, 'Email must not exceed 255 characters')
    .matches(/^[^\d].*$/, 'Email must not start with a number')
    .matches(/@[^0-9]*$/, 'Email must not contain a number after the @')
    .matches(/^[^@\s]+@[^@\s]+\.[^@\s]+$/, 'Email must be a valid email address'),
    password: yup.string().required('Password is required')
      .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
      .matches(/(?=.*\d)/, 'Password must include at least one number')
      .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
      .min(8, 'Password must be at least 8 characters long')
      .max(50, 'Password must be at most 50 characters long'),
    cpassword: yup.string().required('Password is required')
    .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
    .matches(/(?=.*\d)/, 'Password must include at least one number')
    .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
    .min(8, 'Password must be at least 8 characters long')
    .max(50, 'Password must be at most 50 characters long'),

      
  })
  const formik = useFormik({
    initialValues: {
     name:'',
      email: '',
      password: '',
      cpassword:''
    },
    validationSchema,
    onSubmit: (values: any) => {
      const {name,  email, password,cpassword } = values;
      SignUpApi(name, email, password,cpassword, role)
        .then((result: any) => {
          let x: UserType = { ...result.data.user, id: result.data.id };
          dispatch(login(x));
          sessionStorage.setItem("user", JSON.stringify(x));
          notifySuccess(result.message);

        })
        .catch((error) => {
          notifyError(error.response.data.message);

        });
    },
  });



  const handleClick = (index: number) => {
    setActiveSignUp(index);
  };



  const responseFacebook = () => {
    const url = `https://dev-dsbs-api.flynautstaging.com/api/v1/auth/facebook?role=${role}`;
    window.location.href = url;
  }
  // const handleSignUp = async (e: React.FormEvent) => {
  //   e.preventDefault();


  // };
  // useEffect(() => {
  //   requrestPermission();
  // }, []);



  // async function requrestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const Tok = await getToken(messaging, {
  //       vapidKey:
  //         "BKk19EeGEn5cCqbZwWAmVinfXoLk7a4uxZGe6PfUp5MFMS6j6_CM2fdVfo0bNlFksm_AQ6LZZWbiEcIKY_mZo4M",
  //     });
  //     //console.log(Tok);
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }


  return (
    <NotAuthenticatedLayout >
      <AuthWrapper
        heading="Sign Up"
        message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      >
        <div className={styles.tabcontainer}>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => handleClick(1)}
              className={`${activeSignUp === 1 ? styles.coachbutton : null}`}
            >
              {activeSignUp === 1 ? (
                <p className={styles.coachbutton}>Coach</p>
              ) : (
                <p>Coach</p>
              )}
            </Button>
            <Button
              onClick={() => handleClick(2)}
              className={`${activeSignUp === 2 ? styles.coachbutton : null}`}
            >
              {activeSignUp === 2 ? (
                <p className={styles.coachbutton}>Coachee</p>
              ) : (
                <p>Coachee</p>
              )}
            </Button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="SignUp">
            <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    label="Enter Name"
                    sx={{ m: 0, width: "100%" }}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <PermIdentityOutlinedIcon
                            sx={{ color: "#B5B5BE", mr: 0.5, my: 0.5 }}
                          />
                      ),
                    }}
                  />
                </Box>
              </div>
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="email"
                    name="email"
                    type="text"
                    label="Email Address"
                    sx={{ m: 0, width: "100%" }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <MailOutlineIcon
                        sx={{ color: "#B5B5BE", mr: 0.5, my: 0.5 }}
                      />
                      ),
                    }}
                  />
                </Box>
              </div>
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Create Password"
                    sx={{ m: 0, width: "100%" ,cursor:'pointer'}}
                    variant="standard"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                           <div style={{ color: "#B5B5BE", marginRight: 1, marginBottom: 0.5,cursor:'pointer' }}  onClick={handleTogglePasswordVisibility}>
                
                {showPassword ? (
<VisibilityOffIcon style={{ color: '#B5B5BE' }} />
) : (
<VisibilityIcon style={{ color: '#B5B5BE' }} />
)}
             </div>
                       
                     </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </div>
              <div className={styles.InputBox}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField

                    id="cpassword"
                    name="cpassword"
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm password"
                    sx={{ my: 0, width: "100%",cursor:'pointer' }}
                    variant="standard"
                    onChange={formik.handleChange}
                    value={formik.values.cpassword}
                    error={formik.touched.cpassword && Boolean(formik.errors.cpassword)}
                    helperText={formik.touched.cpassword && formik.errors.cpassword}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <div style={{ color: "#B5B5BE", marginRight: 1, marginBottom: 0.5,cursor:'pointer' }}  onClick={handleTogglePasswordVisibility}>
                
                {showPassword ? (
<VisibilityOffIcon style={{ color: '#B5B5BE' }} />
) : (
<VisibilityIcon style={{ color: '#B5B5BE' }} />
)}
             </div>
                        </InputAdornment>
                      ),
                    }}
                  />

                </Box>
              </div>
            </div>

            <div>
              <button className={`${styles.btn} ${styles.btn1}`} type="submit">
                Create an account
              </button>
            </div>
            <p>-----------------Instant Signup-----------------</p>
            <div>
              <button className={`${styles.btn} ${styles.btn2}`}  onClick={() => Glogin()}>
                <img src="/images/Coach/Google.png" />
                continue with google
              </button>
            </div>
            <div>
              <button className={`${styles.btn} ${styles.btn3}`} onClick={responseFacebook}>
                <img src="/images/Coach/f-icon.png" />
                continue with Facebook
              </button>
            </div>
            <div>
              <button className={`${styles.btn} ${styles.btn1}`} onClick={()=>navigate("/login")}>
                Login
              </button>
            </div>
            <div>
              {/* <button className={`${styles.btn} ${styles.btn4}`}>
            <img src="/images/Coach/insta-icon.png" alt="" />
            continue with Instagram
          </button> */}
            </div>
          </form>
        </div>
      </AuthWrapper>
    </NotAuthenticatedLayout>
  );
};

export default Signup;
