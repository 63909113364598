import { json } from "stream/consumers";
import { Axios } from "./Axios";
import { number } from "yup";
import { AnyCnameRecord } from "dns";

const triggerBackgroundAuthentication = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/background-authentication`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve("");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const LoginApi = (email: string, password: string, role: number) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/login`,
      {
        email: email,
        password: password,
        role: role
      },
      {
        //  withCredentials:true
      }
    )

      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const googleLoginapi = ({ access_token, role }: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/googlelogin`,
      {
        access_token: access_token,
        role: role,
      },
      {
        //  withCredentials:true
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const facebookLoginapi = ({ accessToken, role }: { accessToken: string, role: string | number }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/facebook-login`,
      {
        accessToken: accessToken,
        role: role,
      }
    )
      .then((result) => {
       
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const VerifyApi = (concatValue: any, id: string, reqtype: string) => {
 
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/verify`,
      {
        otp: concatValue,
        refId: id,
        reqtype: reqtype,
      },
      {}
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err: any) => {
        reject(err.data);
      });
  });
};

const ResetPass = (newPassword: any, cPassword: any, cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/reset-password`,
      {
        newPassword: newPassword,
        cPassword: cPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const SignUpApi = (
  name: string,
  email: string,
  password: string,
  cpassword: string,
  role: number
) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/signup`,
      {
        name: name,
        email: email,
        password: password,
        cpassword: cpassword,
        role: role,
      },
      {}
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ContactInfo = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/coach/contactInfo`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully fetched data!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ContactFormData = (formData: FormData) => {
  return new Promise((resolve, reject) => {
    Axios.post("/api/v1/contactUs", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const blogs = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/api/v1/blogs", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully Fetch Blog data");
      })
      .catch((error) => {
        reject(error);
        console.error("Error saving contact data:", error);
      });
  });
};

const fetchblogs = (bId: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/blogs-details?bId=${bId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully Fetch Blog data");
      })
      .catch((error) => {
        reject(error);
        console.error("Error saving contact data:", error);
      });
  });
};
const fetchContactUs = (cookie: string, page: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/contactUs`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const ContactInfoPatch = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coach/contactInfo`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully fetched data!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const certificates = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/certificates`,
      formData,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const certificatesPatch = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/certificates`,
      formData,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const coachexp = (
  yearExp: number,
  style: string,
  model: string,
  perfect: string,
  challenges: string,
  currentprofession: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/coachexp`,
      {
        yearExp,
        style,
        model,
        perfect,
        challenges,
        currentprofession,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const coachexpPatch = (
  yearExp: string,
  style: string,
  model: string,
  perfect: any,
  challenges: any,
  currentprofession: any,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/coachexp`,
      {
        yearExp,
        style,
        model,
        perfect,
        challenges,
        currentprofession,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const administration = (Mprocess: string, Wprocess: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coach/administration`,
      {
        Mprocess,
        Wprocess,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const bankDetails = (
  bankaccount: any,
  ssNumber: string,
  bankToken: string,

  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/bank-details`,
      { bankaccount, ssNumber, bankToken },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const bankDetailsUpdate = (
  bankaccount: any,
  bankToken: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/bank-details`,
      { bankaccount, bankToken },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const FetchbankDetails = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/bank-details`, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const administrationPatch = (
  Mprocess: string,
  Wprocess: string,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/administration`,
      {
        Mprocess,
        Wprocess,
      },
      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CoacheePersonalInfo = (formData: any, cookie: string) => {
  //console.log("data", formData);
  for (const [key, value] of formData.entries()) {
    //console.log(`${key}: ${value}`);
  }
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coachee/personalInfo`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
// fetchAdmin

const fetchAdmin = (filter: any, search: any, page: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/fetchallusers?role=${filter}&page=${page}&search=${search}`,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeDetails = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee`,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoach = (cookie: string, filter: any, search: any, page: any, currentDate?: any) => {
  return new Promise((resolve, reject) => {
    //console.log(currentDate);

    Axios.get(
      `/api/v1/admin/fetchallusers?role=${filter}&search=${search}&page=${page}&currentDate=${currentDate}`,

      {
        headers: { Authorization: `Bearer ${cookie}` },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const postQuardentDashboard = ({ quadrant, email, name }: { quadrant: string, email: string, name: string }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/dashboard-assessment`, { quadrant, email, name }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoachPayment = (page: number, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/coach-payment`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const fetchUsersId = (cookie: string, uid: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/getUser`, uid, {
      headers: {
        Authorization: `Bearer ${cookie}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const OfflineProgramData = (formData: any, cookie: string) => {
  //console.log("offline Data", formData);
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/offline_program`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchPrgId = (cookie: string, pid: any) => {
  //console.log("id of program", cookie);
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/program/${pid}`, {
      params: {
        // role and page and search
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const AddCard = ({ cToken, cookie }: { cToken: any; cookie: string }) => {
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/card`,
      { cToken },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCard = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/card`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachData = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachGroup = (cookie: string,page:number,search:any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/joinedGroup?page=${page}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const FetchPaymentList = (cookie: string, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/payments?page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchSessionAdmin = ({
  cookie,
  filter,
  page,
}: {
  cookie: string;
  filter: number;
  page:number;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/session?filter=${filter}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoacheeGroup = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/group`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoacheeGroupId = (cookie: string, groupId: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/group-details?gid=${groupId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachGroupID = (groupId: any, cookie: string) => {

  return new Promise((resolve, reject) => {

    Axios.get(`/api/v1/coach/fetchgroup?groupId=${groupId}`, {

      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const checkRecommend = (groupId: any, cookie: string) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/fetchgroup?groupId=${groupId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachprogramByID = (cId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/program-enrolled-coach?cId=${cId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const createOffProgram = (
  progId: any,
  cardId: any,
  appType: any,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/enroll-program`,
      { pId: progId, cardId: cardId, appType: appType },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });

}
const upgradePlans = (cardId: any, cookie: string) => {
  return new Promise((resolve, reject) => {

    Axios.post(`/api/v1/coachee/upgrade-profile`,
      { cardId: cardId },
      { headers: { Authorization: `Bearer ${cookie}` } })
      .then(result => {

        resolve(result.data);
        //console.log("successfully Plan Upgraded!!")
      })
      .catch(err => {

        reject(err);

      })
  });

}

const createOnProgram = (progId: any, cardId: any, coachIdMatch: any, appType: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coachee/enroll-program`,
      { pId: progId, cardId: cardId, coach_Id: coachIdMatch, appType: appType },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const CreateProgram = (formData: any, cookie: string) => {
  //console.log("Online Data", formData);
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(`/api/v1/admin/program`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateProgram = (formData: any, cookie: string) => {
  //console.log("Online Data", formData);
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/program`, formData, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateSession = (
  pId: any,
  title: any,
  expected: any,
  duration: any,
  cookie: string
) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/chapter`,
      { pId: pId, title: title, expected: expected, duration: duration },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateSession = (
  cId: any, pId: any, title: any, expected: any, duration: any, appType: any,
  cookie: string
) => {

  return new Promise((resolve, reject) => {
    // pid
    Axios.patch(
      `/api/v1/admin/chapter`,
      { cId: cId, pId: pId, title: title, expected: expected, duration: duration },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeletegroupSession = (sessionId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/createsession`, {
      data: {
        sessionId: sessionId,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateOffSession = (
  programId: any,
  title: any,
  expected: any,
  durat: any,
  resource: any,
  cookie: string
) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/chapter`,
      {
        pId: programId,
        title: title,
        expected: expected,
        duration: durat,
        resource: resource,
      },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoachByCoachee = (page: number, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/fetch_coachs`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCoachByCoacheeCopy = (search: any, page: number, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/fetch_coachs`, {
      params: {
        // role and page and search
        page: page,
        search: search
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const CreateLiveSession = (coacheeId: any, pId: any, starttime: any, endtime: any, date: any, cookie: string) => {

  return new Promise((resolve, reject) => {
    // pid
    Axios.post(`/api/v1/coach/session`,
      {
        coacheeId: coacheeId,
        pId: pId,
        starttime: starttime,
        endtime: endtime,
        appType: 1,
        date: date
      }, {
      headers: { Authorization: `Bearer ${cookie}` },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateFAQ = (question: string, answer: string, cookie: string) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/faq`,
      { question: question, answer: answer },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateFAQ = (
  gid: any,
  question: string,
  answer: string,
  cookie: string
) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/admin/faq`,
      { _id: gid, question: question, answer: answer },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);

      })
  });

}
const PayoutCoach = (coachId: string, amount: string, cookie: string) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/admin/payout-coach`,
      { coachId: coachId, amount: amount },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchPayOutCoach = (page: number, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/payout-coach?search=User`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const UpdateCoacheePersonalInfo = (formData: any, cookie: string) => {

  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coachee/personalInfo`,
      formData,
      { headers: { Authorization: `Bearer ${cookie}` } })
      .then(result => {

        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!")
      })
      .catch(err => {

        reject(err);

      })
  });

}
const fetchFAQ = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/faq`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchCertificates = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/certificates`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchFAQUser = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/faq`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const DeleteFAQ = (gid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/faq`, {
      data: {
        _id: gid,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully deleted group with ID:", gid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const AddAdmin = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/admins`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const UpdateAdmin = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/admins`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteAdmin = (uid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/admins`, {
      data: {
        _id: uid,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const ForgetPassEmail = (userinput: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forget-password`, { userinput: userinput })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        //console.log("error in API call", err)
        reject(err);
      });
  });
};
const ResetOtp = (refId: string, types: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/resendOtp`, { refId: refId, type: types })
      .then((result) => {
        resolve(result.data);
        //console.log("OTP SEND");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchAgroup = (search: any, page: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/group?search=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchChapter = (pId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/chapter?pId=${pId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchProgramForCoach = (
  filter: any,
  program_type: any,
  cookie: string
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program?filter=${filter}&program_type=${program_type}`,
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}

const fetchRecommendCoach = (filter: any, cookie: string, page: number) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/recommended-coaches?filter=${filter}`, {
      params: {
        // role and page and search
        page: page,
      },
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        //console.log(result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
// admin/blog
const FetchBlogs = (search: string, page: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/blog?search=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const GetUncoveringSession = (cookie: string) => {

  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/uncovering-session`, {
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch(err => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
}
const CreateNewGroup = (groupTopic: any, coacheeIds: any, coachIds: any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/group`,
      { groupTopic: groupTopic, coacheeIds: coacheeIds, coachIds: coachIds },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const UpdateNewGroup = (groupId:any,groupTopic: any, coacheeIds: any, coachIds: any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/group`,
      {groupId:groupId, groupTopic: groupTopic, coacheeIds: coacheeIds, coachIds: coachIds },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateNewBlog = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/blog`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const creategroupSession = (groupId: any, sName: any, sabout: any, startTime: any, endTime: any, expDate: any, cookie: string) => {
  //console.log("Data is reached")
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/createsession`, { groupId: groupId, sName: sName, sabout: sabout, starttime: startTime, endtime: endTime, sdate: expDate }, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const updategroupSession = (groupId: any, sessionId: any, sName: any, sabout: any, startTime: any, endTime: any, expDate: any, cookie: string) => {
  //console.log("Data is reached")
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/createsession`, { groupId: groupId, sessionId: sessionId, sName: sName, sabout: sabout, starttime: startTime, endtime: endTime, sdate: expDate }, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const EditNewBlog = (formData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/blog`, formData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const BookUncoveringSession = (coachId: any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/coachee/uncovering-session`, { coachId: coachId }, {
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);

      })
      .catch(err => {
        reject(err);
      });
  });
};
const DeleteProgram = (pId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/program`, {
      data: {
        pId: pId,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteChapter = (cId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/chapter`, {
      data: {
        cId: cId,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteBlog = (id: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/blog`, {
      data: {
        _id: id,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully deleted Blog with ID:", id);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// manageAvail
const manageAvail = (availabilityData: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coach/manage-avail`, availabilityData, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log(result.data);

        //console.log("successfully data stored!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getmanageAvail = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/manage-avail`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const ApproveCoach = (uid: string, action: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/admin/actionCoach`,
      { uid: uid, action: action },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const DeleteCards = (cardId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/coachee/card`, {
      data: {
        cardId: cardId
      },
      headers: {
        Authorization: `Bearer ${cookie}`
      }
    })
      .then(result => {
        resolve(result.data);

      })
      .catch(err => {
        reject(err);
      });
  });
};
const DeleteGroup = (gid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/group`, {
      data: {
        gid: gid,
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully deleted group with ID:", gid);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAProgram = (search: string, page: any, cookie: string) => {
  // // role=${filter}&search=${search}&page=${page}
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/program?search=${search}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchAgroupId = (gId: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/group-details?gId=${gId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Detailed Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const fetchAProgramId = (pid: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/program?pid=${pid}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const fetchPayment = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/coachee-payment`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Group Data", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};

const fetchCoachDataForCoachee = (cookie: string, coachId: any) => {
  //console.log("id of program", cookie);
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/coache-details?coachId=${coachId}`, {
      params: {
        // role and page and search
      },
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is one id of program", result.data);
      })
      .catch((err) => {
        reject(err);
        console.error("Error fetching data:", err);
      });
  });
};
const UpdateToken = (cookie: string, notifytoken: any) => {
  return new Promise((resolve, reject) => {
    //console.log(notifytoken);

    Axios.patch(
      `/api/v1/notifytoken`,
      { notifytoken },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Chat Initilized Success!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const InitilizeTicket = (cookie: string, title: any, description: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/ticket`,
      { title: title, description: description },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Chat Initilized Success!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const AddComment = (progId: any, comment: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/coachee/add-comment`,
      { aId: progId, comment: comment },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Chat Initilized Success!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getComment = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/add-comment`,

      {

        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const CreateSessionByCoachee = (
  coachId: string,
  starttime: string,
  endtime: string,
  appType: number,
  date: string,
  pId: string,
  cookie: string
) => {
  //console.log("Online Data");
  return new Promise((resolve, reject) => {
    // pid
    Axios.post(
      `/api/v1/coachee/schedule`,
      {
        coachId: coachId,
        starttime: starttime,
        endtime: endtime,
        appType: appType,
        date: date,
        pId: pId,
      },
      { headers: { Authorization: `Bearer ${cookie}` } }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("successfully data fetch to Offline course!!");
      })
      .catch((err) => {
        reject(err);

      })
  });
}
const fetchCoacheeSession = (page: number, search: string, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/schedule?filter=${1}&page=${page}$search=${search}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchCoacheeProgram = (cookie: string, progType: any, page: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program?filter=${1}&program_type=${progType}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeEnrollProgram = (cookie: string, program_type: any, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/joinedprogram?program_type=${program_type}`,

      {
        params: {
          // role and page and search
          page: page,
        },
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeEnrollProgramDetails = (cookie: string, pid: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program-chapter-details?pid=${pid}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoacheeProgramByID = (cookie: string, pid: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coachee/program-details?pid=${pid}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoachProgramByID = (pid: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coach/program-details?pid=${pid}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const chapterMarkComplete = (pId: any, cId: any, cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coachee/chapter-complete`,
      { pId: pId, cId: cId },

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
// Called To Get AllChatrooms
const GetTickets = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/ticket `, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
// /admin/tickets
const GetAdminTickets = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/tickets?filter=1`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetAllChats = (cookie: string, chatId: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      // type1 for making chat with coach and coachee
      // Type 2 for making chat by admin with coach either coachee
      `/api/v1/chat?roomType=${1}`,
      { chatId },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetChatRooms = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      // type1 for making chat with coach and coachee
      // Type 2 for making chat by admin with coach either coachee
      `/api/v1/chat?roomType=${1}`,

      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetAllMsg = (cookie: string, chatId: any, page: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/getmsg?page=${page}`,
      { chatId: chatId },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const CloseTicket = (ticketId: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/tickets`, { ticketid: ticketId }, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("Successfully fetched data!!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetCoachMsg = (to: any, cookie: string) => {


  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/chat`,
      { to: to },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);

      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ChangePassword = (currentpassword: string, newPassword: string, cPassword: string, cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/change-password`,
      { currentpassword: currentpassword, newPassword: newPassword, cPassword: cPassword },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const SendMsg = (cookie: any, chatId: string, msg: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/sendmsg`,
      { chatId: chatId, text: msg },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchSessions = ({
  filter,
  search,
  page,
  cookie,
}: {
  filter: any;
  search: any;
  page: number;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/sessions?page=${page}&filter=${filter}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


const FetchSessionCoachee = (
 
  filter: any,
  page: number,
  progId:string,
  cookie: string,

) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coachee/sessions?page=${page}&filter=${filter}&progId=${progId}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchAllSessions = ({
  filter,
  search,
  cookie,
}: {
  filter: any;
  search: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/all-sessions?filter=${filter}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchUcoveringSessions = ({
  filter,
  item_per_page,
  cookie,
}: {
  filter: any;
  item_per_page: any;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/coach/uncovering?filter=${filter}&item_per_page=${item_per_page}`,
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const SetUncoveringSession = ({
  sessionId, date, startTime, meetingLink,
  cookie,
}: {
  sessionId: string; date: string; startTime: string; meetingLink: string;
  cookie: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/uncovering`,
      { sessionId: sessionId, date: date, startTime: startTime, meetingLink: meetingLink },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchCoachInfo = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/coach-details`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetNotification = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/notification`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetNotificationSetting = (cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/notification-setting`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const SendNotificationSetting = (cookie: any, value: any, category: any) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/notification-setting`,
      { value: value, category: category },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const FetchProgramsCoach = ({
  search,
  cookie,
  page,
}: {
  search: string;
  cookie: string;
  page: number;
}) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/program?page=${page}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const SuggestPrograms = ({
  pIds,
  usId,
  cookie,
}: {
  pIds: any;
  cookie: string;
  usId: string;
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/coach/suggest-programs`, {
      pIds,
      usId
    }, {
      headers: {
        Authorization: `Bearer ${cookie}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sendQuadrentCoachee = (cookie: any, quadrant:number) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coachee/quadrant`,
      { quadrant: quadrant },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const sendQuadrentCoach = (cookie: any, quadrant:number) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/coach/quadrant`,
      { quadrant: quadrant },
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
        //console.log("Msg Sent ");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchPrograms = ({ token }: { token: string }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/all-program`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const DeleteUser = ({ uid, token, action }: { uid: string, token: string, action: boolean }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/deleteUser`, { uid, action }, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getUserProgram = (uid: any, filter: any, role: any, cookie: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/user-sessions/${uid}?role=${role}&filter=${filter}`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const BlockUser = ({ uid, token, action }: { uid: string, token: string, action: boolean }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/admin/blockUser`, { uid, action }, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


const fetchDashFigure = ({ token, }: { token: string, }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/dashboard-figures`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchCoachGraph = (cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/graph-data`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchAdminGraph = (cookie: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/graph-data`, {
      headers: {
        Authorization: `Bearer ${cookie}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchDashFigureCoach = ({ token, }: { token: string, }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/coach/dashboard-figures`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
        //console.log("this is fetch Figure", result)
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export {
  // common
  triggerBackgroundAuthentication,
  LoginApi,
  SignUpApi,
  VerifyApi,
  fetchDashFigureCoach,
  googleLoginapi,
  ContactInfo,
  ForgetPassEmail,
  ResetPass,
  ResetOtp,
  //Admin
  fetchCoach, AddAdmin,
  fetchAdmin,
  fetchCoacheeDetails,
  fetchUsersId,
  OfflineProgramData,
  fetchAgroup,
  fetchAdminGraph,
  CreateNewGroup,
  CreateNewBlog,
  EditNewBlog,
  UpdateNewGroup,
  DeleteBlog,
  creategroupSession,
  updategroupSession,
  fetchAProgram,
  CreateProgram,
  UpdateProgram,
  DeleteChapter,
  DeleteProgram,
  fetchPrgId,
  CreateSession,
  CreateOffSession,
  UpdateSession,
  DeletegroupSession,
  fetchAgroupId,
  fetchFAQ,
  fetchFAQUser,
  CreateFAQ,
  DeleteFAQ,
  UpdateAdmin,
  DeleteAdmin,
  UpdateFAQ,
  AddCard,
  fetchCard,
  getUserProgram,
  fetchCoachGroup,
  fetchCoachGroupID,
  fetchCoachByCoachee,
  fetchCoachByCoacheeCopy,
  fetchCoachDataForCoachee,
  ApproveCoach,
  FetchBlogs,
  fetchPayment,
  FetchPrograms,
  GetAdminTickets,
  FetchPaymentList,
  FetchSessionAdmin,
  DeleteUser,
  BlockUser,
  fetchDashFigure,
  //Coach
  certificates,
  coachexp,certificatesPatch,
  administration,
  ContactInfoPatch,
  fetchCoachData,
  bankDetails,
  manageAvail,
  administrationPatch,
  getmanageAvail,
  coachexpPatch,
  fetchCoachGraph,
  FetchSessions,
  SuggestPrograms,
  FetchCoachInfo,
  FetchSessionCoachee,
  FetchbankDetails,
  bankDetailsUpdate,
  FetchAllSessions,
  fetchCoachProgramByID,
  FetchUcoveringSessions,
  SetUncoveringSession,
  FetchProgramsCoach,
  fetchAProgramId,
  GetNotification,
  fetchCoachPayment,
  GetNotificationSetting,
  // coachee
  CoacheePersonalInfo,
  CreateSessionByCoachee,
  fetchCoacheeSession,
  AddComment,
  fetchCertificates,
  getComment,
  SendNotificationSetting,
  fetchCoacheeProgram,
  sendQuadrentCoach,
  sendQuadrentCoachee,
  fetchCoacheeProgramByID,
  fetchCoacheeEnrollProgram,
  fetchCoacheeEnrollProgramDetails,
  fetchCoacheeGroup,
  fetchCoachprogramByID,
  createOnProgram,
  createOffProgram,
  fetchCoacheeGroupId,
  fetchProgramForCoach,
  chapterMarkComplete,
  upgradePlans,
  checkRecommend,
  DeleteCards,
  fetchRecommendCoach,
  BookUncoveringSession,
  GetUncoveringSession,
  UpdateCoacheePersonalInfo,
  fetchPayOutCoach,
  CreateLiveSession,
  // Ticket/Chat API
  InitilizeTicket,
  GetTickets,
  GetAllMsg,
  SendMsg,
  GetCoachMsg,
  GetAllChats,
  ChangePassword,
  PayoutCoach,
  GetChatRooms,
  fetchChapter,
  fetchContactUs,
  // FCM Token API
  UpdateToken,
  DeleteGroup,
  CloseTicket,
  // Landing Page
  blogs,
  fetchblogs,
  ContactFormData,
  facebookLoginapi,
  postQuardentDashboard
};
