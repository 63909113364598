import style from "./faq.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchFAQUser } from "../../../../Api";

const Faq = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [faqData, setFaq] = useState<any>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    fetchFAQUser(cookie)
      .then((res: any) => {
        setFaq(res.data.FAQ);
      })
      .catch((error) => {
        console.error("Failed to fetch FAQ data:", error);
      });
  }, [cookie]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={style.container}>
      <div className={style.accordioncontainer}>
        {faqData.map((faq: { question: string; answer: string }, index: number) => (
          <div key={index} className={style.datacontent}>
            <Accordion
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={expanded === `panel${index + 1}` ? <RemoveIcon /> : <AddIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                className={style.accordion}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>
                {faq.answer}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
