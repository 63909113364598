import { useEffect, useState } from "react";
import { fetchCoachGroupID } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import styles from "../programs/programs.module.css";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
type propType = {
    setacTab: (val: (v: number) => number) => void;
    groupId:any;
}
const GroupDetails=({setacTab,groupId}:propType)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [groupData,setGroupdata]=useState<any>()
    const[coachee,setCoachee]=useState<any>([])
    const[groupMeetLink,setGroupMeetLink]=useState('')
 useEffect(()=>{
    fetchCoachGroupID(groupId,cookie)
    .then((res:any)=>{
        //console.log(res)
        setGroupdata(res.data.group)
        setCoachee(res.data.group.coacheeIds)
        setGroupMeetLink(res.data.group.groupMeetLink)
    })
 },[cookie])

 return (<> 
    <div className={styles.programsDisplay}>
      
        


        <div className={styles.programHead}>
           
                
                <div>
                    <h6>{groupData?.groupTopic}</h6>
                    <p><AccessAlarmsIcon/> {dayjs(groupData?.createdAt.slice(0,10)).format('DD MMM YYYY')}</p>
                    <div>
                    {coachee.length>0?coachee.map((cdata:any)=>(cdata.profileImg?(
                        <img src={`${cdn_Link}/${cdata.profileImg}`}  className={styles.profileImg}/>
                    ):(
                        <img src="/images/svg/Coach/setting/personal.svg" className={styles.profileImg} />
                    ))):('')}
                   
                    </div>
                </div>
               
            
        </div>
        <div className={styles.ProgDesc}>
            <br/>
            <br/>

        </div>
<div className={styles.totalSess}>
    <div className={styles.totalSessHead}>
    <h6>Total Session</h6>
    <button  className={styles.totalSessHeadBtn}  onClick={() => window.open(groupMeetLink, '_blank')}>Join Now</button>
    
              
     </div>
    
    <table>
        <tr>
        <th>SESSION TOPIC</th>
        <th>WHAT ARE WE GOING TO LEARN</th>
        <th>DATE</th>
        <th>SESSION TIME</th>
        </tr>
    {groupData?.Sessions?.length>0?(groupData?.Sessions?.map((session:any)=>(
        <tr>
            <td><span className={styles.groupSession}>{session.sName.slice(0,2)} </span>{session.sName}</td>
            <td>{session.sabout}</td>
            <td>{dayjs(session.sdate.slice(0,10)).format('DD MMM YYYY')}</td>
            <td>{dayjs(session.starttime).format('hh:mm A')} - {dayjs(session.endtime).format('hh:mm A')}</td>
            
        </tr>
    ))):'No Session yet Created!'}
    </table>
</div>
  

    </div>
    </>)
}
export default GroupDetails;