export const findMaxOccurrence = (chars: string[]) => {
    const frequencyMap: any = {};

    // Count the frequency of each character
    chars.forEach((char) => {
        frequencyMap[char] = (frequencyMap[char] || 0) + 1;
    });

    // Find the character with the maximum frequency
    let maxChar = null;
    let maxCount = 0;

    for (const char in frequencyMap) {
        if (frequencyMap[char] > maxCount) {
            maxChar = char;
            maxCount = frequencyMap[char];
        }
    }

    return { maxChar, maxCount };
}