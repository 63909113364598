import styles from "./forgot.module.css";
import { ForgetPassEmail } from "../../Api";
import { useState } from "react";
import { UserType } from "../../@types/user";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import { notifyError } from "../../Notify";

type propType = {
  setMailsubmitted: (val: boolean) => void;
};
const Main = ({ setMailsubmitted }: propType) => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const handleNext = (e:any) => {
    e.preventDefault();
    ForgetPassEmail(userInput)
      .then((result: any) => {
        //console.log("resdata",result.data)
        let user: UserType = { ...result.data.user, id: result.data.id };
        
        dispatch(login(user));
        sessionStorage.setItem("user", JSON.stringify(user));
       
        if (result) {
          setMailsubmitted(true);
        }
      })

      .catch((error:any) => {
        //console.log("this is error:",error);
        notifyError(error.response?.data?.message);
        setMailsubmitted(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleNext}>
        <div>
          <label className={styles.mailText}> Email Address</label>
          <input
            className={styles.InputBox}
            type="email"
            name="userInput"
            value={userInput}
            onChange={(e: any) => setUserInput(e.target.value)}
          />
        </div>
        <div>
          <button className={styles.btn} type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Main;
