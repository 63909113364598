import { useNavigate } from "react-router-dom";
import styles from "./resetpassword.module.css";

const PasswordResetsuccess = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div className={styles.cnt}>
      <div className={styles.imagedivv}>
        <img src={"/images/svg/Coach/rightclick.svg"} alt="" />
      </div>

      <div className={styles.profilediv}>
        <h1>Password reset successfully</h1>
      </div>

      <div className={styles.sharedivv}>
        <p>
          Lorem Ipsum is simply dummy text of the printing and type setting
          industry that will used on.
        </p>
      </div>

      <div className={styles.butttondiv}>
        <button className={styles.button} onClick={handleLogin}>
          Login to your Account
        </button>
      </div>
    </div>
  );
};

export default PasswordResetsuccess;
