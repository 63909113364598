import { useEffect, useState } from "react";
import { fetchCoachDataForCoachee, fetchCoacheeGroup } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import style from "./CSS/group.module.css"
import SearchIcon from '@mui/icons-material/Search';
import ShowGroups from "./Program/ShowGroups";
import dayjs from "dayjs";
import Pagination from "../../Pagination/Pagination";
const MyGroups=()=>{
  
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[group,setGroup]=useState<any[]>([])
    
    const[grpId,setgrpId]=useState('')
   
    const [data, setData] = useState({
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      history: [],
    });
    const[activeTab,setActiveTab]=useState(0)
    const[page,setPage]=useState(1);
    useEffect(()=>{
        fetchCoacheeGroup(cookie)
        .then((ress:any)=>{
            let res = ress.data;
            let x: any = { ...data };
            x = {
              ...x,
              next: res.next,
              prev: res.prev,
              total_page: res.total_page || 1,
              history: [...res.history],
            };
            setData(x);
            setGroup(x.history)
            
           
        })
    },[cookie,page])
    const decreasePage = () => {
      setData({ ...data, page: data.page - 1 });
    };
  
    const IncreasePage = () => {
      setData({ ...data, page: data.page + 1 });
    };

const handleId=(e:any,gid:any)=>{
    e.preventDefault();
    setgrpId(gid)
setActiveTab(1)
}

    return(<>
    {activeTab===0 && ( <div className={style.groupContent}>
        <div className={style.groupInputContent} style={{maxWidth:'300px'}}>
   <SearchIcon/> <input type="text" placeholder="Which Group are you looking for" className={style.groupInput}/>
    </div>
    <div className={style.showGroups}>
        
        <table>
            <tr>
            <th>GROUP TOPIC </th>
            <th>ASSIGNED COACH</th>
            <th>TOTAL SESSION</th>
            <th>SESSION COMPLETED</th>
            <th>DATE</th>
            <th></th>
            </tr>
            {/*  */}
           
                {group.length>0?(group.map((gp:any)=>(
                
                    <tr>
                        <td className={style.groupTd} ><p className={style.groupTdpara}>{gp?.groupTopic?.slice(0,2)}</p><p>{gp?.groupTopic}</p></td>
                    <td>{gp.coachIds.map((coach:any)=>(<><img src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`} className={style.coachImg}/>{coach.name}</>))}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>{dayjs(gp.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
                    <td><img src="/images/svg/Admin/viewIcon.svg" onClick={(e:any)=>handleId(e,gp._id)} /></td>
                  
                    </tr>
                  
                ))):(<td rowSpan={2}>No Group Sessions Scheduled !</td>)}
             
        </table>
       
    </div>
    <div className={style.pagination}>
    <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
    </div>)}
    {activeTab === 1 && (<ShowGroups grpId={grpId}/>)}
   
    </>)
}
export default MyGroups;