import Assessment from "../components/Game/DashboardAssessment";
import style from "./wrapper/landing.module.css";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const BehaveGame = ({ setActiveTab }: propType) => {
  return (
    <>
      <div className={style.HomeContainer}>
        <div className={style.headerContainer}>
          <header className={style.headerTxt}>
            <span className={style.DSHead}>Dream Smart Solution</span> lorem
            ipsom is simple dummy text that can used on that can on people goes
            under
          </header>
        </div>
        <div className={style.headerpara}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to on people goes under takern goes on that can help
            people had make a type specimen book.
          </p>
        </div>
        <div>
          <Assessment />
        </div>
      </div>
    </>
  );
};

export default BehaveGame;
