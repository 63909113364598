import AddIcon from "@mui/icons-material/Add";
import styles from "../programs/programs.module.css";
import style from "../wrapper/wrapper.module.css";
import { useEffect, useState } from "react";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { Cancel } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { creategroupSession, DeletegroupSession, fetchAgroupId, updategroupSession } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import moment from 'moment';
import { notifyError, notifySuccess } from "../../../../Notify";
type propType = {
  pid: any;
  setActivescreen: (val: (v: number) => number) => void;
};
const dayjs = require('dayjs');
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const GroupInfo = ({ pid,setActivescreen }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open, setOpen] = useState(false);
  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [sabout, setsabout] = useState("");
  const[handleState,usehandleState]=useState(1)
  const [sName, setsName] = useState("");
  const[updata,setupdata]=useState(false)
  const [groupTopic, setgroupTopic] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const[sessionId,setSid]=useState('')
  const [expDate, setexpDate] = useState("");
  const [createdAt, setcreatedAt] = useState("");
  const [coachName, setCoachName] = useState<any>([]);
  const [session, setSession] = useState<any[]>([]);
  const [profileImg, setprofileImg] = useState<any>([]);
  const[upStartTime,setupStartTime]=useState('')
  const[upEndTime,setupEndTime]=useState('')
  const[modelData,setModelData]=useState({
    sName:"",
    sabout:"",
    sdate:"",
    duration:"",
    startTime:"",
    endTime:""
  })
  // fetchAgroupId
  useEffect(() => {
    fetchAgroupId(pid, cookie)
      .then((result: any) => {
        setcreatedAt(result.data.group.createdAt);
        setgroupTopic(result.data.group.groupTopic);
        setprofileImg(result.data.group.coacheeIds);
        setCoachName(result.data.group.coachIds);
        setSession(result.data.session);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie,updata]);
  const createSession = (e:any) => {
    e.preventDefault();
    setupdata(true)
    const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
const diffInMillis = end.diff(start);
const minutes = Math.floor((diffInMillis % (1000 * 60 * 60)) / (1000 * 60)); 
    const today = dayjs().startOf('day');
    const sessionStartDate = dayjs(expDate)
    
    // if (minutes < 30) {
    //   notifyError("Session duration must be at least 30 minutes.");
    //   return;
    // }
    // if (minutes > 300) {
    //   notifyError("Session duration cannot exceed 5 hours.");
    //   return;
    // }
   
    // if (sessionStartDate.isBefore(today, 'day')) {
    //   notifyError("Session start date cannot be before today.");
    //   return;
    // }
    
      creategroupSession(pid,sName,sabout,startTime,endTime,expDate, cookie)
      .then((res: any) => {
        notifySuccess(res.message || "Session is created!");
        setupdata(false)
        handleClose1()  
        setsName('')    
        setsabout('')
        setStartTime('')
        setEndTime('')
        setexpDate('')
      })
      .catch((err: any) => {
        notifyError(err.response.data.message);
        setupdata(false)
        handleClose1()
      });
    
    
  };
  
  const handlestate=(active:any,pid:any,sName:any,sabout:any,duration:any,sdate:any,startTime:any,endTime:any)=>{
    handleOpen2()
    setSid(pid)
    setModelData({
      sName,
      sabout,
      sdate,
      duration,
      startTime, 
      endTime,
  });
    usehandleState(active)
  }
  const deleteSession=(e:any)=>{
    setupdata(true)
    e.preventDefault();
   
    DeletegroupSession(sessionId,cookie)
    .then((res:any)=>{
      notifySuccess(res.message)
      setupdata(false)
      handleClose2()
      
    })
    .catch((err:any )=>{
      notifyError(err.response.data.message)
      setupdata(false)
      handleClose2()
    })
  }
  const updateSession = (e: any) => {
    e.preventDefault();
    setupdata(true);
    updategroupSession(pid, sessionId, modelData.sName, modelData.sabout, upStartTime||dayjs(modelData.startTime).format('HH:mm'), upEndTime||dayjs(modelData.endTime).format("HH:mm"), modelData.sdate.slice(0, 10) || expDate.slice(0, 10), cookie)
      .then((res: any) => {
        notifySuccess(res.data.message || "session updated successfully");
        setupdata(false);
        handleClose2()
        setupStartTime('')
        setupEndTime('')
        
      })
      .catch((err: any) => {
        notifyError(err.response.data.message);
        setupdata(false);
        handleClose2()
        setupStartTime('')
        setupEndTime('')
      });
  };
  return (
    <>
      <div className={styles.container} style={{paddingTop:'20px'}}>
        <FastRewindIcon style={{color:'#0395B8',cursor:'pointer'}} onClick={()=>setActivescreen(()=>2)}/>
        <div className={styles.GroupInfoContent} >
          <div className={styles.GroupInfoLeft}>
            <h6 style={{fontSize:'20px'}}>{groupTopic}</h6>
            <p>{dayjs(createdAt.substring(0, 10)).format("DD MMM YYYY")}</p>
            {profileImg ? (
              profileImg.map((profile: any) =>
                profile.profileImg ? (
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${profile.profileImg}`}
                    className={styles.groupIc2}
                  />
                ) : (
                  <img
                    src="/images/svg/Coach/setting/personal.svg"
                    className={styles.groupIc2}
                  />
                )
              )
            ) : (
              <></>
            )}

            <br />
          </div>
           
          <div>
            <div className={styles.GroupInfoRight}>
              <div className={styles.groupData}>
                <p>Assigned Coach</p>

                <span>
                  <p>
                    {coachName.length > 0
                      ? coachName.map((cname: any) =>
                          cname.name.slice(0, 2).toUpperCase()
                        )
                      : 1}
                  </p>
                  {coachName.length > 0
                    ? coachName.map((cname: any) => cname.name)
                    : 1}
                </span>
              </div>
              <div className={styles.groupData}>
                <p>Total Sessions</p>
                <span>{session.length}</span>
              </div>
            </div>
            <br />
            <div className={styles.groupButton} >
            
              <button className={styles.EditRightBtn1} style={{marginRight:'30px'}} onClick={handleOpen1}>
                <AddBoxSharpIcon /> Add New Session
              </button>
            </div>
            <Modal
              open={open}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: "scroll", overflowY: "scroll" }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose1} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  <h3> Create New Session</h3>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <div className={style.ProgramProp}>
                    <div className={style.onlinePro}>
                      <form
                        className={style.programForm}
                        onSubmit={createSession}
                      >
                        <TextField
                          type="text"
                          id="outlined-basic"
                          label="Session Topic"
                          variant="outlined"
                          name="sName"
                          value={sName}
                          onChange={(e: any) => setsName(e.target.value)}
                          className={style.Inputfield}
                          InputProps={{
                            className: `${style.muiInput}`,
                          }}
                          sx={{
                            my: 1,
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                          required
                        />

                        <TextArea
                          id="outlined-basic"
                          placeholder="What are you going to learn"
                          name="sabout"
                          value={sabout}
                          onChange={(e: any) => setsabout(e.target.value)}
                          style={{ resize: "none" }}
                          rows={5}
                          required
                        />
                     
                        <div className={styles.textFieldForSHow}>
                          <TextField
                            type="time"
                            id="outlined-basic"
                            label="Expected Start Time"
                            variant="outlined"
                            name="duration"
                            value={startTime}
                            onChange={(e: any) => setStartTime(e.target.value)}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,
                              borderRadius: "20px",
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                            required
                          />
                          <TextField
                            type="time"
                            id="outlined-basic"
                            label="Expected End Time"
                            variant="outlined"
                            name="duration"
                            value={endTime}
                            onChange={(e: any) => setEndTime(e.target.value)}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,
                              borderRadius: "20px",
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                            required
                          />
                        </div>
                        <TextField
                          type="date"
                          id="outlined-basic"
                          label="Expected Session Date"
                          variant="outlined"
                          name="duration"
                          className={style.Inputfield}
                          value={expDate}
                          onChange={(e: any) => setexpDate(e.target.value)}
                          InputProps={{
                            className: `${styles.muiInput}`,
                          }}
                          sx={{
                            my: 1,
                            borderRadius: "20px",
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                          required
                        />

                        <button className={style.SaveButton} type="submit">
                          {updata==true ?<>Loading ..</>:<>Save</>}
                        </button>
                        <button  onClick={handleClose1} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                        Cancel</button>
                      </form>
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        <br />
        <div style={{marginTop:'-30px'}}>
        <h5>Group Sessions</h5>
        </div>
        <table>
          <tr>
            <th>SESSION TOPIC</th>
            <th>WHAT ARE WE GOING TO LEARN</th>
            <th>SESSION TIME</th>
            <th>SESSION DURATION</th>
            <th>DATE</th>
           
            <th></th>
          </tr>
          {session.length ? (
            session.map((sc: any) => (
              <>
                <tr>
                  <td style={{textAlign:'left'}}><span className={styles.groupIcon}>{sc.sName.slice(0,2).toUpperCase()}</span>{sc.sName}</td>
                  <td className={styles.groupDataInfo} style={{textAlign:'left'}}>{sc.sabout.substring(0,25)}</td>
                 <td>{dayjs(sc.starttime).format('hh:mm A')} - {dayjs(sc.endtime).format('hh:mm A')} </td>
                  <td>{`${dayjs.duration(sc.duration, 'minutes').hours()} hrs ${dayjs.duration(sc.duration, 'minutes').minutes()} min`}</td>
                  {/* >10 ? sc.duration.slice(0, 10) : sc.duration */}
                  <td>{dayjs(sc.sdate.slice(0, 10)).format("DD MMM YYYY")}</td>
                  <td className={styles.editIcon}>
                     <img src="/images/svg/Admin/viewIcon.svg" onClick={()=>handlestate(1,sc._id,sc.sName,sc.sabout,sc.duration,sc.sdate,sc.starttime,sc.endtime)}/>
                      <img src="/images/svg/Admin/EditIcon.svg" onClick={()=>handlestate(2,sc._id,sc.sName,sc.sabout,sc.duration,sc.sdate,sc.starttime,sc.endtime)}/>
                       <img src="/images/svg/Admin/deletIcon.svg" onClick={()=>handlestate(3,sc._id,sc.sName,sc.sabout,sc.duration,sc.sdate,sc.starttime,sc.endtime)}/>
                     </td>
                </tr>
              </>
            ))
          ) : (
            <tr>
              <td colSpan={3}>Sessions not Added Yet!</td>
            </tr>
          )}
        </table>
        <Modal
              open={open2}
              onClose={handleClose2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: "scroll", overflowY: "scroll" }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose2} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  <h5>{handleState==1 && <>View Session</>} {handleState==2 && <>Edit Session</>} {handleState==3 && <>Delete Session</>}</h5>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  {handleState==1 &&(
                    <>
                    <div className={styles.showingSession}>
                    <h6>Session Topic</h6><p>{modelData.sName}</p></div>
                    <div className={styles.showingSession}>
                    <h6>Session Learn</h6><p className={styles.showingSessionAbout}>{modelData.sabout}</p></div>
                    <div className={styles.showingSession}>
                    <h6>Session  Timing</h6><p className={styles.showingSessionAbout}>{dayjs(modelData.startTime).format('hh:mm A')} - {dayjs(modelData.endTime).format('hh:mm A')}</p></div>
                    <div className={styles.showingSession}>
                    <h6>Session Date</h6><p className={styles.showingSessionAbout}>{dayjs(modelData.sdate).format('DD MMM YYYY')}</p></div>
                    </>
                  )}
                  {handleState==2 &&(
                    <>
                    <form
                        className={style.programForm}
                        onSubmit={updateSession}
                      >
                        <TextField
                          type="text"
                          id="outlined-basic"
                          label="Session Topic"
                          variant="outlined"
                          name="sName"
                          value={modelData.sName}
                          onChange={(e: any) =>
                            setModelData((prevState) => ({
                              ...prevState,
                              sName: e.target.value, 
                            }))}
                        
                          className={style.Inputfield}
                          InputProps={{
                            className: `${style.muiInput}`,
                          }}
                          sx={{
                            my: 1,
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                        />

                        <TextArea
                          id="outlined-basic"
                          placeholder="What are you going to learn"
                          name="sabout"
                          value={modelData.sabout}
                          onChange={(e: any) =>
                            setModelData((prevState) => ({
                              ...prevState,
                              sabout: e.target.value, 
                            }))}
                          style={{ resize: "none" }}
                          rows={5}
                        />
                     
                        <div className={styles.textFieldForSHow}>
                          <TextField
                            type="time"
                            id="outlined-basic"
                            label="Expected Start Time"
                            variant="outlined"
                            name="UpstartTime"
                            value={upStartTime}
                            onChange={(e: any) =>setupStartTime(e.target.value)}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,
                              borderRadius: "20px",
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <TextField
                            type="time"
                            id="outlined-basic"
                            label="Expected End Time"
                            variant="outlined"
                            name="upEndTime"
                            value={upEndTime}
                            onChange={(e: any) => setupEndTime(e.target.value)
                             }  
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,
                              borderRadius: "20px",
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                        </div>
                        <TextField
                          type="date"
                          id="outlined-basic"
                          label="Expected Session Date"
                          variant="outlined"
                          name="timeDte"
                          className={style.Inputfield}
                          value={modelData.sdate}
                          onChange={(e: any) =>
                            setModelData((prevState) => ({
                              ...prevState,
                              sdate: e.target.value, 
                            }))}
                          InputProps={{
                            className: `${styles.muiInput}`,
                          }}
                          sx={{
                            my: 1,
                            borderRadius: "20px",
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                        />

                        <button className={style.SaveButton} type="submit">
                          {updata==true ?<>Loading ..</>:<>Save</>}
                        </button>
                        <button  onClick={handleClose2} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                        Cancel</button>
                      </form>
                    </>
                  )}
                  {handleState==3 &&(
                    <>
                     Are you sure Delete Group Session? <br/>
 {/*  */}
 <button className={styles.delete} onClick={(e:any)=>deleteSession(e)}><DeleteIcon /> Delete </button>
 <button className={styles.DontDelete} onClick={handleClose2}><Cancel /> Cancel </button>
                    </>
                  )}
                </Typography>

                </Box>
                </Modal>

      </div>
    </>
  );
};
export default GroupInfo;
