import TextArea from "antd/es/input/TextArea";
import styles from "./setup.module.css";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select, TextField } from "@mui/material";
import { setProfileSetup } from "../../redux/userSlice";
import { bankDetails, fetchCoachData } from "../../Api";
import { notifyError, notifySuccess } from "../../Notify";
import { useStripe } from "@stripe/react-stripe-js";
import Stripe from "stripe";
import { loadStripe } from "@stripe/stripe-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import configuration from "../../config/configuration";

const stripe: any = new Stripe(configuration.stripePublicKey);

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  activeTab:any
};

const CpTab5 = ({ setActiveTab,activeTab }: propType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [token, setToken] = useState('');
  const cookie: any = user?.token;
  const[loader,setLoader]=useState(false)
  // Initialize Formik
  const[coachData,setCoachData]=useState<any>([])
  useEffect(()=>{
    handleCoachData()
  },[cookie,activeTab])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      hourlyRate: "",
      ssNumber:coachData?.ssNumber|| "",
      bankName:coachData?.bankName|| "",
      accNo:coachData?.accNo || "",
      routingNo:coachData?.routingNo|| "",
      accType: "individual",
    },
    validationSchema: Yup.object({
     
      ssNumber: Yup.string().required("Required"),
      bankName: Yup.string().required("Required"),
      accNo: Yup.string().required("Required"),
      routingNo: Yup.string().required("Required"),
      accType: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const accountData = {
        bankName: values.bankName,
        accType: values.accType,
        accNo: values.accNo,
        routingNo: values.routingNo,
      };

      const bankaccount = JSON.stringify(accountData);
      
      // Call to Stripe API to create token
      await fetchToken(values.accNo, values.routingNo); // Ensure token is fetched before using it
      setLoader(true)
      bankDetails(bankaccount, values.ssNumber, token, cookie)
        .then((result: any) => {
          notifySuccess(result.data.message);
          setActiveTab((prev) => prev + 1);
        })
        .catch((error) => {
          notifyError(error.response?.data?.message || "An error occurred");
          setLoader(false)
        });
    },
  });

  const fetchToken = async (accountNumber: string, routingNumber: string) => {
    if (accountNumber && routingNumber) {
      try {
        const tokenn = await stripe.tokens.create({
          bank_account: {
            country: 'US',
            currency: 'usd',
            account_holder_name: user?.name,
            account_holder_type: 'individual',
            routing_number: routingNumber,
            account_number: accountNumber,
          },
        });

        setToken(tokenn.id);
      } catch (error:any) {
        notifyError(error.response?.data?.message)
        console.error("Error creating token", error);
      }
    }
  };
  const handleCoachData=()=>{
    fetchCoachData(cookie).then((res:any)=>{
      setCoachData(res.data)
      //console.log(res);
      
    })
    .catch((error:any)=>{
      //console.log(error)
    })
  }
  useEffect(() => {
    // Fetch token whenever accNo or routingNo changes
    if (formik.values.accNo && formik.values.routingNo) {
      fetchToken(formik.values.accNo, formik.values.routingNo);
    }
  }, [formik.values.accNo, formik.values.routingNo, user?.name]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputContent}>
        <h3>Coaching Related Information</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
          <TextField
              type="text"
              id="ssNumber"
              name="ssNumber"
              label="SSN Number of Coach"
              variant="outlined"
              value={formik.values.ssNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ssNumber && Boolean(formik.errors.ssNumber)}
              // helperText={formik.touched.ssNumber && formik.errors.ssNumber}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            {/* <TextField
              type="text"
              id="hourlyRate"
              name="hourlyRate"
              label="Enter Your 1 Hr rate"
              variant="outlined"
              value={formik.values.hourlyRate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.hourlyRate && Boolean(formik.errors.hourlyRate)}
              // helperText={formik.touched.hourlyRate && formik.errors.hourlyRate}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
            /> */}
          </div>
          <div className={styles.RightInputField}>
            
          </div>
        </div>

        <br />
        <br />
        <h3>Bank Details</h3>

        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="text"
              id="bankName"
              name="bankName"
              label="Bank Name"
              variant="outlined"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bankName && Boolean(formik.errors.bankName)}
              // helperText={formik.touched.bankName && formik.errors.bankName}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            <TextField
              type="text"
              id="accNo"
              name="accNo"
              label="Account Number"
              variant="outlined"
              value={formik.values.accNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.accNo && Boolean(formik.errors.accNo)}
              // helperText={formik.touched.accNo && formik.errors.accNo}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                }
                ,
              }}
              required
            />
          </div>

          <div className={styles.RightInputField}>
            <Select
              labelId="account-type-select"
              id="accType"
              name="accType"
              value={formik.values.accType}
              onChange={formik.handleChange}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "10px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
              className={styles.Inputfield}
              label="Select Account Type"
            >
              <MenuItem value="individual" className={styles.MenuItems}>
                Individual Account
              </MenuItem>
              {/* <MenuItem value="company" className={styles.MenuItems}>
                Company Account
              </MenuItem> */}
            </Select>
            <TextField
              type="text"
              id="routingNo"
              name="routingNo"
              label="Routing Number"
              variant="outlined"
              value={formik.values.routingNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.routingNo && Boolean(formik.errors.routingNo)}
              // helperText={formik.touched.routingNo && formik.errors.routingNo}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
          </div>
        </div>

        <br /><br />
        <div className={styles.textcenter1}>
          <button className={styles.button4} type="submit">
           {loader==true? <>Saving ...</>:<>Submit</>} 
          </button>
        </div>
        <div className={styles.textcenter1}>
          <p onClick={() => setActiveTab((prev) => prev - 1)} style={{cursor:'pointer',color:'black'}}>Back</p>
        </div>
      </form>
    </div>
  );
};

export default CpTab5;
