import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetOtp, VerifyApi } from "../../Api";
import styles from "./otp.module.css";
import { RootState } from "../../redux/store";
import { UserType } from "../../@types/user";
import { login } from "../../redux/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../Notify";
import OtpInput from "react-otp-input"; // Import the OtpInput component

type PropType = {
  setVefified: (val: boolean) => void;
  type: string;
};

const Verify = ({ setVefified, type }: PropType) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.user.user);
  const [otp, setOtp] = useState<string>(""); // Use a single string for OtpInput
  const [tabActivated, setTabActivated] = useState(false);
  const [reqtype, setReqtype] = useState("");
  const [countdown, setCountdown] = useState<number>(60);
  const [isResendBlocked, setIsResendBlocked] = useState<boolean>(false);
  const [isErrorDisplay, setisErrorDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname === "/verify") {
      setReqtype("regi");
    }
    if (location.pathname === "/forget-password") {
      setReqtype("forget");
    }
  }, [location.pathname]);

  useEffect(() => {
    setTabActivated(otp.length === 4); // Update to check length for 4 digits
  }, [otp]);

  useEffect(() => {
    if (isResendBlocked) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendBlocked(false);
            notifyError(`OTP will Resend In ${timer} Seconds`)
            return 60; // Reset countdown to 60 seconds
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isResendBlocked]);

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    const id: any = user?._id || user?.id;
    // const OTPInterval = setInterval(() => {
    //   setisErrorDisplay(!isErrorDisplay)
    // }, 8000);
    try {
      if (!isErrorDisplay) {
        const result: any = await VerifyApi(otp, id, reqtype);
        setisErrorDisplay(!isErrorDisplay)
        const user: UserType = {
          ...result.data.usr,
          token: result.data.token,
        };
        dispatch(login(user));

        if (reqtype === "forget") {
          setVefified(true);

        } else {
          setVefified(false);
          navigate("/setup");
        }

      }

    } catch (error: any) {
      setisErrorDisplay(!isErrorDisplay)
      notifyError(error?.response.data.message || 'Invalid OTP');
    }
  };

  const resendOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isResendBlocked) return; // Prevent resend if blocked

    setIsResendBlocked(true); // Block resend button
    setCountdown(60); // Reset countdown
    notifyError(`OTP will Resend In ${countdown} Seconds`)
    const refId = user?._id || user?.id;
    try {
      await ResetOtp(refId, "1");
      notifySuccess('Successfully sent OTP');
    } catch (err: any) {
      //console.log(err);
      notifyError(err.response?.data?.message)
      const timeMatch = err.response?.data?.message.match(/(\d+)/);
      const timeInMinutes = timeMatch ? parseInt(timeMatch[0], 10) : 0;
      setCountdown(timeInMinutes * 60);
    }
  };

  return (
    <>
      <form onSubmit={handleVerify}>
        <div className={styles.container}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => (
              <input
                {...props}
                type="text"
                maxLength={1}
                style={{
                  width: '3rem',
                  height: '3rem',
                  fontSize: '2rem',
                  textAlign: 'center',
                  background: '#F1F2DE',
                  borderRadius: '10px',
                }}
              />
            )}
          />
        </div>

        <center>
          <span className={styles.code}>
            Didn’t receive any code?{" "}
            <button onClick={resendOTP} disabled={isResendBlocked}>
              Request new code {isResendBlocked && `(${countdown})`}
            </button>
          </span>
        </center>
        <center>
          <button className={styles.createid} type="submit">
            Verify & {type === "forget" ? "Reset Password" : "Create an account"}
          </button>
        </center>
      </form>
    </>
  );
};

export default Verify;
