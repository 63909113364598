import style from "./profiledata.module.css";
import styles from "../../CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { fetchCoacheeSession } from "../../../../../Api";
import { Button } from "antd";
const Webinars=()=>{
  const user = useSelector((state: RootState) => state.user.user);
  //console.log("profile", user?.profileSetup);

  const cookie: any = user?.token;
  const [CoachingData,setCoachingData]=useState<any[]>([])
  const fetchSessions=()=>{
    fetchCoacheeSession(1,'',cookie)
    .then((result:any)=>{
     
      //console.log("Card details",result)
      setCoachingData(result.history)
      
    })
    .catch((error:any)=>{
      //console.log("from Card",error)
    })
  }
  useEffect(()=>{
    fetchSessions()
  },[cookie])
    return(
        <>
        <div className={style.UserProfile}>
        <div className={styles.Recommond}>
          
        

        {
  CoachingData.length > 0 ? (
   
    (() => {
      const upcomingSessions = CoachingData.slice(0, 2).filter((cdata: any) => {
        const sessionDate = dayjs(cdata.date.slice(0, 10)); 
        return sessionDate.isSameOrAfter(dayjs(), 'day');
      });

     
      if (upcomingSessions.length === 0) {
        return <>No webinars Schedule!</>;
      }

     
      return upcomingSessions.map((cdata: any) => (
        <div className={styles.coaching} key={cdata.coach?.id}>
          <img
            src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`}
            className={styles.coachingImg}
          />
          <div className={styles.coachingTxt}>
            <p style={{fontWeight:'500',fontSize:'20px'}}>{cdata.coach?.name}</p>
            <div className={styles.coachEmail}>
              <p style={{color:'#0C5899',marginRight:'20px',fontWeight:'normal'}}> {cdata.coach?.email.slice(0, 14)}..</p>
              <button className={styles.coachbuttonJoin1}>
                <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">
                  Join
                </a>
              </button>
            </div>
            <div className={styles.coachtime}>
              <p style={{color:'grey',fontWeight:'500'}}>
                <AccessTimeIcon style={{ fill: "#FF5733" }} />
                {cdata.starttime}
              </p>
              <p style={{color:'grey',fontWeight:'500'}}>
                <CalendarMonthIcon style={{ fill: "#FF5733" }} />
                {dayjs(cdata.date.slice(0, 10)).format('DD MMM YYYY')}
              </p>
            </div>
          </div>
        </div>
      ));
    })()
  ) : (
    <>No Any Webinar selected!</> 
    )
}


          
          </div>
   
          </div>
         {/* </div> */}
       
        </>
    )

}
export default Webinars;