import { useEffect, useState } from "react";
import style from "./wrapper.module.css";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Coachee/CSS/dashboardHome.module.css";
import { logout } from "../../../redux/userSlice";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import { RootState } from "../../../redux/store";
type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  setHead: any;
};
function SideBar({ activeTab, setActiveTab, setHead }: propType) {
  const user:any = useSelector((state: RootState) => state.user.user);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose= () => setOpen(false);
  const handleClick = (index: number) => {
    setActiveTab(index);
  };
  const [hasShownModal, setHasShownModal] = useState(false);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const [showSidebar, setShowSidebar] = useState(true);
  const handleButt=()=>{
    if (activeTab === 0 || activeTab === 1 || activeTab === 2 || activeTab === 5 || activeTab === 6) {
      if (!user?.quadrant || user?.quadrant==0 && !hasShownModal) {
        handleOpen();
        setHasShownModal(true);
      }
    }
  }
  useEffect(() => {
    handleButt();
  }, [activeTab, user?.quadrant, hasShownModal]); 
  const renderHead = () => {
    if (activeTab === 1) {
      setHead(`Welcome Dashboard ${user?.name}`);
    } else if (activeTab === 2) {
      setHead(`All Sessions`);
    } else if (activeTab === 3) {
      setHead(`Group Engagement`);
    } else if (activeTab === 4) {
      setHead(`My Earnings`);
    } else if (activeTab === 5) {
      setHead(`My Settings`);
    } else if (activeTab === 10) {
      setHead(`Payment & Bills `);
    } else if (activeTab == 6) {
      setHead(`FAQ's`);
    } else if (activeTab == 7) {
      setHead(`All Programs`);
    } else if (activeTab == 8) {
      setHead(`Uncovering`);
    }
  };
  useEffect(() => {
    renderHead();
  }, [activeTab]);

  return (
    <>
      {/* main content */}
      <button onClick={toggleSidebar} className={style.toggleButton}>
        {showSidebar ? (
          <ViewSidebarIcon className={style.HideButton} />
        ) : (
          <ViewSidebarIcon />
        )}
      </button>
      <div className={style.sidebarContainer}>
        <div className={style.MenuToggleOpen}>
          <ViewSidebarIcon />
        </div>
        {showSidebar ? (
          <div className={style.sidebar}>
            <div className={style.smartlogo}>
              <div className={style.smarthlogo1}>
                <img
                  src="./images/Coach/login/white-logo.png"
                  className={style.whitebg}
                />
              </div>
              <div className={style.siderbarcontent}>
                <div className={style.content}>
                  <div className={style.profile}>
                    {/* profile css  */}
                    <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`} className={style.profileImg}/>
                    <div className={style.uname}>
                      <h2>{user?.name} </h2>
                      <p>{user?.email}</p>
                    </div>
                  </div>
                  <div className={style.contentitem}>
                    <ul className={style.tabCnt}>
                      <li
                        onClick={() => handleClick(1)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 1 && style.lidesign}`}>
                          {activeTab === 1 ? (
                            <img src="./images/Coach/login/dashboardwhite.svg" />
                          ) : (
                            <img src="./images/Coach/login/dashboard.svg" />
                          )}
                          Dashboard
                        </div>
                      </li>

                      <li
                        onClick={() => handleClick(2)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 2 && style.lidesign}`}>
                          {activeTab === 2 ? (
                            <img src="./images/Coach/login/sessionwhite.svg" />
                          ) : (
                            <img src="./images/Coach/login/session.svg" />
                          )}
                          All Session
                        </div>
                      </li>
                      <li
                        onClick={() => handleClick(7)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 7 && style.lidesign}`}>
                          {activeTab === 7 ? (
                            <img src="/images/svg/Coachee/whiteMyrepo.svg" />
                          ) : (
                            <img src="/images/svg/Coachee/report.svg" />
                          )}
                          All Program
                        </div>
                      </li>
                      <li
                        onClick={() => handleClick(8)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 8 && style.lidesign}`}>
                          {activeTab === 8 ? (
                            <img src="/images/svg/Coachee/whiteMyrepo.svg" />
                          ) : (
                            <img src="/images/svg/Coachee/report.svg" />
                          )}
                          Uncovering
                        </div>
                      </li>

                      <li
                        onClick={() => handleClick(3)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 3 && style.lidesign}`}>
                          {activeTab === 3 ? (
                            <img src="./images/Coach/login/groupwhite.svg" />
                          ) : (
                            <img src="./images/Coach/login/group.svg" />
                          )}
                          Group Engagement
                        </div>
                      </li>

                      <li
                        onClick={() => handleClick(4)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 4 && style.lidesign}`}>
                          {activeTab === 4 ? (
                            <img src="./images/Coach/login/earningwhite.svg" />
                          ) : (
                            <img src="./images/Coach/login/dollar.svg" />
                          )}{" "}
                          My Earning
                        </div>
                      </li>
                      <li
                    onClick={() => handleClick(9)}
                    className={`${style.tabDesign}`}
                  >
                      <div className={`${activeTab === 9 && style.lidesign}`}>
                      {activeTab === 9 ? (
                        <img src="/images/svg/Coachee/whiteSub.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myAssess.svg" />
                      )}{" "}
                      Assessment
                    </div>
                  </li>
                      <li
                        onClick={() => handleClick(5)}
                        className={`${style.tabDesign}`}>
                        <div className={`${activeTab === 5 && style.lidesign}`}>
                          {activeTab === 5 ? (
                            <img src="./images/Coach/login/settingwhite.svg" />
                          ) : (
                            <img src="./images/Coach/login/settings.svg" />
                          )}{" "}
                          My Settings
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <div className={style.about}>
                      <ul>
                        <li>About us</li>
                        <li>How it works</li>
                        <li>Resources and articles</li>
                        <li onClick={() => dispatch(logout())}>logout</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  <>Take Free Assessment</>  
                              </Typography>
                <Typography
                id="modal-modal-description"
                className={styles.typoDesc}
              ></Typography>
              <>
                <h6> Start your Free Assessment </h6>
                <p>Lorem Ipsum is simply dummy text of the printing and<br/> typesetting industry. Lorem Ipsum has been the industry's<br/> standard dummy text ever since the 1500s, </p>
                <button onClick={()=>{setActiveTab(9);handleClose()}} className={styles.DeleteCard1}> Start Now </button>
                </>
                </Box>
                </Modal>
            </div>
          </div>
        ) : (
          <div className={style.Sidebar2}></div>
        )}
      </div>
    </>
  );
}

export default SideBar;
