const DEP_TYPE = process.env.REACT_APP_DEPLOYMENT || "local";

const SERVER_HOSTNAME =
    (DEP_TYPE === "production"
        ? process.env.REACT_APP_SERVER_HOSTNAME_PROD
        : DEP_TYPE === "development"
            ? process.env.REACT_APP_SERVER_HOSTNAME_DEV
            : process.env.REACT_APP_SERVER_HOSTNAME_LOCAL) || "";

const FRONT_HOSTNAME =
    (DEP_TYPE === "production"
        ? process.env.REACT_APP_FRONT_HOSTNAME_PROD
        : DEP_TYPE === "development"
            ? process.env.REACT_APP_FRONT_HOSTNAME_DEV
            : process.env.REACT_APP_FRONT_HOSTNAME_LOCAL) || "";

const STRIPE_PUBLIC_KEY =
    (DEP_TYPE === "production"
        ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
        : DEP_TYPE === "development"
            ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
            : process.env.REACT_APP_STRIPE_PUBLIC_KEY_LOCAL) || "";

const STRIPE_SECRET_KEY =
    (DEP_TYPE === "production"
        ? process.env.REACT_APP_STRIPE_SECRET_KEY_PROD
        : DEP_TYPE === "development"
            ? process.env.REACT_APP_STRIPE_SECRET_KEY_DEV
            : process.env.REACT_APP_STRIPE_SECRET_KEY_LOCAL) || "";

const CDN_LINK = process.env.REACT_APP_CDN || "";

const configuration = {
    mode: DEP_TYPE,
    cdnFront: CDN_LINK,
    apiUrl: SERVER_HOSTNAME,
    googleClientId:
        "336322284813-u73gv905s926hvdqhf8e1u47jtc54m1d.apps.googleusercontent.com/",
    stripeKey: STRIPE_SECRET_KEY,
    stripePublicKey: STRIPE_PUBLIC_KEY,
    vapidKey: "BMPjvvV0zl0JJEhYLPV9uUEt-RXVatKQNJnAXx0L9F1st1P85f5h-2PLBzj8-rsP-JN3MN9ZHusvkZQsjIBnx5M",
};

export default configuration;
