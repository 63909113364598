import { InputAdornment, TextField } from "@mui/material";
import FormData from "form-data";
import styles from "./setup.module.css";
import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ContactInfo, fetchCoachData } from "../../Api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notifyError } from "../../Notify";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import PersonIcon from "@mui/icons-material/Person";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import dayjs from "dayjs";
import { cdn_Link } from "../../config";
interface NewUser {
  profile: File | null;
  videoLink: File | null;
}

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  activeTab:any
};

interface UserType {
  bdate: any;
  phoneNo: any;
  behaviourIdenty: any;
  topvalues: any;
  comvalues: any;
  locationvalue: any;
  linkedinLink: any;
  instagramLink: any;
  facebookLink: any;
  twitterLink: any;
  profile: File | null;
  videoLink: File | null;
}
const CpTab1 = ({ setActiveTab, activeTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[handleDImg,setHandleDImg]=useState<any>([])
  const[coachData,setCoachData]=useState<any>([])
  useEffect(()=>{
    handleCoachData()
  },[cookie,activeTab])
  const [previewImage, setPreviewImage] = useState('');
  const [previewVideo, setPreviewVideo] = useState("");
  const [loader, setLoader] = useState(false)
  const[handleWait,setHandleWait]=useState(false)

  const [newUser, setNewUser] = useState<NewUser>({
    profile: null,
    videoLink: null,
  });
  const handleCoachData=()=>{
    fetchCoachData(cookie).then((res:any)=>{
      setCoachData(res.data)
      //console.log(res);
      
    })
    .catch((error:any)=>{
      //console.log(error)
    })
  }


  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleVideoClick = () => {
    const videoInput = document.getElementById("videoInput");
    if (videoInput) {
      videoInput.click();
    }
  };
  const handleVideo = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setNewUser({ ...newUser, videoLink: file });
    if(coachData?.introVideo){
      const videoURL = `${cdn_Link}/${coachData?.introVideo}`;
      setPreviewVideo(videoURL);
    }
    else{
      const videoURL = URL.createObjectURL(file);
      setPreviewVideo(videoURL);
    }
    }
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, profile: file });
// `${cdn_Link}/${coachData?.profileImg}`||'/images/coach/shape-2x.png'
    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
 const userprofile:any=`${cdn_Link}/${coachData?.profileImg}`
  const locationvar = [
    coachData?.location?.streetName || "",
    coachData?.location?.city || "",
    coachData?.location?.state || "",
    coachData?.location?.postalCode || "",
  ].filter(Boolean).join(",");
  const formik:any = useFormik<UserType>({
    enableReinitialize: true,
    initialValues: {
      
      bdate: `${dayjs(coachData?.birthDate).format('YYYY-MM-DD')}`|| Date,
      phoneNo:coachData?.phoneNo|| "",
      behaviourIdenty:coachData?.behavioralIdentity|| "",
      topvalues:coachData?.topTalents|| "",
      comvalues:coachData?.commpower|| "",
      locationvalue: locationvar||"",
      linkedinLink: coachData?.linkedinLink|| "",
      instagramLink:coachData?.instagramLink|| "",
      facebookLink: coachData?.facebookLink|| "",
      twitterLink:coachData?.twitterLink|| "",
      profile:userprofile || null,
      videoLink: null,
    },
    validationSchema: Yup.object({
      bdate: Yup.date().required("Required"),
      phoneNo: Yup.string()
        .required("Required")
        .matches(/^\d{10}$/, "Must be a valid phone number"),
      behaviourIdenty: Yup.string().required("Required")
        .test("is-valid-behavior", " Minimum three super Power Add with commas(,) ", (value: any) => {
          return value ? value.split(",").length == 3 : false
        }),
      topvalues: Yup.string().required("Required")
        .test("is-valid-behavior", " Minimum Top Three Talent Add with commas(,) ", (value: any) => {
          return value ? value.split(",").length == 3 : false
        }),
      comvalues: Yup.string().required("Required")
        .test("is-valid-behavior", " Minimum three Communication SuperPower Add with commas(,) ", (value: any) => {
          return value ? value.split(",").length == 3 : false
        }),
      locationvalue: Yup.string().required("Required")
        .test("is-valid-behavior", " StreetName,City,State,Pincode Must to add  ", (value: any) => {
          return value ? value.split(",").length >= 4 : false; 
        }),
      linkedinLink: Yup.string().url("Invalid URL").required("Required"),
    }),
    onSubmit: (values: any) => {
      
      if (!newUser.profile) {
        notifyError('Profile Image Required')
      }
      else {
        setHandleWait(true)
        const formData = new FormData();
        const behavioralIdentity = values.behaviourIdenty.split(",").map((item: any) => item.trim());
        const topTalents = values.topvalues.split(",").map((item: any) => item.trim());
        const commpower = values.comvalues.split(",").map((item: any) => item.trim());
        // const locationString = JSON.stringify(values.locationvalue);
        const valuesForm = values.locationvalue.split(',');

        const locationNum = {
          streetName: valuesForm[0],
          city: valuesForm[1],
          state: valuesForm[2],
         // country: valuesForm[3],
          postalCode: valuesForm[3]
        };
        const location = JSON.stringify(locationNum)
        //console.log()
        // 
        const formattedDate: any = dayjs(values.bdate, 'DD/MM/YYYY').format('MM/DD/YYYY');
        formData.append("birthDate", formattedDate);
        formData.append("phoneNo", values.phoneNo);
        behavioralIdentity.forEach((identity: any, index: any) => {
          formData.append(`behavioralIdentity[${index}]`, identity);
        });
        topTalents.forEach((talents: any, index: any) => {
          formData.append(`topTalents[${index}]`, talents)
        });
        commpower.forEach((power: any, index: any) => {
          formData.append(`commpower[${index}]`, power)
        });
        formData.append("location", location);
        formData.append("linkedinLink", values.linkedinLink);
        formData.append("instagramLink", values.instagramLink);
        formData.append("facebookLink", values.facebookLink);
        formData.append("twitterLink", values.twitterLink);
        formData.append("profile", newUser.profile);
        formData.append("videoLink", newUser.videoLink);
        // setLoader(true)
        ContactInfo(formData, cookie)
          .then(() => {
            setHandleWait(false)
            // dispatch(updateFormData(values));
            setActiveTab((prev: number) => prev + 1);
            setLoader(false)
          })
          .catch((error: any) => {
            notifyError(error?.response?.data?.message || 'Missing Fields')
            console.error(error);
            setLoader(false)
            setHandleWait(false)
          });
      }


    },
  });


  return (
    <div className={styles.mainContainer}>
      <form onSubmit={formik.handleSubmit} className="mt-2">
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <p className={styles.DateOf}>Date  of Birth</p>
            <TextField
              type="date"
              variant="outlined"
              name="bdate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={ formik.values.bdate}
              error={formik.touched.bdate && Boolean(formik.errors.bdate)}
              helperText={formik.touched.bdate && formik.errors.bdate}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
                
              }}
              FormHelperTextProps={{
                style: { width: '50px', whiteSpace: 'normal' }  
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                  maxWidth:'400px'
                },
                 maxWidth:'420px'
              }}
              inputProps={{
                max: new Date(new Date().setFullYear(new Date().getFullYear() - 19))
                  .toISOString()
                  .split('T')[0], 
              }}
              required
            />
      
          </div>
          <div className={styles.RightInputField}>
            <TextField
              type="text"
              label="Contact Number"
              variant="outlined"
              name="phoneNo"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {  
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNo}
              error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
                disableUnderline: true,               
              
              }}
              inputProps={{
                maxLength: 11,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                 
                },
                 maxWidth:'420px'
              }}
              required
            />
          </div>
        </div>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="text"
              label="Behavioral Superpowers Identity"
              variant="outlined"
              name="behaviourIdenty"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.behaviourIdenty}
              error={formik.touched.behaviourIdenty && Boolean(formik.errors.behaviourIdenty)}
              helperText={formik.touched.behaviourIdenty && formik.errors.behaviourIdenty}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                 
                },
                maxWidth:'420px'
              }}
              required
            />
          </div>
          <div className={styles.RightInputField}>
            <TextField
              type="text"
              label="Three Top Talents"
              variant="outlined"
              name="topvalues"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.topvalues}
              error={formik.touched.topvalues && Boolean(formik.errors.topvalues)}
              helperText={formik.touched.topvalues && formik.errors.topvalues}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
                maxWidth:'420px'
              }}
              required
            />
          </div>
        </div>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="text"
              label="Communication SuperPower"
              variant="outlined"
              name="comvalues"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comvalues}
              error={formik.touched.comvalues && Boolean(formik.errors.comvalues)}
              helperText={formik.touched.comvalues && formik.errors.comvalues}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                  maxWidth:'400px'
                },
                 maxWidth:'420px'
              }}
              required
            />
          </div>
          <div className={styles.RightInputField}>
            <TextField
              label="Location (i.e., streetname, city, state, pincode)"
              variant="outlined"
              name="locationvalue"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.locationvalue}
              error={formik.touched.locationvalue && Boolean(formik.errors.locationvalue)}
              helperText={formik.touched.locationvalue && formik.errors.locationvalue}
              sx={{
                mr: 2,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              InputProps={{
                className: `${styles.muiInput}`,
                endAdornment: (
                  <InputAdornment position="end">
                    <LocationOn />
                  </InputAdornment>
                ),
              }}
              required
            />
          </div>
        </div>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="text"
              label="LinkedIn Profile link"
              variant="outlined"
              name="linkedinLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.linkedinLink}
              error={formik.touched.linkedinLink && Boolean(formik.errors.linkedinLink)}
              helperText={formik.touched.linkedinLink && formik.errors.linkedinLink}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
                 maxWidth:'420px'
              }}
              // required
            />
          </div>
          <div className={styles.RightInputField}>
            <TextField
              type="text"
              label="Instagram Profile link"
              variant="outlined"
              name="instagramLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.instagramLink}
              error={formik.touched.instagramLink && Boolean(formik.errors.instagramLink)}
              helperText={formik.touched.instagramLink && formik.errors.instagramLink}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
                 maxWidth:'420px'
              }}
              // required
            />
          </div>
        </div>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="text"
              label="Facebook Profile link"
              variant="outlined"
              name="facebookLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.facebookLink}
              error={formik.touched.facebookLink && Boolean(formik.errors.facebookLink)}
              helperText={formik.touched.facebookLink && formik.errors.facebookLink}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
                 maxWidth:'420px'
              }}
              // required
            />
          </div>
          <div className={styles.RightInputField}>
            <TextField
              type="text"
              label="Twitter Profile link"
              variant="outlined"
              name="twitterLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.twitterLink}
              error={formik.touched.twitterLink && Boolean(formik.errors.twitterLink)}
              helperText={formik.touched.twitterLink && formik.errors.twitterLink}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {

                },
                 maxWidth:'420px'
              }}
              // required
            />
          </div>
        </div>
        <div className={styles.ImgVideoContent}>
          <div className={styles.ProfileImage}>


            <input
              type="file"
              id="fileInput"
              name="profile"
              onChange={handleProfile}
              accept="image/*"
              style={{ display: "none" }}
              required
            />

            <div className={styles.MainImg}>
              {!previewImage ? (
                <>
                  <p>Profile Image</p>
                  <PersonIcon
                    // alt="Preview"
                    style={{
                      width: "100px",
                      height: "100px"
                    }}
                    className={styles.MainImgPre}
                  />
                  <p style={{ fontSize: '10px' }}>File Format jpg,jpeg,png</p>

                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                    />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={coachData?.profileImg?`${cdn_Link}/${coachData?.profileImg}`:previewImage}
                    alt="Preview"
                    className={styles.MainImgPre1}
                  />
                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/*        video code        */}

          <div className={styles.ProfileImage}>
            <input
              type="file"
              id="videoInput"
              name="videoLink"
              onChange={handleVideo}
              accept="video/*"
              style={{ display: "none" }}

            />

            <div className={styles.MainImg}>
              {!previewVideo ? (
                <>
                  <p>Select your Video</p>
                  <p style={{ cursor: "pointer" }}>
                    <div className={styles.MainVideo}>
                      <VideoCameraFrontIcon className={styles.MainVideoIcon} style={{
                        width: "100px",
                        height: "100px"
                      }} />

                      <sub>file format mp4, mkv</sub>
                    </div>
                    <div className={styles.IconImg}>
                      <img
                        src="/images/svg/Coach/group-8291.svg"
                        onClick={handleVideoClick}

                      />
                    </div>
                  </p>
                </>
              ) : (
                <>
                  <div className={styles.MainVideo}>
                    <video controls>
                      <ReactPlayer url={previewVideo} />
                      {/* <source src={previewVideo} type="video/*"className={styles.MainVideoIcon} /> */}
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className={styles.IconVideo}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleVideoClick}

                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={styles.textcenter1}>
          {/* {loader==true ? <>Saving ...</>: */}
          <button className={styles.button4} type="submit">
          {handleWait==true ?'loading..':'Next'}  
          </button>

        </div>

      </form>
    </div>
  );
};

export default CpTab1;
