import { useEffect, useState } from "react"
import style from "./blogs.module.css"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { fetchblogs } from "../../../../Api"
type proptype={
    blogId:any 
}
const ShowBlog=({blogId}:proptype)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;

    const[blogImg,setBlogImg]=useState('')
    const[blogTitle,setTitle]=useState('')
    const[blogDesc,setDesc]=useState('')
    const[blogContent,setContent]=useState('')
    useEffect(()=>{
        fetchblogs(blogId)
        .then((res:any)=>{
            //console.log(res.data);
            
            setTitle(res.data.title)
            setDesc(res.data.description)
            setBlogImg(res.data.img)
            setContent(res.data.content)
            
        }) 
    },[cookie])
    const encodedContent = encodeURIComponent(blogContent);
    const dataUrl = `data:text/html;charset=utf-8,${encodedContent}`;
    // fetchblogs
return(<>
<div className={style.showBlog}>
    <div className={style.showBlogLeft}>
     
            <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${blogImg}`} className={style.blogImage}/>
        <div className={style.blogtitles}>
        <h6>{blogTitle}</h6>
        <p>{blogDesc}</p>
        </div>
    </div>
    <div className={style.showBlogRight}>
    <iframe
      src={dataUrl}
     className={style.iframe}
       title="Content"
       
    />
    </div>
    
</div>
</>)
}
export default ShowBlog;