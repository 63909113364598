import { useEffect, useState } from "react";
import styles from "../../../styles/dashboardHome.module.css";
import { fetchCoachGraph, fetchCoachPayment, fetchDashFigureCoach } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { notifyError } from "../../../Notify";
import dayjs from "dayjs";
import { AirplaneTicketRounded, CalendarMonth } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DollarCircleFilled } from "@ant-design/icons";
import Pagination from "../../Pagination/Pagination";
import { number } from "yup";
import { formatNumber } from "../../../utils/helper";

type userType = {
  clientPer:string, hourPer:string, sessionPer:string
}

const Earning = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [userdata, setUserData] = useState<userType>({
    clientPer:"+0", hourPer:"+0", sessionPer:"+0"
  });
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
    totalPayment:'',
    currentMonthPayment:'',
    totalIncome:0

  });
  const fetchFigure = () => {
    fetchDashFigureCoach({ token: cookie }).then((result: any) => {
      //console.log(result)
      const { clientPer, hourPer, sessionPer } = result?.data;
      setUserData(
        {clientPer: clientPer || "0",  hourPer: hourPer || "0", sessionPer: sessionPer || "0"}
      )
    
    })
  }
  const fetchGraph=()=>{
    fetchCoachGraph(cookie)
    .then((res:any)=>{
  //console.log("coach graph",res)
  setSessionData(res.data);
    })
    .catch((Err:any)=>{
      notifyError(Err.response.data.message)
    })
  }
  const chartData = sessionData.map((item: any) => ({
    name: item.month, 
    Sessions: item.totalSessions, 
  }));
  const fetchPayment=()=>{
    fetchCoachPayment(data.page,cookie)
    .then((result:any)=>{
      let res = result.data;
      const totalPayment = res.history.reduce((sum: number, payment: any) => {
        return sum + payment.amount; 
      }, 0);
      
     
     
      const currentMonthPayment = res.history.reduce((sum: number, payment: any) => {
        const paymentDate = new Date(payment.createdAt); 
        const currentDate = new Date();

      
        if (
          paymentDate.getFullYear() === currentDate.getFullYear() &&
          paymentDate.getMonth() === currentDate.getMonth()
        ) {
          return sum + payment.amount; 
        }
        return sum;
      }, 0);
      const totalIncome = () => {
        if (totalPayment === 0) {
          return 0; 
        }
        const tot=(currentMonthPayment / totalPayment) * 100 
        return tot;
      };
  
    let x: any = { ...data };
    x = {
      ...x,
      next: res.next,
      prev: res.prev,
      total_page: res.total_page || 1,
      history: [...res.history],
      totalPayment:totalPayment,
      currentMonthPayment:currentMonthPayment,
      totalIncome:totalIncome()
    };
    setData(x);
    })
  }
  useEffect(() => {
   
    fetchFigure();
    fetchGraph();
    fetchPayment()
  }, []);
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  return (
    <>
      <div className={styles.mainContainer}>
        {/* first */}
        <div className={styles.firstContainer}>
          <div className={styles.menuesBox}>
            <div className={styles.item}>
              {/* box 1 */}
              <div>
                <p style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  LIFE TIME EARNINGS
                </p>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/dash-user.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$ {data.totalPayment}</h5>
              </div>
              <div className={styles.corno}>
              <div className={styles.corner}>
                <img src={parseFloat(data.totalPayment) >= 0 ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} alt="" />
                <p style={{color:'black'}}> {formatNumber(data.totalIncome)} %</p>
              </div>
                <p style={{ fontSize: "9px",marginTop:'-10px' }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 2 */}
              <div>
                <p
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  earnings this Month
                </p>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/carbon_headphones.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$ {data.currentMonthPayment}</h5>
              </div>
              <div className={styles.corno}>
              <div className={styles.corner}>
                <img src={parseFloat(data.currentMonthPayment) >= 0 ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} alt="" />
                <p style={{color:'black'}}>{parseFloat( data.currentMonthPayment)}%</p>
                </div>
                <p style={{ fontSize: "9px",marginTop:'-15px', textTransform: "capitalize" }}>
                  Since Last Month
                </p>
              </div>
            </div>
            {/* <div className={styles.item}>
           
              <div>
                <p
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  Upcoming Payments
                </p>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ width: "24px" }}
                  src="/images/Coach/hour-glass.png"
                  alt=""
                />
              </div>
              <div>
                <h5>1299</h5>
              </div>
              <div>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <p> -6.34%</p>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
            
              <div>
                <p
                  style={{
                    color: "#9FA4AF",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  Available Balance
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ height: "33px" }}
                  src="/images/Coach/money-bag.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$1250</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div> */}
          </div>
          <div className={styles.ChartGraph}>
            <div className={styles.headLine}>
              <h5>Total Sessions</h5>{" "}
            </div>
            <div>
            <div className={styles.selectBar}>
              <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="1 1 "  />
          <XAxis dataKey="name" interval={1}/>
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Sessions" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* second */}
        <div className={styles.middleContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" style={{paddingRight:'10px'}} />
                Payment Details
              </h3>
            </div>
            <div>
              
            </div>
          </div>
       
            <table>
              <thead>
                <tr>
                 
                  <th>AMOUNT</th>
                  <th>PAYMENT DATE</th>
                  <th>STATUS</th>
                 
                </tr>
              </thead>
              <tbody>
             {data.history.length>0?data.history.map((payment:any)=>(<tr>
              <td style={{display:'flex',justifyContent:'center',width:'auto',alignItems:'center'}}><DollarCircleFilled style={{color:'#EF633E',fontSize:'20px',marginRight:"10px"}}/> {payment.amount}</td>
              <td><CalendarMonth style={{color:'#EF633E',marginRight:"10px"}}/>{dayjs(payment.createdAt).format('DD MMM YYYY')}</td>
              <td><CheckCircleIcon style={{color:'#97CC4F'}}/> {payment.status}</td>
             
             </tr>)):'No Payment Recorded!'}
              </tbody>
            </table>
          </div>
      

        {/* third */}
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
    </>
  );
};

export default Earning;
