import { useEffect, useState } from "react";
import style from "./profiledata.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getComment } from "../../../../../Api";
import { cdn_Link } from "../../../../../config";
const MyReview=()=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[comments,setComments]=useState<any>([])
    const MyReviews=()=>{
        getComment(cookie)
        .then((res:any)=>{
            //console.log("Reviews",res)
            setComments(res.history)

        })
    }
    useEffect(()=>{
        MyReviews();
    },[cookie])
return(
    <>
         <div className={style.UserProfile}>

        
           {comments.length>0?(comments.map((comment:any)=>(
            comment.coacheeComment.isCommented==true?(
                <div className={style.MyReview}>
                   <div className={style.MyReviewContent}>
                    <img src={`${cdn_Link}/${comment.pId.programImg}`} className={style.programImg}/>
                   <div className={style.flexDist}><p> {comment.pId?.name}</p></div>
                   
                   </div><p className={style.commentPara}>"{comment.coacheeComment?.comment} "</p>
                   
                </div> 
            ):''
           ))):'No Comments Found!'}
         </div>
    </>
)
}
export default MyReview