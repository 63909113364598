import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "../../../../styles/setting.module.css";
import { ChangePassword } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";

const SetTab1 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old password is required')
      .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
      .matches(/(?=.*\d)/, 'Password must include at least one number')
      .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
      .min(8, 'Password must be at least 8 characters long')
      .max(50, 'Password must be at most 50 characters long'),
      newPassword: Yup.string().required("Password is required")
      .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
      .matches(/(?=.*\d)/, 'Password must include at least one number')
      .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
      .min(8, 'Password must be at least 8 characters long')
      .max(50, 'Password must be at most 50 characters long'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: async (values) => { // Mark this function as async
      const { oldPassword, newPassword, confirmPassword } = values;
      try {
        const result: any = await ChangePassword(oldPassword, newPassword, confirmPassword, cookie);
        //console.log(result);
        if(result){
          notifySuccess(result.data?.message || "password Reset Successfully")

        }
      } catch (error: any) {
        notifyError(error.response?.data?.message );
      }
    },  
  });

  return (
    <div className={style.tabcontainer}>
      <div className={style.tabheader}>Authentication</div>
      <div className={style.changepass}>
        <header className={style.changepwdhead}>Change Password</header>
        <form onSubmit={formik.handleSubmit}>
          <div className={style.forminput4}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                name="oldPassword"
                type="password"
                label="Old Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
             
                name="newPassword"
                type="password"
                label="New Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
               
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                sx={{ my: 2, width: "100%" }}
                variant="standard"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </Box>
          </div>
          <div className={style.formbutton}>
            <button type="submit">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetTab1;
