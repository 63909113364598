import { Box, Button, MenuItem, Select } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import { getToken } from "firebase/messaging";
// import { messaging } from "../../../firebase";
import { useEffect, useState } from "react";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchCard, fetchCoacheeSession, fetchRecommendCoach, upgradePlans } from "../../../Api";
import { notifyError, notifySuccess } from "../../../Notify";
import dayjs from "dayjs";
import configuration from "../../../config/configuration";

const stripepromise = loadStripe(configuration.stripePublicKey)
type propType={
  setActiveTab: (val: (v: number) => number) => void;
}
const HomePayOut = ({setActiveTab}:propType)=>  {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  //console.log("profile", user?.profileSetup);
  const stripe = useStripe();
  
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [CoachingData,setCoachingData]=useState<any[]>([])
  const[coachData,seCoachData]=useState<any[]>([]);
  // async function requrestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const Tok = await getToken(messaging, {
  //       vapidKey:
  //         "BGrRgMNPpZSeLkJ0LPvw8GZ41-OpfjkyS49qhtNvTG-Jd0AKLAg6qN9gURNf6MeYFDlXHoM7lhdgUt5YJdUHXBY",
  //     });
  //     setnotifytoken(Tok);
  //     try {
  //       const res = await UpdateToken(cookie, Tok);
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }
  // useEffect(() => {
  //   fetchCoach(cookie,2)
  //     .then((result: any) => {
  //       //console.log("Coach data:", result);
  //       setCoachData(result.data);
  //       requrestPermission();
  //     })
  //     .catch((error: any) => {
  //       console.error("Error fetching coach data:", error);
  //     });
  // }, [cookie]);
  const [cardId, setCardId] = useState("")
  const [cards, setCards] = useState<any[]>([]);
  const handleSubmit = (e: any) => {
    e.preventDefault()


    upgradePlans( cardId, cookie)
      .then(async (res: any) => {
       
        const { error, paymentIntent }: any = await stripe!.confirmCardPayment(
          res.data.clientSecret,
          {
            payment_method: cardId,
          }
        );
        if (paymentIntent && paymentIntent.status === "succeeded") {
          notifySuccess("Payment Successful");
           
        handleClose()

        }
        if (error && error.payment_intent.status === "succeeded") {
          notifySuccess("Payment Successful");
          handleClose()
        } else if (error) {
          //console.log(error);
          handleClose()
        }

      })
      .catch((err: any) => {
        notifyError(err.response.data.message)
      })


  }
  useEffect(()=>
    {
      fetchRecommendCoach(1,cookie,1)
      .then((result: any) => {
      //console.log(result)
      seCoachData(result.data.history)
      
  
      
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error);
      });
  }, [cookie]);
  useEffect(()=>{
    fetchCoacheeSession(1,'',cookie)
    .then((result:any)=>{
     
      //console.log("Card details",result)
      setCoachingData(result.history)
      
    })
    .catch((error:any)=>{
      //console.log("from Card",error)
    })
  },[cookie])
  const handleCardVal = (event: any) => {

    setCardId(event.target.value)
  }
  useEffect(() => {
    fetchCard(cookie)
      .then((result: any) => {
        setCards(result.data)
        //console.log("Card details", result)

      })
      .catch((error: any) => {
        //console.log("from Card", error)
      })
  }, [cookie])
  return (
   
      <div className={styles.dashcontainer}>
        <div className={styles.upgradeTap} onClick={()=>setActiveTab(()=>5)}>
          <div className={styles.upgradeTxt} >
            {user?.upgraded==true? <><h3>Check Now</h3>
              <h2>Comprehensive Report</h2></>:<><h3>Upgrade to</h3>
              <h2>Comprehensive Report</h2></>}
            
            <br />
            <h4>Features</h4>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
            <p>- hello this is from your dashboard</p>
          </div>
          <div className={styles.upgradeButton}>
          {user?.upgraded==false ? <Button onClick={handleOpen}>Upgrade Now</Button>:(<></>)}
          
           
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={styles.BoxStyle}>
                <span onClick={handleClose} className={styles.HandleClose}>
                  <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Make Payment
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={styles.typoDesc}
                >
                  <div className={styles.UpgradeDiv}>
                    <img src="./images/Coachee/placeholder.png" />
                    <div className={styles.UpgradeText}>
                      <br />
                      <h6>Upgrade to Comprehensive Report</h6>
                      <p>-Lorem Ipsum is simply dummy </p>
                      <p>-Lorem Ipsum is simply dummy </p>
                      <p>-Lorem Ipsum is simply dummy </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.TotalPay}>
                    <h6>Total Payment</h6>
                    <h6> $250.00 </h6>
                  </div>
                  <br />
                  <Select
                        onChange={handleCardVal}
                        className={styles.cardDesignSelect}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Card"
                      >
                        {cards && cards.length > 0 ? (
                          cards.map(cinfo => (
                            <MenuItem key={cinfo.id} value={cinfo.id} className={styles.MenuItems}>
                              <div className={styles.MenuItems}>
                                <p><CreditCardIcon /> John Doe</p>
                                <p className={styles.MenuItemsIC}>
                                  <FormatListNumberedIcon className={styles.MenuItemsIC} />
                                  {' '}.... .... .... {cinfo.last4}
                                </p>

                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <p className={styles.dataColors}>Please Add Cards.. Go To Settings!</p>
                          </MenuItem>
                        )}
                      </Select>
                      {/* <button className={style.enrolledRecordBtn}>Pay Now</button> */}
                     
                  <button type="submit"
                    className={styles.TypoButton}
                    
                  onClick={(e:any)=>handleSubmit(e)}>
                    Pay Now
                  </button>
                  <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                   Cancel</button>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
        
          {/* </div> */}
       {/* </div> */}
        {/*            Recommended Coaches     */}
        <div className={styles.UpcomingContainer}>
        <div className={styles.Upcoming}>
        <div className={styles.UserListContent}>
            <h4>Upcoming Coachings </h4>

            <h6 onClick={()=>setActiveTab(()=>2)}>View All </h6>
          </div>

            </div>
        <div className={styles.Recommond}>
          
        

        {
  CoachingData.length > 0 ? (
    CoachingData.slice(0, 4).map((cdata: any) => {
      const sessionDate = dayjs(cdata.date.slice(0, 10));
      const isUpcoming = sessionDate.isSameOrAfter(dayjs(), 'day'); 
      
      return isUpcoming ? (
        <>
          <div className={styles.coaching}>
            <img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`}
              className={styles.coachingImg}
            />
            <div className={styles.coachingTxt}>
              <p style={{fontSize:'20px',fontWeight:'500'}}>{cdata.coach?.name}</p>
              <div className={styles.coachEmail}>
                <p style={{color:'#0C5899',marginRight:'20px',fontWeight:'normal'}}>{cdata.coach?.email.slice(0, 14)}..</p>
                <button className={styles.coachbuttonJoin1}>
                  <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">
                    Join
                  </a>
                </button>
              </div>
              <div className={styles.coachtime}>
                <p style={{color:'gray',fontSize:'15px',fontWeight:'normal'}}>
                  <AccessTimeIcon style={{ fill: "#FF5733" }} />
                  {cdata.starttime}
                </p>
                <p style={{color:'gray',fontSize:'15px',fontWeight:'normal'}}>
                  <CalendarMonthIcon style={{ fill: "#FF5733" }} />
                  {sessionDate.format('DD MMM YYYY')}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null; 
    })
  ) : (
    <>No Any Session Scheduled!</>
  )
}



</div>
       
{user?.upgraded==true && (<>
  <br/><br/>
<div className={styles.Upcoming}>
<div className={styles.UserListContent}>

            <h4>Recommended Coaches </h4>

            <h6 onClick={()=>setActiveTab(()=>4)}>View All </h6>
          </div>
            </div>
<div className={styles.Recommond}>
         {coachData.length>0?(coachData.map((cdata:any)=><>
         
        
          <div className={styles.coaching}>
          <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.uid.profileImg}`}
                        className={styles.coachingImg}
                      />
              <div className={styles.coachingTxt}>
                <p>{cdata.uid.name}</p>
                <div className={styles.category}>
                 
                 <span> {cdata.behavioralIdentity.slice(0, 2)}</span>
                </div>
                <div className={styles.coachEmail}>
                  <span>{cdata.uid.email.slice(0,18)}..</span>
                </div>
              </div>
              </div>
          


         </>)):(<>No coach Data Found!</>)}
        </div>
        </> )}
      </div>  
      
      </div>
      
  );
};

const Home= ({setActiveTab}:propType)=>{
return(
  <Elements stripe={stripepromise}>
  <HomePayOut setActiveTab={setActiveTab} />
</Elements>
)
}
export default Home;
