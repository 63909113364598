import { useState } from "react";
import Indexing from "../components/Dashboard/Coach/wrapper/Indexing";
import Index from "../components/Dashboard/Coachee/wrapper/Index";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

import Setup from "./Setup";
import AdminIndex from "../components/Dashboard/Admin/wrapper/AdminIndex";

const Dashboard = () => {
  const user = useSelector((state: RootState) => state.user.user);
  return (
    <>
      <AuthenticatedLayout>
        {user?.profileSetup ? (
          <>
            {user?.role === 0 && <AdminIndex />}
            {user?.role === 1 && <Indexing />} 
            {user?.role === 2 && <Index />}
          </>
        ) : (
          <>
            <Setup />
          </>
        )}
      </AuthenticatedLayout>
    </>
  );
};

export default Dashboard;
