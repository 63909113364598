import style from "../../../../styles/setting.module.css";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import { bankDetails, bankDetailsUpdate, FetchbankDetails } from "../../../../Api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../redux/store";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { Box, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { notifyError, notifySuccess } from "../../../../Notify";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import configuration from "../../../../config/configuration";
const strp = loadStripe( configuration.stripePublicKey);

const UpdateForm=()=>{
  const stripe:any = useStripe();
  const elements:any = useElements();
  const [bankdetails, setBankDetails] = useState({

    bank_name: "",
    last4: "",
    account_holder_name: "",
    routing_number: "",
  });
  const updateBankDetails = async(e: any) => {
    const cardElement: any = elements.getElement(CardElement);
    const { token, error }: any = await stripe.createToken(cardElement);
      //console.log(token.id);
    e.preventDefault();
    // bankDetailsUpdate(bankaccount,account,cookie)
    // .then((res:any)=>{
    //   notifySuccess(res.data.message)
    // })
    // .catch((err:any)=>{
    //   notifyError(err?.response?.data?.message || err?.message)
    // })
   };
   const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setBankDetails((prev:any) => ({
      ...prev,
      [name]: value,
    }));
  };
  return(
    <>
     <div className={style.thisMsg}>
                <TextField
                  type="text"
                  name="bank_name" // Add name attribute
                  label="Enter Bank Name"
                  variant="outlined"
                  value={bankdetails.bank_name} // Bind value
                  onChange={handleInputChange} // Handle change
                  InputProps={{
                    className: `${style.muiInput}`,
                  }}
                  sx={{ mr: 1, my: 1, borderRadius: "20px", background: "white", width: '100%',
                    "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                   }}
                />
                {/* <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={accountType} // Bind to accountType
                  onChange={(e) => setAccountType(e.target.value)} // Handle change
                  sx={{
                    mr: 1,
                    my: 1,
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  className={style.Inputfield}
                  label="Select Card"
                >
                  <MenuItem value="individual" className={style.MenuItems}>
                    Individual Account
                  </MenuItem>
                  <MenuItem value="company" className={style.MenuItems}>
                    Company Account
                  </MenuItem>
                </Select> */}
                <TextField
                  type="text"
                  name="last4" // Add name attribute
                  label="Account Number"
                  variant="outlined"
                  value={bankdetails.last4} // Bind value
                  onChange={handleInputChange} // Handle change
                  InputProps={{
                    className: `${style.muiInput}`,
                  }}
                  sx={{ mr: 1, my: 1, borderRadius: "20px", background: "white", width: '100%'
                    ,"& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                   }}
                />
                <TextField
                  type="text"
                  name="routing_number" // Add name attribute
                  label="Routing Number"
                  variant="outlined"
                  value={bankdetails.routing_number} // Bind value
                  onChange={handleInputChange} // Handle change
                  InputProps={{
                    className: `${style.muiInput}`,
                  }}
                  sx={{ mr: 1, my: 1, borderRadius: "20px", background: "white", width: '100%',"& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        }, }}
                />
                

                <button type="submit" className={style.button} onClick={updateBankDetails}>Update</button>
             
              </div>
    </>
  )
}


const SetTab7 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [bankdetails, setBankDetails] = useState({

    bank_name: "",
    last4: "",
    account_holder_name: "",
    routing_number: "",
  });
 
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState(""); // For Select input
const[account,setAccount]=useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fetchBankDetails = () => {
    FetchbankDetails(cookie)
      .then((result: any) => {
        
        if (result.data && result.data.length > 0) {
          
          const { account, bank_name, last4, account_holder_name, routing_number } = result.data[0];
          setAccount(account)
          setBankDetails({
            
            bank_name,
            last4,
            account_holder_name,
            routing_number,
          });
          
        } else {
          console.error("Bank details not found in the response");
        }
      })
      .catch((err) => {
        console.error("Error fetching bank details:", err);
      });
  };
  //console.log(account)

  useEffect(() => {
    fetchBankDetails();
  }, [cookie]);
  const bankaccount = {
    bank_name: bankdetails.bank_name,
    last4: bankdetails.last4,
    account_type: 'individual', 
    routing_number: bankdetails.routing_number,
  };
 

  

  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Add Payment Detail</div>
        <div className={style.OutterContainer}>
          <EditSharpIcon
            style={{
              position: "absolute",
              right: "2rem",
              top: "1rem",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          />
          <div className={style.Container}>
            <div className={style.BankContainer_Header}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <AccountBalanceRoundedIcon style={{ fontSize: "3rem" }} />
              </div>
              <div>
                <p
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "1.3rem",
                    lineHeight: "1.5rem",
                  }}>
                  {bankdetails.bank_name}
                </p>
                <p style={{ fontSize: "1rem", lineHeight: "1rem" }}>
                  A/c No.- {bankdetails.last4}
                </p>
              </div>
            </div>
            <div className={style.seperator} />
            <div style={{ marginTop: "0.5rem" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <p>Account Holder Name:</p>
                <p>{bankdetails.account_holder_name}</p>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <p>Routing Number:</p>
                <p>{bankdetails.routing_number}</p>
              </div>
            </div>
          </div>
          <div className={style.bankFooter}>
            <VerifiedRoundedIcon
              style={{ color: "#00b12c", fontSize: "2rem", margin: "0.5rem" }}
            />
            Primary bank account for transaction
          </div>
        </div>
        {/* <div className={style.formbutton}>
          <button type="submit">Save</button>
        </div> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <div className={style.thisData}>
                <h6>Update Bank Details</h6>
              </div>
            </Typography>
            <Typography id="modal-modal-description" className={style.typoDesc}>
            <Elements stripe={strp}>
              <UpdateForm/>
             </Elements>
              <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                 Cancel</button>

            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default SetTab7;
