import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchCoacheeSession } from "../../../Api";
import dayjs from "dayjs";
import Pagination from "../../Pagination/Pagination";
const UpcomingCoaching=()=> {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
   const [CoachingData,setCoachingData]=useState<any[]>([])
  
   const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  useEffect(()=>{
    // fetchCoacheeSession(1,'',cookie)
    fetchCoacheeSession(data.page,'',cookie)
    .then((result:any)=>{
      let res = result;
      let x: any = { ...data };
      x = {
        ...x,
        next: res.next,
        prev: res.prev,
        total_page: res.total_page || 1,
        history: [...res.history],
      };
      setCoachingData(result.history)
      setData(x)
      
    
    })
    .catch((error:any)=>{
      //console.log("from Card",error)
    })
  },[cookie,data.page])
  

 
  return (

    <>
    <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
          <div className={styles.Upcoming}>
            <div className={styles.UpcomingP}>
              <p> Upcoming Coachings</p>
            </div>
          </div>


<div className={styles.Recommond}>

{
  CoachingData.length>0?(CoachingData.filter((cdata:any) => dayjs(cdata.date.slice(0, 10)).isSameOrAfter(dayjs())).map((cdata:any)=>(<>
  <div className={styles.coaching}>
    
   <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`} className={styles.coachingImg} />
   <div className={styles.coachingTxt}>
                <p style={{fontSize:'20px',fontWeight:'500'}}>{cdata.coach?.name}</p>
                <div className={styles.coachEmail}>
                  <p style={{color:'#0C5899',marginRight:'20px',fontWeight:'normal'}}> {cdata.coach?.email.slice(0,14)}..</p>
                  <button className={styles.coachbuttonJoin1}> <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">Join</a></button>
                </div>
                <div className={styles.coachtime}>
                  <p style={{color:'gray',fontSize:'15px',fontWeight:'normal'}}>
                    <AccessTimeIcon style={{ fill: "#FF5733" ,marginRight:'5px'}} />
                    {cdata.starttime}

                  </p>
                  <p style={{color:'gray',fontSize:'15px',fontWeight:'normal'}}>
                    {" "}
                    <CalendarMonthIcon style={{ fill: "#FF5733",marginRight:'5px' }} />{dayjs(cdata.date.slice(0,10)).format('DD MMM YYYY')}
                  </p>
                </div>
                </div>
        
                </div>
  </>))):(<>No Any Session selected!</>)
}

</div>
          {/*  Trial form */}
          
        </div>
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>

    </>
  )
}

export default UpcomingCoaching