import { useEffect, useState } from "react";
import { fetchAProgram } from "../../../../Api";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import style from "./programs.module.css";
import Pagination from "../../../Pagination/Pagination";
import dayjs from "dayjs";
import PaidIcon from '@mui/icons-material/Paid';
import { cdn_Link } from "../../../../config";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  setProgramId: (val: (v: any) => any) => void;
  handleSeach: string;

};
const DispProgram = ({ setActivescreen, setProgramId, handleSeach }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [myTimeOut, setMytimeOut] = useState<any>(null);
  const [search, setSearch] = useState(handleSeach);
  const [showProgram, setShowProgram] = useState<any>([]);

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  };
  const HandleProgram = (ptype: any, programId: any) => {

    ptype === 1
      ? (setActivescreen(() => 2,))
      : (setActivescreen(() => 3,));
    ptype === 1
      ? (setProgramId(() => programId,))
      : (setProgramId(() => programId,));

  };

  const fetchProgram = () => {
    fetchAProgram(handleSeach, data.page, cookie)
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
        };
        const sortedPrograms = result.data.history.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setShowProgram(sortedPrograms);
        setData(x);
      })
      .catch((error: any) => {
        console.error("Error fetching programs:", error);
      });
  };

  useEffect(() => {
    fetchProgram();
  }, [data.page]);


  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout = setTimeout(() => {
      fetchProgram()
    }, 700);
    setMytimeOut(timeout);

  }, [handleSeach])




  return (
    <>
      <div className={style.container}>
        <div className={style.programContent}>
          {showProgram.length > 0 ? (
            showProgram
            .sort((a:any, b:any) => dayjs(b.createdAt).isBefore(dayjs(a.createdAt)) ? -1 : 1)
            .map((program: any, index: number) => (
              <>
                <div className={style.DisplayProg}>
                  <div className={style.progContent} onClick={() => HandleProgram(program.program_type, program._id)}>
                    <img
                      src={`${cdn_Link}/${program.programImg}`}
                    />

                    <div className={style.programText}>
                      <h6>{program.name}</h6>
                      <p>{program.description.substring(0, 17)}...</p>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                      <p className={style.programDate}>
                        <CheckCircleIcon className={style.programDateIcon} />
                        {dayjs(program.createdAt).format('DD MMM YYYY')}
                      </p>
                      <p style={{color:'#EF633E'}}><PaidIcon style={{marginRight:'2px'}}/> {program.disCost? program.disCost:program.overallCost}</p>
                      
                      </div>


                    </div>

                  </div>
                </div>

              </>
            ))
          ) : (
            <p>No Program data available</p>
          )}
        </div>
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
    </>
  );
};

export default DispProgram;
