import { useEffect, useState } from "react";
import styles from "./css/Home.module.css";
import { fetchAdminGraph, fetchCoach, fetchContactUs, fetchDashFigure } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CachedIcon from '@mui/icons-material/Cached';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { cdn_Link } from "../../../config";
import { formatNumber } from "../../../utils/helper";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { notifyError } from "../../../Notify";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

type userType = {
  totalCoachees: any;
  totalCoach: any;
  totalUsers: any;
  UserPer: any;
  coachPer: any;
  coacheePer: any;
  newUser:any;
}

const Home = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState<any[]>([]);
  const [coacheData, setCoacheData] = useState<any[]>([]);
  const [contact, setContact] = useState<any[]>([]);
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [data, setData] = useState<userType>({
    totalCoachees: '',
    totalCoach: '',
    totalUsers: '',
    UserPer: '+0',
    coachPer: '+0',
    coacheePer: '+0',
    newUser:0
  });
  const fetchGraph=()=>{
    fetchAdminGraph(cookie)
    .then((res:any)=>{
  //console.log("coach graph",res)
  setSessionData(res.data);
    })
    .catch((Err:any)=>{
      notifyError(Err.response.data.message)
    })
  }
  const fetchFigure = () => {
    fetchDashFigure({ token: cookie }).then((result: any) => {
      const { UserPer, coachPer, coacheePer }:any = result.data;
      const { totalCoachees, totalCoach, totalUsers }:any = result.data?.TotalFigures || {};
      const newUser1:any=result.data.currentMonth?.totalUsers
      setData({
        totalCoachees: totalCoachees || 0,
        totalCoach: totalCoach || 0,
        totalUsers:totalUsers || 0,
        newUser:newUser1||0,
        UserPer: UserPer || "+0",
        coachPer:coachPer || "+0",
        coacheePer:coacheePer || "+0"
      });
    }).catch((error: any) => {});
  }
  const chartData = sessionData.map((item: any) => ({
    name: item.month.slice(0,3), 
    Sessions: item.totalSessions, 
  }));

  useEffect(() => {
    // // role=${filter}&search=${search}&page=${page}
    fetchCoach(cookie, 2,'',1)
      .then((result: any) => {
        setCoachData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });

    fetchCoach(cookie, 1,'',1)
      .then((result: any) => {
        setCoacheData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });

    fetchContactUs(cookie, 1)
      .then((result: any) => {
        setContact(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching contact data:", error);
      });
      fetchGraph();
    fetchFigure();
  }, [cookie]);

  // Prepare data for the bar chart


  return (
    <>
      <div className={styles.container}>
        <div className={styles.setContainer}>
          <div className={styles.leftContent}>
            <div className={styles.TotalUsersContent}>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL COACHEE</p>
                  <img src="/images/Coach/dash-user.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalCoachees)}</h3>
                  <p>
                  <img src={!data.totalCoachees?.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                   
                    <span style={{ color: !data.totalCoachees?.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coachPer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL USERS</p>
                  <img src="/images/Coach/carbon_headphones.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalUsers)}</h3>
                  <p>
                    <img src={!data.totalUsers?.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.totalUsers?.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.UserPer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.TotalUsersContent}>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL COACHES</p>
                  <img src="/images/Coach/ProfileImage.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalCoach)}</h3>
                  <p>
                    <img src={!data.coachPer?.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.coachPer?.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coachPer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>NEW USERS</p>
                  <img src="/images/Coach/profileimg.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.newUser)}</h3>
                  <p>
                    <img src={!data.coacheePer?.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.coacheePer?.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coacheePer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <h5 style={{ color: 'red', padding: '10px' }}>Total Session</h5>
            <ResponsiveContainer width="100%" height={300} >
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name"  interval={1}/>
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Sessions" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
          </div>
        </div>
        
        {/* Users List */}
        <div className={styles.CoachTable}>
          <div className={styles.UserListContent}>
            <h4>Users List </h4>
            <h6 onClick={() => setActiveTab(() => 2)}>View All </h6>
          </div>

          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
            </tr>

            {coachData.length > 0 ? (
              coachData.slice(0, 5).map((coach: any, index: number) => (
                <tr key={index}>
                  <td>
                    {coach.profileImg ? (
                      <img
                        src={`${cdn_Link}/${coach.profileImg}`}
                        className={styles.coachImg}
                      />
                    ) : (
                      <img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg1} />
                    )}
                    {coach.name}
                  </td>
                  <td>{coach.email}</td>
                  <td className={styles.alignCenter}>
                    {coach.account_status === 0 ? (<p className={styles.AccStatus0}><HourglassTopIcon />Unverified</p>) :
                    coach.account_status === 1 ? (<p className={styles.AccStatus1}><CachedIcon />Pending</p>) :
                    coach.account_status === 2 ? (<p className={styles.AccStatus2}><ThumbUpAltIcon />Active</p>) :
                    coach.account_status === 3 ? (<p className={styles.AccStatus3}><BlockIcon />Blocked</p>) :
                    coach.account_status === 4 ? (<p className={styles.AccStatus4}><DeleteIcon />Deleted</p>) :
                    (<p className={styles.AccStatus4}><PersonOffIcon />Not Found!</p>)}
                  </td>
                  <td style={{textAlign:'center'}}>{coach.totalSessions}</td>
                  <td style={{textAlign:'center'}}>{coach.completedSessions}</td>
                </tr>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>

        {/* Coach List */}
        <div className={styles.CoachTable}>
          <div className={styles.UserListContent}>
            <h4>Coach List </h4>
            <h6 onClick={() => setActiveTab(() => 2)}>View All </h6>
          </div>
          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
            </tr>

            {coacheData.length > 0 ? (
              coacheData.slice(0, 5).map((coach: any, index: number) => (
                <tr key={index}>
                  <td>
                    {coach.profileImg ? (
                      <img
                        src={`${cdn_Link}/${coach.profileImg}`}
                        className={styles.coachImg}
                      />
                    ) : (
                      <img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg1} />
                    )}
                    {coach.name}
                  </td>
                  <td>{coach.email}</td>
                  <td className={styles.alignCenter}>
                    {coach.account_status === 0 ? (<p className={styles.AccStatus0}><HourglassTopIcon />Unverified</p>) :
                    coach.account_status === 1 ? (<p className={styles.AccStatus1}><CachedIcon />Pending</p>) :
                    coach.account_status === 2 ? (<p className={styles.AccStatus2}><ThumbUpAltIcon />Active</p>) :
                    coach.account_status === 3 ? (<p className={styles.AccStatus3}><BlockIcon />Blocked</p>) :
                    coach.account_status === 4 ? (<p className={styles.AccStatus4}><DeleteIcon />Deleted</p>) :
                    (<p className={styles.AccStatus4}><PersonOffIcon />Not Found!</p>)}
                  </td>
                  <td style={{textAlign:'center'}}>{coach.totalSessions}</td>
                  <td style={{textAlign:'center'}}>{coach.completedSessions}</td>
                </tr>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>

        {/* Contact Us List */}
        <div className={styles.setContainer}>
          <div className={styles.UserListContent}>
            <h4>Contact Us List </h4>
            <h6 onClick={() => setActiveTab(() => 8)}>View All </h6>
          </div>
        </div>
        <div className={styles.sessiontable}>
          <table>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Message</th>
              <th></th>
              <th></th>
            </tr>

            {contact.length > 0 ? (
              contact.slice(0, 5).map((contact: any, index: number) => (
                <tr key={index}>
                  <td>
                    <img src="/images/svg/Coachee/myCoach.svg" className={styles.ContactUsImg} />
                    {contact.firstName}
                  </td>
                  <td>{contact.email}</td>
                  <td className={styles.alignCenter}>
                    {contact.phoneno}
                  </td>
                  <td>{contact.message.length < 40 ? contact.message : `${contact.message.substring(0, 40)}...`}</td>
                </tr>
              ))
            ) : (
              <p>No contact data available</p>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default Home;
