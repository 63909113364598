import { TextField } from "@mui/material";
import styles from "./setup.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { coachexp, fetchCoachData } from "../../Api";
import { notifyError } from "../../Notify";
import { useFormik } from "formik";
import * as Yup from "yup";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  activeTab:any
};

const CpTab3 = ({ setActiveTab,activeTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
const[loader,setLoader]=useState(false)
  // Initialize Formik
  const[coachData,setCoachData]=useState<any>([])
    useEffect(()=>{
      handleCoachData()
    },[cookie,activeTab])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      yearExp: coachData?.yearExp||"",
      style: coachData?.style||"",
      model:coachData?.model|| "",
      perfect:coachData.perfect|| "",
      challen:coachData?.challenges|| "",
      currentP:coachData?.currentprofession||"",
    },
    validationSchema: Yup.object({
      yearExp: Yup.number()
        .required("Required")
        .positive("Must be a positive number")
        .integer("Must be an integer"),
      style: Yup.string().required("Required"),
      model: Yup.string().required("Required"),
      perfect: Yup.string().required("Required"),
      challen: Yup.string().required("Required"),
      currentP: Yup.string().required("Required"),
    }),
    onSubmit: (values:any) => {
      const challenges = values.challen.split(",").map((value:any) => value.trim());
      const currentProfession = values.currentP.split(",").map((value:any) => value.trim());
      setLoader(true)
      coachexp(
        Number(values.yearExp),
        values.style,
        values.model,
        values.perfect,
        challenges,
        currentProfession,
        cookie
      )
        .then((result: any) => {
          //console.log(result);
          setActiveTab((prev) => prev + 1);
          setLoader(false)
        })
        .catch((error) => {
          notifyError(error.response?.data?.message || "An error occurred");
          //console.log(error);
        });
    },
  });
  const handleCoachData=()=>{
    fetchCoachData(cookie).then((res:any)=>{
      setCoachData(res.data)
      //console.log(res);
      
    })
    .catch((error:any)=>{
      //console.log(error)
    })
  }

  return (
    <div className={styles.mainContainer}>
      <h3>Coaching Experience</h3>
      <br />
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputContent}>
          <div className={styles.LeftInputField}>
            <TextField
              type="number"
              label="Years of Experience"
              variant="outlined"
              name="yearExp"
              
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.yearExp}
              error={formik.touched.yearExp && Boolean(formik.errors.yearExp)}
              // helperText={formik.touched.yearExp && formik.errors.yearExp}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            <TextField
              type="text"
              label="Methodology or model used when coaching"
              variant="outlined"
              name="model"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.model}
              error={formik.touched.model && Boolean(formik.errors.model)}
              // helperText={formik.touched.model && formik.errors.model}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            <TextField
              type="text"
              label="Challenges/adversities faced as a coach"
              variant="outlined"
              name="challen"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.challen}
              error={formik.touched.challen && Boolean(formik.errors.challen)}
              // helperText={formik.touched.challen && formik.errors.challen}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
          </div>
          <div className={styles.RightInputField}>
            <TextField
              type="text"
              label="Coaching style"
              variant="outlined"
              name="style"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.style}
              error={formik.touched.style && Boolean(formik.errors.style)}
              // helperText={formik.touched.style && formik.errors.style}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            <TextField
              type="text"
              label="Perfect Client"
              variant="outlined"
              name="perfect"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.perfect}
              error={formik.touched.perfect && Boolean(formik.errors.perfect)}
              // helperText={formik.touched.perfect && formik.errors.perfect}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
            <TextField
              type="text"
              label="Why stay current in coaching as a profession"
              variant="outlined"
              name="currentP"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentP}
              error={formik.touched.currentP && Boolean(formik.errors.currentP)}
              // helperText={formik.touched.currentP && formik.errors.currentP}
              className={styles.Inputfield}
              InputProps={{
                className: `${styles.muiInput}`,
              }}
              sx={{
                mr: 1,
                my: 1,
                borderRadius: "20px",
                "& .MuiInputBase-input": {
                  width: "100%",
                },
              }}
              required
            />
          </div>
        </div>
        <div className={styles.textcenter1}>
          <button className={styles.button4} type="submit">
          {loader==true? <>Saving ...</>:<>Next</>} 
          </button>
        </div>
        <div className={styles.textcenter1}>
         <p  onClick={() => setActiveTab((prev) => prev - 1)} style={{cursor:'pointer',color:'black'}}>Back</p> 
        </div>
      </form>
    </div>
  );
};

export default CpTab3;
