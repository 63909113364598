import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchCertificates } from "../../../Api";
import { cdn_Link } from "../../../config";

import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Pagination from "../../Pagination/Pagination";
const MyCertificate=()=> {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
   const [data, setData] = useState({
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      history: [],
    });

  const[programDetails,setprogramDetails]=useState<any>([])
  useEffect(()=>{
    fetchCertificates(cookie)
    .then((result:any)=>{
      let res = result.data;
          let x: any = { ...data };
          x = {
            ...x,
            next: res.next,
            prev: res.prev,
            total_page: res.total_page || 1,
            history: [...res.history],
          };
          setData(x);
      setprogramDetails(x.history)
    })
  },[cookie])
  const downloadPDF = async (url:string) => {
    window.open(url, '_blank');
  };
  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };
  return (
    <>
    <div className={styles.dashcontainer}>
      <div style={{margin:'20px'}}>
    <div className={styles.UpcomingP}>
              <p>My Certificates</p>
            </div>
            <div>
              {programDetails.length>0?(programDetails.map((data:any)=>
              (
              <>
              {/* <iframe src={`${cdn_Link}/${data?.certificateLink}`} width={'400px'} height={'300px'}/> */}
            <button onClick={()=>{downloadPDF(`${cdn_Link}/${data?.certificateLink}`)}}>DOWNLOAD</button>
          
             </>
              )

             )):'No certificates Available!'}
            </div>
          </div>
          <div style={{bottom:0,position:'absolute',right:0,margin:'20px'}}>

          
          <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}

        /></div>
          </div>
          
    </>
  )
}

export default MyCertificate