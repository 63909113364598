import TextArea from "antd/es/input/TextArea";
import styles from "./setup.module.css";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { administration, fetchCoachData } from "../../Api";
import { login, setProfileSetup } from "../../redux/userSlice";
import { UserType } from "../../@types/user";
import { notifyError } from "../../Notify";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  activeTab:any
};
const CpTab4 = ({ setActiveTab,  activeTab }: propType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  //console.log("cpTab3 ", user);
  const cookie: any = user?.token;
  const [Mprocess, setMprocess] = useState("");
  const [Wprocess, setWprocess] = useState("");
   const[coachData,setCoachData]=useState<any>([])
    useEffect(()=>{
      handleCoachData()
    },[cookie,activeTab])
    const handleCoachData=()=>{
      fetchCoachData(cookie).then((res:any)=>{
        setCoachData(res.data)
        //console.log(res);
        if (res.data) {
         
          setMprocess(res.data?.Mprocess || "");
          setWprocess(res.data?.Wprocess || "");
        }
      })
      .catch((error:any)=>{
        //console.log(error)
      })
    }
 
  
  const[loader,setLoader]=useState(false)
  let profileSetupValue: boolean;
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoader(true)
    administration(Mprocess, Wprocess, cookie)
      .then((result: any) => {
        //console.log(result.data);
        let x: UserType = {
          ...result.data.user,
          token: result.data.token,
        };
      
        setActiveTab((prev) => prev + 1);

        setLoader(false)
      })

      .catch((error) => {notifyError(error.response?.data?.message)
        //console.log(error);
      });
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <h3>Administration </h3>
        <br />
        <form onSubmit={handleSubmit}>
          <div className={styles.LeftInputField}>
            <TextArea
              id="outlined-basic"
              name="Mprocess"
              placeholder="Process to maintain client confidentiality"
              className={styles.TextAreaField}
              rows={5}
              onChange={(e: any) => setMprocess(e.target.value)}
              value={Mprocess}
              style={{resize:'none'}}
              // required
            />
            <TextArea
              id="outlined-basic"
              placeholder="What process do you use to follow timely contractor required submissions (i.e., invoices, reports…)style"
              className={styles.TextAreaField}
              rows={5}
              name="Wprocess"
              onChange={(e: any) => setWprocess(e.target.value)}
              value={Wprocess}
              style={{resize:'none'}}
              // required
            />
          </div>
          <div className={styles.textcenter1}>
            <button className={styles.button4} type="submit">
            {loader==true ? <>Saving ...</>:<>Next</>}
            </button>
          </div>
          <div className={styles.textcenter1}>
         <p  onClick={() => setActiveTab((prev) => prev - 1)} style={{cursor:'pointer',color:'black'}}>Back</p> 
        </div>
        </form>
      </div>
    </>
  );
};

export default CpTab4;
