import React, { useEffect, useState } from 'react'
import style from "./css/Support.module.css";
import { notifyError, notifySuccess } from "../../../Notify";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { CloseTicket, GetAdminTickets } from '../../../Api';
import styled from "./wrapper/wrapper.module.css";
import styles from "./css/AdminManage.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { Cancel } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Chats from './supportChat/Chats';
const SupportChat = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [ticketId, setTicketId] = useState('');
  const [viewTicket, setviewTicket] = useState<any>([]);
  const [sender, setSender] = useState<any>([]);
  const [open1, setOpen1] = useState(false);
  const [updata, setUpdata] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [activechat, setactivechat] = useState<any>("");
  const getAllTickets=async()=>{
    try {
      const data: any = await GetAdminTickets(cookie);
      if (data?.data) {
        setviewTicket(data.data.history);
      }
    } catch (error) {
      notifyError("Failed to fetch tickets");
    }

  }

  const closeTik = (e: any, ticketId: any) => {
    e.preventDefault();
    setTicketId(ticketId);
    handleOpen1();
  };

  const deleteTicket = (e: any) => {
    e.preventDefault();
    setUpdata(true);
    if (ticketId) {
      CloseTicket(ticketId, cookie)
        .then((res: any) => {
          notifySuccess(res.message);
          handleClose1();
          setactivechat('')
          setUpdata(false);
        })
        .catch((Err: any) => {
          notifyError(Err.response.data.message);
          handleClose1();
          setUpdata(false);
        });
    }
  };

  useEffect(()=>{
    getAllTickets()
  },[cookie,updata])
  return (
    <div className={style.ChatContainer}>
        <div className={style.Chathead}>
          <h4>Ticket</h4>
            </div>
          
        <div className={style.Chatparts}>
          <div className={style.ChatpartsLeft}>
            {viewTicket && viewTicket.length>0?viewTicket.map((ticket:any)=>(<>
              <div className={activechat === ticket.chatId ? style.TicketDesc1 : style.TicketDesc} 
                  key={ticket._id}
                  onClick={()=>{
                    setactivechat(ticket.chatId)
                    setSender(ticket.UserData);

                  }}
                  >
                   <div key={ticket.chatId} style={{padding:'10px'}}>
                      <p style={{color:'black'}}>{ticket.title}</p>
                      <span style={{color:'gray'}}>{ticket.description.slice(0, 16)}</span>
                    </div>
                    <DoNotDisturbAltIcon className={style.doNotDistb} onClick={(e) => closeTik(e, ticket._id)} />          
          
                  </div>
            </>)):'No any Queries!'}
            </div>
            

        <div className={style.ChatpartsRight}>
         
              <Chats activechat={activechat} sender={sender}/>

</div>
        </div>

        <div className={styled.upgradeButton}>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={styled.BoxStyle}>
                <span onClick={handleClose1} className={styled.HandleClose}>
                  <CloseTwoToneIcon className={styled.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  <div className={styled.thisData}>
                    <h5>Close Ticket</h5>
                  </div>
                </Typography>
                <Typography id="modal-modal-description">
                  Are you sure you want to close this ticket? <br />
                  <button className={styles.delete} onClick={deleteTicket}>
                    <DeleteIcon /> Close
                  </button>
                  <button className={styles.DontDelete} onClick={handleClose1}>
                    <Cancel /> Cancel
                  </button>
                </Typography>
              </Box>
            </Modal>
          </div>

      
    </div>
  )
}

export default SupportChat
