import { useSelector } from "react-redux";
import styles from "../complete.module.css";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../@types/user";
import { LoginApi } from "../../../Api";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/userSlice";

const SetupComplete = () => {
  const dispatch=useDispatch()
  const navigator = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  //console.log("pro status", user?.profileSetup);
  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(logout())

    navigator("/");
  };
  return (  
    <>
      <div className={styles.firstdiv}>
        <center>
          <h4> Profile created Succesfully</h4>
        </center>
      </div>
      <center>
        <div className={styles.imagediv}>
          <img src={"/images/svg/Coach/profileclick.svg"} alt="" />
        </div>
      </center>

      <div className={styles.profilediv}>
        <center>
          <h4>
            <b>Your profile is ready!</b>
          </h4>
        </center>
      </div>

      <div>
        <center>
          <form onSubmit={handleClick}>
            <button className={styles.button} type="submit">
              Continue to Login
            </button>
          </form>
        </center>
      </div>
    </>
  );
};

export default SetupComplete;
