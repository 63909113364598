import { useSelector } from "react-redux";
import CoachSideBar from "./SideBar";
import CoacheeSideBar from "../Coachee/wrapper/SideBar";
import AdminSideBar from "../Admin/wrapper/SideBar";
import style from "./wrapper.module.css";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { GetNotification } from "../../../Api";
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  children?: any;
};
const Wrapper = (props: propType) => {
  
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [head, setHead] = useState("");
  const[notification,setNotification]=useState<any>([])
  const [extend, setExtend] = useState<any>(null);

useEffect(()=>{
  GetNotification(cookie)
  .then((res:any)=>{
    //console.log(res)
    setNotification(res.data.history)

  })
},[cookie])
const toggleExpand = (id: any) => {
  setExtend((prevId:any) => (prevId === id ? null : id));
};
  return (
    <>
      <div className={style.container}>
        <div className={style.bkTop}>
          <img
            src="/images/svg/Coach/shapebk.svg"
            className={style.bkTopInner}
          />
        </div>

        <div className={style.foreGround}>
          <div className={style.innerForeGround}>
            <header className={style.navname}>
              <ul>
                {user?.role ==1 && (
                <li onClick={() => props.setActiveTab(13)}>
                <img src="./images/svg/Coachee/chatIconUse.svg" className={style.chatShape}/>
                </li> )}
                {user?.role ==2 && (
                <li onClick={() => props.setActiveTab(13)}>
                <img src="./images/svg/Coachee/chatIconUse.svg" className={style.chatShape}/>
                </li> )}
                <li onClick={handleOpen}>
                 {notification.length>0?<img src="/images/Coach/login/bell.svg" />:<img src="/images/Coach/login/bells.svg" />} 
                </li>
                <div className={style.upgradeButton}>
            
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={style.BoxStyle}>
                
                <Typography
                  id="modal-modal-description"
                  variant="h5"
                  component="h2"
                >
                  <div className={style.typoDesc}>
                    <h6>Notification</h6>
                 {notification.length>0?(notification.map((notify:any)=>(
                  <>
                  <div className={style.notifymsg}>
                    
                  <h6 onClick={()=>toggleExpand(notify._id)}>{notify.title} <ArrowDropDownIcon /></h6>
                  <p>{dayjs(notify.createdAt.slice(0,10)).format('DD MMM YYYY')}</p>
                  {extend==notify._id?
                  <p className={style.notifyDesc}>{notify.body}</p>:''}
                  </div>
                  
                  </>
                 ))):('No notification')}
                  
                  </div>
               
                </Typography>
               
              </Box>
            </Modal>
          </div>
                {user?.role == 2 && (
                  <>
                    {" "}
                    <li onClick={() => props.setActiveTab(10)}>
                      <img src="/images/Coach/login/help.svg" />
                      Help
                    </li>
                  </>
                )}
                {user?.role == 1 && (
                  <>
                    {" "}
                    <li onClick={() => props.setActiveTab(6)}>
                      <img src="/images/Coach/login/help.svg" />
                      Help
                    </li>
                  </>
                )}
                
              </ul>
            </header>

            <div>
              {head != "My Settings" && (
                <div className={style.dashboardheader}>
                  <h1>{head}</h1>
                  {/* <p>hello this is from your {head}</p> */}
                </div>
              )}

              <div className={style.dashboardcontainer}>{props.children}</div>
            </div>

            <footer className={style.footer}>
              Copyright 2023 © DreamSmart Behavioural Solutions™
            </footer>
          </div>
          {user?.role === 0 && (
          <AdminSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
        {user?.role === 1 && (
          <CoachSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
        {user?.role === 2 && (
          <CoacheeSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
        </div>
       
      </div>
    </>
  );
};

export default Wrapper;
