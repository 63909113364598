import styles from "./programs.module.css";
import styled from "../wrapper/wrapper.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import style from "../wrapper/wrapper.module.css";
import TimerIcon from "@mui/icons-material/Timer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";

import { BorderRight, CalendarMonth, Cancel } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CreateSession, DeleteChapter, DeleteProgram, fetchChapter, fetchCoach, fetchPrgId, FetchSessionAdmin, UpdateProgram, UpdateSession } from "../../../../Api";
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography, duration } from '@mui/material';
import TextArea from "antd/es/input/TextArea";
import { notifyError, notifySuccess } from "../../../../Notify";
import AboutUs from '../../../../Landing/AboutUs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { cdn_Link } from "../../../../config";
import dayjs from "dayjs";
import { ClockCircleFilled } from "@ant-design/icons";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  programId: any;
};
interface NewUser {
  pImg: File | null;
  upImg: File | null;
}

const OnlineProgram = ({ setActivescreen, programId }: propType) => {
  const [open1, setOpen1] = useState(false);

  const handleClose = () => setOpen1(false);
  const [open, setOpen] = useState(false);

  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const user = useSelector((state: RootState) => state.user.user);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false)
  const[updata,setUpdata]=useState(false)
  const cookie: any = user?.token;
  const [program, setProgram] = useState<string[]>([]);
  const [pname, setPname] = useState("");
  const [ptype, setPtype] = useState('')
  const [overcost, setOvercost] = useState("");
  const [createdAt, setCreate] = useState("");
  const [desc, setDesc] = useState("");
  const [coachNames, setCoachNames] = useState<string[]>([]);
  const [programImg, setprogramImg] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [title, setTitle] = useState("");
  const [expected, setExpected] = useState("");
  const [duration, setDuration] = useState("");
  const [disCost, setdisCost] = useState<string[]>([]);
  const [coachData, setCoachData] = useState<string[]>([]);
const[coachIds,setCoachIds]=useState<string[]>([])
  const [AassignedCoach, setassignedCoach] = useState<any[]>([]);
  
  const [cId, setCid] = useState('')
  const [upd, setUpd] = useState(false);
  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
    upImg: null
  });
  const [modalData, setModalData] = useState({
    title: "",
    desc: "",
    duration: "",
    createdAt: ""
  });

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const [updateProg, setupdateProg] = useState({
    progName: '',
    progDesc: '',
    progDuration: '',
    progCost: '',
    progDist: '',
  })
  const handleOpen = (e: any, progName: string, progDesc: string, progDuration: any, progCost: any,progDist:any) => {
    e.preventDefault();
    setupdateProg({ progName, progDesc, progDuration, progCost,progDist })
    setOpen1(true)
  };
  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    //console.log("files", file);
    setNewUser({ ...newUser, upImg: file });

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
  const handleOpening = (
    e: any,
    title: any,
    desc: any,
    duration: any,
    cDate: any
  ) => {
    e.preventDefault();
    handleOpen2();
    setModalData({ title, desc, duration, createdAt: cDate })



  };
  const updatehandleUpdate = (e: any, cId: any, title: any,
    desc: any,
    duration: any,
    cDate: any) => {
    e.preventDefault()
    handleOpen3();
    setCid(cId)
    setModalData({ title, desc, duration, createdAt: cDate })
  }
  const handleUpdate = (e: any) => {
    e.preventDefault()

    UpdateSession(cId, programId, modalData.title, modalData.desc, modalData.duration, 1, cookie)
      .then((res: any) => {
        setUpd(true)
        notifySuccess(res.data.message)
        handleClose3()
      })
      .catch((err: any) => {
        notifyError(err.response?.data?.message)
        handleClose3()
      })
    setUpd(false)
  }
  useEffect(() => {
    const ids = coachNames.map((coach: any) => coach._id);
    setCoachIds(ids);
  }, [coachNames]);
  
  const pId = programId;
  useEffect(() => {
    FetchSessionAdmin({ cookie, filter: 1,page:data.page })
    .then((result: any) => {
      let res = result.data;
      let x: any = { ...data };
      x = {
        ...x,
        next: res.next,
        prev: res.prev,
        history: [...res.history],
      };

      setData(x);
    })
    .catch((err) => {});

  }, [cookie,upd]);
 
  const handleSession = (e: any) => {
    e.preventDefault();
    CreateSession(pId, title, expected, duration, cookie)
      .then((result: any) => {
        setUpd((prev) => !prev);
        notifySuccess(result.data.message);
        handleClose();
        setTitle("");
        setExpected("");
        setDuration("");
        handleClose1();
      })

      .catch((error) => {
        //console.log(error);
        setTitle("");
        setExpected("");
        setDuration("");
      });
    // fetchChapter

  };
  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };
  useEffect(() => {
    fetchCoach(cookie, 1, '', 1)
      .then((result: any) => {
        //console.log("Coach data:", result);
        setCoachData(result.data.history);


        //console.log("uid Data ", coachData);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
        notifyError(error.response.data.message);
      });
  }, []);

  const handleAddCoach = (coachId: string) => {
    if (!AassignedCoach.includes(coachId)) {
      setassignedCoach([...AassignedCoach, coachId]);

    }
  };

  const handleRemoveCoach = (coachId: string) => {
    setassignedCoach(AassignedCoach.filter((id) => id !== coachId));
  };

  const handleDeleteProg = (e: any) => {
    e.preventDefault()
    DeleteProgram(programId, cookie)
      .then((res: any) => {
        notifySuccess(res.message || 'program deleted Sucessfully')
        handleClose5()
        setActivescreen(() => 1)
      })
      .catch((err: any) => {
        notifyError(err.response.data.message || 'Program not found !')
        handleClose5()
      })
  }
  const DeleteSessionByID = (e: any) => {

    e.preventDefault()
    DeleteChapter(cId, cookie)
      .then((res: any) => {
        notifySuccess(res.message)
        setUpd(true)
        handleClose4()
      })
      .catch((err: any) => {
        notifyError(err.response?.data.message)
        handleClose4()
      })
    setUpd(false)
  };
  const getDeleteSession = (e: any, cId: any) => {
    e.preventDefault()
    handleOpen4()
    setCid(cId)
  }

  useEffect(() => {
    fetchPrgId(cookie, programId)
      .then((result: any) => {
        //console.log("Sile Data  data:", result);
        setProgram(result.data.history);
        setPname(result.data.name);
        setOvercost(result.data.overallCost);
        setdisCost(result.data.disCost);
        setCreate(result.data.createdAt.substring(0, 10));
        setDesc(result.data.description);
        setCoachNames(result.data.coacheIds);
        setprogramImg(result.data.programImg);
        setPtype(result.data.program_type)
        setDuration(result.data.duration)
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });

  }, [upd,cookie]);
  const convertDaysToMonthsAndDays = () => {
    const days:any=duration;
    const daysInMonth = 30;
    const months = Math.floor(days / daysInMonth); 
    const remainingDays = days % daysInMonth;
    return `${months} month${months !== 1 ? "s" : ""} ${remainingDays} day${remainingDays !== 1 ? "s" : ""}`;
  };
  const assignedCoach = JSON.stringify(AassignedCoach);
  //console.log("both list listing",assignedCoach,coachIds)
  const updateProgramId = (e: any) => {
    e.preventDefault()
    const assignedCoach1 = JSON.stringify(coachIds);
    setUpd((prev) => !prev);
    const formData: any = new FormData();
    formData.append("pId", programId)
    formData.append("ptype", ptype);
    formData.append("name", updateProg.progName||pname);
    formData.append("description", updateProg.progDesc||desc);
    formData.append("overallCost", updateProg.progCost||overcost);
    formData.append("disCost",updateProg.progDist||disCost);
    formData.append("assignedCoach",AassignedCoach.length>0?assignedCoach:assignedCoach1);
    formData.append("programImg",newUser.upImg||programImg);
    formData.append("duration",  updateProg.progDuration||duration);

    UpdateProgram(formData, cookie)
      .then((res: any) => {
        notifySuccess(res.message || "Update Successfully")
        handleClose()
         setUpd((prev) => !prev);
      })
      .catch((err: any) => {
        notifyError(err.response.data.message || "Data Not updated!")
         setUpd((prev) => !prev);
         
      })

  } 

  return (
    <>
      <div className={styles.recorded}>
        <div className={styles.EditLeft}>
          {programImg ? (
            <img
              src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`}
              className={styles.EditData}
            />
          ) : (
            <p className={styles.EditData}>{pname.slice(0, 2)}</p>
          )}

          <div className={styles.EditLeftData}>
            <h5>{pname} </h5>
            <p className={styles.EditLeftDataP1}>
              <span>
                <TimerIcon />
              </span>
              {dayjs(createdAt).format('DD MMM,YYYY')}
            </p>
            <p className={styles.EditLeftDataP2}>
              <span>
                <AttachMoneyIcon />
              </span>
              <del style={{color:'grey',marginRight:'10px'}}>${overcost} </del>
               ${disCost}
            </p>
            {duration && <p className={styles.EditLeftDataP1}>
              <span>
                <CalendarMonthIcon />
              </span>
              {convertDaysToMonthsAndDays()}
            </p>}
          </div>
        </div>
        <div className={styles.EditRightData}>
          <div className={style.addButton}>
            <div className={styles.upgradeButton}>

              <button className={styles.EditRightBtn1} onClick={(e) => { handleOpen(e, pname, desc, duration,overcost,disCost) }}>
                <BorderColorIcon />
                Edit
              </button>
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: "scroll", overflowY: "scroll" }}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    <h3> Edit Program</h3>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                    <div className={style.ProgramProp}>
                      <div className={style.onlinePro}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleProfile}
                          id="fileInput"
                          name="profile"
                        />

                        {!previewImage ? (
                          <>
                            <img
                              src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`}
                              className={style.progProfile}
                            />
                            <div className={style.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={previewImage}
                              alt="Preview"
                              className={style.MainImgPre}
                            />
                            <div className={style.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        )}
                        <form
                          className={style.programForm}>
                          <TextField
                            type="text"
                            id="outlined-basic"
                            label="Program Topic"
                            variant="outlined"
                            name="name"
                            value={updateProg.progName}
                            onChange={(e: any) =>
                              setupdateProg((prevState) => ({
                                ...prevState,
                                progName: e.target.value,
                              }))}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${style.muiInput}`,
                            }}
                            sx={{
                              my: 1,
                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />

                          <TextArea
                            id="outlined-basic"
                            placeholder="Description"
                            name="description"

                            value={updateProg.progDesc}
                            onChange={(e: any) =>
                              setupdateProg((prevState) => ({
                                ...prevState,
                                progDesc: e.target.value,
                              }))}
                            // onChange={(e: any) => setDescription(e.target.value)}

                            rows={5}
                          />
                          <TextField
                            type="text"
                            id="outlined-basic"
                            label="Overall Cost"
                            variant="outlined"
                            name="overallCost"
                            value={updateProg.progCost}
                            onChange={(e: any) =>
                              setupdateProg((prevState) => ({
                                ...prevState,
                                progCost: e.target.value,
                              }))}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,

                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <TextField
                            type="text"
                            id="outlined-basic"
                            label="Program Discount Cost"
                            variant="outlined"
                            name="discost"
                            value={updateProg.progDist}
                            onChange={(e: any) =>
                              setupdateProg((prevState) => ({
                                ...prevState,
                                progDist: e.target.value,
                              }))}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,

                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <TextField
                            type="text"
                            id="outlined-basic"
                            label="Program Duration (in Days)"
                            variant="outlined"
                            name="ProgDuration"
                            value={updateProg.progDuration}
                            onChange={(e: any) =>
                              setupdateProg((prevState) => ({
                                ...prevState,
                                progDuration: e.target.value,
                              }))}
                            className={style.Inputfield}
                            InputProps={{
                              className: `${styles.muiInput}`,
                            }}
                            sx={{
                              my: 1,

                              "& .MuiInputBase-input": {
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "#fff",
                              },
                            }}
                          />
                          <h6>Update Coaches</h6>
                          <div className={styled.AddCoachList}>
                            {coachData.length > 0 ? (
                              coachData.map((coach: any, index: number) => (<>
                                <div className={styled.CoachlstP}>
                                  <p>
                                    <img
                                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                                      className={styled.coachImg}
                                    />
                                    {coach.name} <p className={styled.Coachlstemal}> {coach.email}</p></p>

                                  <p >
                                    {AassignedCoach.includes(coach._id) ? (<CloseTwoToneIcon className={styled.CoachlstIconClose} onClick={() => handleRemoveCoach(coach._id)} />)
                                      : (<AddIcon className={styled.CoachlstIcon} onClick={() => handleAddCoach(coach._id)} />)}
                                  </p>
                                </div>
                              </>
                              ))
                            ) : (
                              <p>No coach data available</p>
                            )}

                          </div>
                          <button className={style.SaveButton} onClick={(e) => updateProgramId(e)}>Update</button>
                          <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                          Cancel</button>
                        </form>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
          <button className={styles.EditRightBtn2} onClick={handleOpen5}>
            <DeleteIcon />
            Delete
          </button>
          <Modal
            open={open5}
            onClose={handleClose5}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <h3> Delete Program</h3>
              </Typography>
              <Typography id="modal-modal-description" className={style.typoDesc}>
                Are you Sure delete Program?<br />
                <button className={styles.EditRightBtn2} onClick={handleDeleteProg}>
                  <DeleteIcon />
                  Delete
                </button>
                <button className={styles.EditRightBtn3} onClick={handleClose5}>
                  <CloseTwoToneIcon />
                  Cancel
                </button>

              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
      <div className={styles.EditDescription}>
        <hr />
        <br />
        <br />
        <h5>Description</h5>

        <p>{desc}</p>
      </div>
      <div>
        <h5>Added Coaches</h5>

        <div className={styles.coachData}>
          {coachNames.map((coach: any, index: any) => (
            
            <div className={styles.coachContainer}>
              
              <img
                src={coach.profileImg ? `${cdn_Link}/${coach.profileImg}`:'/images/Coachee/p1.png'}
                className={styles.coachImg2} style={{borderRadius:'20px'}}
                alt="Coach"
              />

              <div className={styles.coachName}>
                <h6>{coach.name}</h6>
                <p>{coach.email}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div className={styles.recorded}>
        <h5>Total Session</h5>
        {/* <button className={styles.EditRightBtn1} onClick={handleOpen1}>
          <AddBoxSharpIcon /> Add New Session
        </button> */}

        <Modal
          open={open}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose1} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <h3> Create New Session</h3>
            </Typography>
            <Typography id="modal-modal-description" className={style.typoDesc}>
              <div className={style.ProgramProp}>
                <div className={style.onlinePro}>
                  <form className={style.programForm} onSubmit={handleSession}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Session Topic"
                      variant="outlined"
                      name="title"
                      value={title}
                      onChange={(e: any) => setTitle(e.target.value)}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <TextArea
                      id="outlined-basic"
                      placeholder="What are you going to learn"
                      name="description"
                      value={expected}
                      onChange={(e: any) => setExpected(e.target.value)}
                      rows={5}
                    />
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Expected Duration of Session"
                      variant="outlined"
                      name="duration"
                      value={duration}
                      onChange={(e: any) => setDuration(e.target.value)}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${styles.muiInput}`,
                      }}
                      sx={{
                        my: 1,

                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <button className={style.SaveButton}>Save</button>
                    <button  onClick={handleClose1} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                    Cancel</button>
                  </form>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
      <div>
        <br />
        <table>
          <tr>
            <th>COACH NAME</th>
            <th>COACHEE NAME</th>
            <th>SESSION DATE</th>
            <th>SESSION TIME</th>
            <th>SESSION DURATION</th>
          </tr>
          {data.history.length > 0 &&
            data.history
            .filter((elem: any) => elem?.programData?._id === programId)
            .map((elem: any, index: number) => (
              <tr
                className={style.tableTr1}
                key={`${elem?._id}_Sessions_${index}`}>

                {/* <td><img src={`${cdn_Link}/${elem?.programData?.programImg}`} style={{width:'50px',borderRadius:'50%',marginRight:'10px'}}/> {elem?.programData?._id}</td> */}
                
                <td><img src={`${cdn_Link}/${elem?.coachData?.profileImg}`} style={{width:'50px',borderRadius:'50%',marginRight:'10px'}}/>{elem?.coachData?.name}</td>
                <td><img src={`${cdn_Link}/${elem?.coacheeData?.profileImg}`} style={{width:'50px',borderRadius:'50%',marginRight:'10px'}}/>{elem?.coacheeData?.name}</td>
                <td style={{textAlign:'left'}}>
                 <CalendarMonth style={{color:'#ef643ecd',marginRight:'10px',fontSize:'20px'}}/>
                  {dayjs(elem?.startdate).format("DD MMM ")} -{" "}
                  {dayjs(elem?.enddate).format("DD MMM YYYY")}
                </td>
                <td style={{textAlign:'left'}}>
                <ClockCircleFilled style={{color:'#ef643ecd',marginRight:'10px',fontSize:'20px'}}/>
                  {dayjs(elem?.startdate).format("hh:mmA")} -{" "}
                  {dayjs(elem?.enddate).format("hh:mm A")} 
                </td>
                <td><ClockCircleFilled style={{color:'#ef643ecd',marginRight:'10px',fontSize:'20px'}}/> {elem?.duration}{" "}
                  Min</td>
              </tr>
            ))}

         
        </table>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose2} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <h3> About Program</h3>
            </Typography>
            <Typography
              id="modal-modal-description"
              className={style.typoDesc}
            >
              <div className={style.sessionModalInfo}>

                <h6>Session Title</h6>  <p>{modalData.title}</p>
              </div>
              <div className={style.sessionModalInfo}>

                <h6>Session Duration</h6>  <p>{modalData.duration} Hrs</p>
              </div>
              <div className={style.sessionModalInfo}>

                <h6>Session Description</h6>  <p className={modalData.desc.length > 50 ? style.sessionModalInfoDesc1 : style.sessionModalInfoDesc}>{modalData.desc}</p>
              </div>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose3} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <h3> Edit Session</h3>
            </Typography>
            <Typography id="modal-modal-description" className={style.typoDesc}>
              <div className={style.ProgramProp}>
                <div className={style.onlinePro}>
                  <form className={style.programForm} onSubmit={handleUpdate}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Session Topic"
                      variant="outlined"
                      name="title"
                      value={modalData.title}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          title: e.target.value,
                        }))}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <TextArea
                      id="outlined-basic"
                      placeholder="What are you going to learn"
                      name="description"
                      value={modalData.desc}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          desc: e.target.value,
                        }))}
                      rows={5}
                    />
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Expected Duration of Session"
                      variant="outlined"
                      name="duration"
                      value={modalData.duration}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          duration: e.target.value,
                        }))}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${styles.muiInput}`,
                      }}
                      sx={{
                        my: 1,

                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <button className={style.SaveButton}>Update</button>
                    <button  onClick={handleClose3} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                    Cancel</button>
                  </form>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
        <div className={style.upgradeButton}>

          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose4} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={style.thisData}>
                  <h5> Delete Session</h5>

                </div>

              </Typography>
              <Typography id="modal-modal-description"
              >

                Are you sure Delete Session? <br />
                <button className={styles.delete} onClick={(e: any) => DeleteSessionByID(e)}><DeleteIcon /> Delete </button>
                <button className={styles.DontDelete} onClick={handleClose4}><Cancel /> Cancel </button>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default OnlineProgram;
