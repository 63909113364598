import style from "../../../../styles/setting.module.css";

type propType = {
  activesetTab: number;
  setActivesetTab: (val: number) => void;
  children: any;
};
function MainSettings({ activesetTab, setActivesetTab, children }: propType) {
  const handleClick = (index: number) => {
    setActivesetTab(index);
  };
  return (
    <>
      <div className={style.setcontainer}>
        <header className={style.setheader}>Settings</header>
        <hr />
        <div className={style.mainsetcontainer}>
          <div className={style.setleftcontainer}>
            <div className={style.setsidebar}>
              <ul>
                <li onClick={() => handleClick(1)} className={style.setli}>
                  <div className={`${activesetTab === 1 && style.setliactive}`}>
                    {activesetTab === 1 ? (
                      <img src="/images/svg/Coach/setting/colorauthen.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/authenticate.svg" />
                    )}
                   <span> Authentication</span>
                  </div>
                </li>
                <li onClick={() => handleClick(2)} className={style.setli}>
                  <div className={`${activesetTab === 2 && style.setliactive}`}>
                    {activesetTab === 2 ? (
                      <img src="/images/svg/Coach/setting/colorpayment.svg" />
                    ) : (
                      <img src="/images/svg/Coach/setting/payment.svg" />
                    )}
                   <span> Payment Details</span>
                  </div>
                </li>
                <li onClick={() => handleClick(3)} className={style.setli}>
                  <div className={`${activesetTab === 3 && style.setliactive}`}>
                    {activesetTab === 3 ? (
                      <img src="/images/svg/Coachee/notification.svg" />
                    ) : (
                      <img src="/images/svg/Coachee/wnotification.svg" />
                    )}
                    <span>Notification</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={style.setrightcontainer}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default MainSettings;
