import style from "./chatCoachee.module.css";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect, useRef, useState } from "react";
import {
  FetchProgramsCoach,
  GetAllMsg,
  GetCoachMsg,
  SendMsg,
} from "../../../../Api";
import dayjs from "dayjs";
import { notifyError } from "../../../../Notify";

const ChatCoachee = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState<any>([]);
  const [chatId, setChatId] = useState("");
  const [msg, setMsg] = useState("");
  const [coachId, setCoachId] = useState("");
  const [chatData, setChatData] = useState<any>({});
  const [coacheeId, setCoacheeId] = useState("");
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const messagesEndRef = useRef<any>(null);

  useEffect(() => {
    FetchProgramsCoach({ cookie: cookie, page: 1, search: '' }).then((res: any) => {
      setCoachData(res.data.history);
    });
  }, [cookie]);

  const GetMsg = (nextPage: any) => {
    if (chatId) {
      GetAllMsg(cookie, chatId, nextPage).then((res: any) => {
        const newHistory = Array.isArray(res.history) ? res.history : [];
        setChatData((prevChatData: any) => {
          const previousHistory = prevChatData.history || [];
          return {
            ...res,
            history: [...newHistory, ...previousHistory],  
          };
        });
        setPageLimit(res.total_page);
      });
    }
  };

  useEffect(() => {
    GetMsg(page);
  }, [cookie, chatId, page]);

  const getCoachId = (to: any) => {
    setChatData({});
    setPage(1);
    setCoachId(to);
    GetCoachMsg(to, cookie).then((res: any) => {
      setChatId(res.data._id);
    });
  };

  useEffect(() => {
    if (coacheeId) {
      getCoachId(coacheeId);
    }
  }, [cookie, coacheeId]);

  const loadMoreMessages = () => {
    if (page < pageLimit) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatData]);

  const SendMsgs = () => {
    if (msg.trim()) {
      SendMsg(cookie, chatId, msg).then((res: any) => {
        const newMessage = res.data;
        setChatData((prevChatData: any) => ({
          ...prevChatData,
          history: [...prevChatData.history, newMessage], // Append the new message
        }));
        setMsg(""); // Clear message input

        // Scroll to the bottom after sending the message
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    } else {
      notifyError("Enter Message!");
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      SendMsgs();
    }
  };

  const uniqueCoacheeIds = new Set();

  return (
    <div className={style.ChatContainer}>
      <div className={style.Chatparts}>
        <div className={style.ChatpartsLeft}>
          <div className={style.Chathead}>
            <h4>Coachee List</h4>
          </div>
          <div className={style.DisplaycoachContent}>
            {coachData.length > 0 ? (
              coachData.map((cdata: any) =>
                cdata.applied.length > 0
                  ? cdata.applied.map((coach: any) => {
                      const cId = coach.coacheeDetails?._id;
                      if (cId && !uniqueCoacheeIds.has(cId)) {
                        uniqueCoacheeIds.add(cId);
                        return (
                          <div key={cId} className={style.TicketDesc} onClick={() => setCoacheeId(cId)}>
                            <img
                              src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.coacheeDetails?.profileImg}`}
                              className={style.CoachImg}
                            />
                            <div className={style.CoachDesc}>
                              <p>{coach.coacheeDetails?.name}</p>
                              <span>{coach.coacheeDetails?.email}</span>
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })
                  : null
              )
            ) : (
              <div>No coachee data available.</div>
            )}
          </div>
        </div>

        <div className={style.ChatpartsRight}>
          <div className={style.chapterHead}>
            {coachData.length > 0 && coachData.map((coach: any) =>
              coach.coacheeDetails?._id === coachId ? (
                <div key={coach.coacheeDetails?._id} className={style.chattinghead}>
                  <img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.coacheeDetails?.profileImg}`}
                    className={style.CoachImg}
                  />
                  <div className={style.CoachDesc}>
                    <p>{coach.coacheeDetails?.name}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>

          <div className={`${chatData.history && chatData.history.length < 7 ? style.DisplayMsgContent1 : style.DisplayMsgContent}`}>
            {chatData.history ? (
              chatData.history.length > 0 ? (
                chatData.history.map((msg: any, index: any) => (
                  <div key={index} className={style.DisplayMsg}>
                    {msg?.from === user?._id ? (
                      <div className={style.msgTextLeft}>
                        <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>
                        <p>{msg?.text}</p>
                      </div>
                    ) : (
                      <div className={style.msgTextRight}>
                        <p>{msg?.text}</p>
                        <span>{dayjs(msg.createdAt).format("hh:mm A")}</span>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No messages yet.</div>
              )
            ) : (
              <div></div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {page < pageLimit && (
            <button onClick={loadMoreMessages} className={style.LoadMoreButton}>
              Load More
            </button>
          )}

{chatId ? ( <div className={style.SendMsgButton}>
           <input
              type="text"
              value={msg}
              onChange={(e: any) => setMsg(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button onClick={SendMsgs}>
              <SendIcon />
            </button>
          </div>):'Start Chat with Coachee'}
        </div>
      </div>
    </div>
  );
};

export default ChatCoachee;

