import { useSelector } from "react-redux";
import {
  FetchAllSessions,
  fetchCoachGraph,
  fetchDashFigureCoach,
  FetchSessions,
  FetchUcoveringSessions,
} from "../../../Api";
import styles from "../../../styles/dashboardHome.module.css";
import { useEffect, useState } from "react";
import { cdn_Link } from "../../../config";
import dayjs from "dayjs";
import { formatNumber } from "../../../utils/helper";
import { notifyError } from "../../../Notify";
import { RootState } from "../../../redux/store";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type propType = {
  setActiveTab: (val: number) => void;
};
type userType = {
  totalCoachees: any,
  totalHours: any,
  totalSessions: any,
  clientPer:string, hourPer:string, sessionPer:string
}

const Home = (props: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [Upcomingdata, setUpcomingData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [userdata, setUserData] = useState<userType>({
    totalCoachees: 0,
    totalHours: 0,
    totalSessions: 0,
    clientPer:"+0", 
    hourPer:"+0", 
    sessionPer:"+0"
  });
  const fetchSessions = async () => {
    FetchUcoveringSessions({ filter: 1, item_per_page: 4, cookie: cookie })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setData(x);
      })
      .catch((err) => {});
  };
const fetchGraph=()=>{
  fetchCoachGraph(cookie)
  .then((res:any)=>{
//console.log("coach graph",res)
setSessionData(res.data);
  })
  .catch((Err:any)=>{
    notifyError(Err.response.data.message)
  })
}
  const fetchUpcomingSessions = async () => {
    FetchAllSessions({ filter: 2, search: "", cookie: cookie })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...Upcomingdata };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setUpcomingData(x);
      })
      .catch((err) =>{});
  };

  // const HandleDurationShow = ({ sessions }: { sessions: any[] }) => {
  //   const totalDuration = sessions.reduce((accumulator, element) => {
  //     return accumulator + (element?.duration || 0);
  //   }, 0);

  //   const hours = Math.floor(totalDuration / 60); // Get whole hours
  //   const minutes = totalDuration % 60; // Get remaining minutes

  //   return `${hours} Hr ${minutes} Min`;
  // };

  // const HandleFirstSession = ({ sessions }: { sessions: any[] }) => {
  //   if (!sessions.length) return null;
  //   const firstSession = sessions.sort(
  //     (a, b) =>
  //       new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
  //   )[0];

  //   return firstSession?.startdate || null;
  // };
  const fetchFigure = () => {
    fetchDashFigureCoach({ token: cookie }).then((result: any) => {
      // result?.data[0].currentMonth;
      const { clientPer, hourPer, sessionPer }:any = result?.data;
      const { totalCoachees, totalHours, totalSessions }:any =result?.data[0].currentMonth;
      setUserData(
        
        {
          totalCoachees:totalCoachees||0,
          totalHours:totalHours || 0,
          totalSessions:totalSessions||0, 
          clientPer: clientPer || "0",  hourPer: hourPer || "0", sessionPer: sessionPer || "0"}
      )
    
    })
  }
  useEffect(() => {
    fetchSessions();
    fetchUpcomingSessions();
    fetchFigure();
    fetchGraph();
  }, []);
  const chartData = sessionData.map((item: any) => ({
    name: item.month.slice(0,3), 
    Sessions: item.totalSessions, 
  }));

  return (
    <>
      <div className={styles.mainContainer}>
        {/* first */}
        <div className={styles.firstContainer}>
          <div className={styles.menuesBox}>
            <div className={styles.item}>
              {/* box 1 */}
              <div>
                <p style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  CLIENT
                </p>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/dash-user.png"
                  alt=""
                />
              </div>
              <div>
                <h5>{parseFloat(userdata?.totalCoachees)}</h5>
              </div>
              <div className={styles.corno} >
              <div className={styles.corner}>
              <img src={parseFloat(userdata.clientPer) >= 0 ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} alt="Trend" />
              <p style={{color:'black'}}>
                {formatNumber(userdata.clientPer)}%
              </p>
              </div>
              <p style={{ fontSize: "9px",marginTop:'-15px' }}>Since last Month</p>
              
              </div>
            </div>
            <div className={styles.item}>
              {/* box 2 */}
              <div>
                <p style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  SESSIONS
                </p>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  src="/images/Coach/carbon_headphones.png"
                  alt=""
                />
              </div>
              <div>
              <h5>{parseFloat(userdata.totalSessions)}</h5>
              </div>
              <div>
                <img src={parseFloat(userdata.sessionPer) >= 0 ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} alt="" />
                <span> {formatNumber(userdata.sessionPer)}%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            <div className={styles.item}>
              {/* box 3 */}
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  COACHING HOURS
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ width: "24px" }}
                  src="/images/Coach/hour-glass.png"
                  alt=""
                />
              </div>
              <div>
                <h5>{formatNumber(userdata?.totalHours || 0)}</h5>
              </div>
              <div>
                <img src={parseFloat(userdata.hourPer) >= 0 ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} alt="" /> 
                 <span> {formatNumber(userdata.hourPer)}%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div>
            {/* <div className={styles.item}>
           
              <div>
                <span style={{ color: "#9FA4AF", fontSize: "12px" }}>
                  EARNINGS THIS MONTH
                </span>
              </div>
              <div>
                <img
                  className={styles.logoImage}
                  style={{ height: "33px" }}
                  src="/images/Coach/money-bag.png"
                  alt=""
                />
              </div>
              <div>
                <h5>$1250</h5>
              </div>
              <div className={styles.corner}>
                <img src="/images/Coach/down-arrow-red.png" alt="" />
                <span> -6.34%</span>
                <p style={{ fontSize: "9px" }}>Since last Month</p>
              </div>
            </div> */}
          </div>
          <div className={styles.ChartGraph}>
            
            <div className={styles.headLine}>
              <h5>Total Sessions</h5>
             
            </div>
            <div>
              <div className={styles.selectBar}>
              <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="1 1 "  />
          <XAxis dataKey="name" interval={1}/>
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Sessions" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
              </div>
              <div>
                
              </div>
              
            </div>
          </div>
        </div>

        {/* second */}
        <div className={styles.middleContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" />{" "}
                Uncovering Session
              </h3>
            </div>
            <div>
              <h4
                onClick={() => {
                  props.setActiveTab(8);
                }}
                style={{
                  color: "#1C74BC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}>
                View All
              </h4>
            </div>
          </div>
          <div >
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  {/* <th>session type</th> */}
                  <th>Session Date</th>
                  <th>View Report</th>
                  <th>Join Session</th>
                </tr>
              </thead>
              <tbody>
                {data.history?.slice(0, 4).map((elem: any, ind: number) => (
                  <tr
                    key={`Coachee_${(1000 + Math.floor(Math.random() * 9000))
                      .toString()
                      .substring(0, 4)}_${elem._id?.coachee}`}>
                    <td className={styles.img_text}>
                      <div>
                        <img
                          src={
                            elem?.CoacheeDetails?.profileImg
                              ? `${cdn_Link}/${elem?.CoacheeDetails?.profileImg}`
                              : "/images/Coach/pic-placeholder.png"
                          }
                          alt="User Img"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div className={styles.names}>
                        {elem?.CoacheeDetails?.name}
                      </div>
                    </td>
                    <td>
                      {elem?.appointmentDate
                        ? dayjs(elem?.appointmentDate).format(
                            " MMM DD, YYYY hh:mm A"
                          )
                        : "-"}
                    </td>
                    <td>View Report</td>
                    <td > <a href={elem?.meetingLink} target="_blank"  className={styles.handleJoinButton} > Join Now</a> </td>
                    {/* <td>{HandleDurationShow({ sessions: elem?.sessions })}</td> */}
                    {/* <td>{elem?.sessions.length}</td> */}
                    {/* <td>
                      {dayjs(
                        HandleFirstSession({ sessions: elem?.sessions })
                      ).format("MMM DD,YYYY hh:mm A")}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* third */}
        <div className={styles.thirdContainer}>
          <div className={styles.headerLine}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                {" "}
                <img src="/images/Coach/rectangle-shape.png" alt="" /> Upcoming
                Appointments
              </h3>
            </div>
            <div>
              <h4
                style={{
                  color: "#1C74BC",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setActiveTab(2);
                }}>
                View All
              </h4>
            </div>
          </div>
          <div className={styles.tables}>
            <table>
              <thead>
                <tr>
                  <th>client name</th>
                  <th>session type</th>
                  <th>duration</th>
                  {/* <th>earnings</th> */}
                  <th>date & time</th>
                  <th>Join Meeting</th>
                 
                </tr>
              </thead>
              <tbody>
                {Upcomingdata.history
                  ?.slice(0, 4)
                  .map((elem: any, ind: number) => (
                    <tr key={`${ind}_${elem._id?.coachee}`}>
                      <td className={styles.img_text}>
                        <div>
                          <img
                            src={
                              elem?.CoacheeDetails?.profileImg
                                ? `${cdn_Link}/${elem?.CoacheeDetails?.profileImg}`
                                : "/images/Coach/pic-placeholder.png"
                            }
                            alt="User Img"
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div className={styles.names}>
                          {" "}
                          {elem?.CoacheeDetails?.name}
                        </div>
                      </td>
                      <td>Type here</td>
                      <td>{elem?.duration} Min</td>
                      <td>
                        {" "}
                        {dayjs(elem?.startdate).format("MMM DD,YYYY hh:mm A")}
                      </td>
                      <td><button className={styles.handleJoinButton} onClick={() => window.open(elem?.join_url, "_blank")}> Join Now </button> </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
