import { TextField } from "@mui/material";
import style from "../../../../styles/setting.module.css";
import { coachexpPatch, FetchCoachInfo } from "../../../../Api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";

const SetTab3 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
const[updata,setUdpate]=useState(false)
  const [data, setData] = useState({
    yearExp: "",
    style: "",
    model: "",
    perfect: "",
    challenges: "",
    currentprofession: "",
  });

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const {
          yearExp,
          style,
          model,
          perfect,
          challenges,
          currentprofession,
        }: any = result.data;
        setData({
          yearExp,
          style,
          model,
          perfect,
          challenges,
          currentprofession,
        });
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);

  // Update handler for form fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setUdpate(true)
   
    const challenges = typeof data.challenges === 'string' 
    ? data.challenges.split(',').map((value: string) => value.trim()) 
    : [];
  
  const profession = typeof data.currentprofession === 'string' 
    ? data.currentprofession.split(',').map((value: string) => value.trim()) 
    : [];
   
    coachexpPatch(data.yearExp,data.style,data.model,data.perfect,challenges,profession,cookie)
    .then((res:any)=>{
      notifySuccess("Data updated Successfully")
      setUdpate(false)
    })
    .catch((err:any)=>{
      notifyError(err.response.data.message)
      setUdpate(false)
    })


    

  }
  
  return (
    <>
      <div className={style.tabcontainer}>
        <header className={style.tabheader}>Coaching Experience</header>

        <form onSubmit={handleSubmit} >
          <div className={style.tabformcontent}>
          <div className={style.leftabcontent}>
            <div className={style.inputtab3}>
              <TextField
                type="text"
                id="outlined-basic"
                label="Years of Experience"
                name="yearExp"
                variant="outlined"
                value={data.yearExp}
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1, borderRadius: "20px" }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.model}
                name="model" 
                label="Methodology or model used when coaching"
                variant="outlined"
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                value={data.challenges}
                id="outlined-basic"
                name="challenges" 
                label="Challenges/adversities faced as a coach"
                variant="outlined"
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
          <div className={style.rightabcontent}>
            <div className={style.inputtab2}>
              <TextField
                type="text"
                id="outlined-basic"
                value={data.style}
                name="style" 
                label="Coaching style"
                variant="outlined"
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.perfect}
                name="perfect" 
                label="Perfect Client"
                variant="outlined"
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />

              <TextField
                type="text"
                id="outlined-basic"
                value={data.currentprofession}
                name="currentprofession" 
                label="Way to stay current in coaching as a profession"
                variant="outlined"
                onChange={handleChange} 
                InputProps={{
                  className: `${style.muiInput}`,
                }}
                sx={{ mr: 1, my: 1 }}
              />
            </div>
          </div>
          </div>
          <div className={style.formbutton}>
          <button disabled={updata} type="submit">
            {updata==true?'Loading...':'Add'}
          </button>
        </div>
        </form>
        
    
    </div>
    </>
  );
};

export default SetTab3;
