import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import AuthWrapper from "../components/layouts/authWrapper";
import { useEffect, useState } from "react";
import Main from "../components/forget-password/Main";
import Verify from "../components/verify";
import ResetPassword from "../components/reset-password";
import PasswordResetsuccess from "../components/reset-password/success";
import { useLocation } from "react-router-dom";

const ForgotPassword = () => {
  const location = useLocation();
  const[head,setHead]=useState('')
  let initialMailSubmitted = false;
  let initialVerified = false;
  let initialResetSuccess = false;
  let type = '';
//console.log('path name',location.pathname)

  if (location.pathname == "/verify") {
    type = "Create Account";
    initialMailSubmitted=true;
  }

  if (location.pathname == "/forget-password") {
    type = "forget";
  }
  useEffect(()=>{
    if (location.pathname == "/verify") {
      setHead("Verify Your Account")
    }
  
    if (location.pathname == "/forget-password") {
      setHead("Forget Your Password?")
    }
  },[])

  const [mailSubmitted, setMailsubmitted] = useState(initialMailSubmitted);
  const [verified, setVefified] = useState(initialVerified);
  const [resetsuccess, setResetSuccess] = useState(initialResetSuccess);
  //console.log("this is condition",mailSubmitted ,verified ,resetsuccess)
  return (
    <>
      <AuthWrapper
        heading={head}
        message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      >
        <>
          {!mailSubmitted && !verified && !resetsuccess && (
            <Main setMailsubmitted={setMailsubmitted} />
          )}
          {mailSubmitted && !verified && !resetsuccess && (
            <Verify setVefified={setVefified} type={type} />
          )}
          {mailSubmitted && verified && !resetsuccess && (
            <ResetPassword setResetSuccess={setResetSuccess} />
          )}
          {mailSubmitted && verified && resetsuccess && (
            <PasswordResetsuccess />
          )}
        </>
      </AuthWrapper>
    </>
  );
};

export default ForgotPassword;
