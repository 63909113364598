import { useEffect, useState } from "react";
import AsTab1 from "./all-session/AS-Tab1";
import AsTab2 from "./all-session/AS-Tab2";
import AsTab3 from "./all-session/AS-Tab3";
import AsTab4 from "./all-session/AS-Tab4";
import Style from "../../../../src/styles/session.module.css";
import { FetchSessions } from "../../../Api";
import { useSelector } from "react-redux";
import Pagination from "../../Pagination/Pagination";

const Session = () => {
  const user = useSelector((state: any) => state.user.user.token);
  const [activeTab, setActiveTab] = useState(1);
  let filter = activeTab;
  const [myTimeOut, setMytimeOut] = useState();
  const [search, setSearch] = useState("");
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchSessions = async () => {
    FetchSessions({ filter, search, cookie: user, page: data.page })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };

        setData(x);
      })
      .catch((err) => {});
  };

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
  };

  const decreasePage = () => {
    setData({ ...data, page: data.page - 1 });
  };

  const IncreasePage = () => {
    setData({ ...data, page: data.page + 1 });
  };

  useEffect(() => {
    fetchSessions();
  }, [filter]);

  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout: any = setTimeout(() => {
      setData({ ...data, page: 1 });
      fetchSessions();
    }, 700);
    setMytimeOut(timeout);
  }, [search]);

  return (
    <>
      <div className={Style.Artist}>
        {/* {Tab Container} */}
        <ul className={Style.tabCnt1}>
          <li
            className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
            onClick={() => {
              setActiveTab(1);
              setData({ ...data, page: 1 });
            }}>
            Upcoming Sessions
          </li>
          <li
            className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
            onClick={() => {
              setActiveTab(2);
              setData({ ...data, page: 1 });
            }}>
            Completed Sessions
          </li>
          <li
            className={`${Style.tab1} ${activeTab === 3 && Style.tabActive1}`}
            onClick={() => {
              setActiveTab(3);
              setData({ ...data, page: 1 });
            }}>
            Manage Requests
          </li>
        </ul>
        {activeTab === 1 && (
          <AsTab1
            setActiveTab={setActiveTab}
            data={data}
            handleSearch={handleSearch}
            search={search}
          />
        )}
        {activeTab === 2 && (
          <AsTab2
            setActiveTab={setActiveTab}
            data={data}
            handleSearch={handleSearch}
            search={search}
          />
        )}
        {activeTab === 3 && <AsTab4 setActiveTab={setActiveTab} />}
        <Pagination
          next={data.next}
          page={data.page}
          prev={data.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={data.total_page}
        />
      </div>
    </>
  );
};

export default Session;
