import style from "./chat.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useRef } from "react";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError } from "../../../../Notify";
import dayjs from "dayjs";
import { GetTickets, InitilizeTicket } from "../../../../Api";
import ChattingPage from "./ChattingPage";
import Chats from "../../Admin/supportChat/Chats";


const Chat = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [messages, setmessages] = useState<any>([]);
  const [viewTicket, setviewTicket] = useState<any>([]);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [isTrue, setIsTrue] = useState(false);
  const [issue, setIssue] = useState("");
  const [description, setdescription] = useState("");
  const[activeChat,setActiveChat]=useState('')
 const [updata, setUpdata] = useState(false);
 const RaiseTicket = async () => {
  const trimmedIssue = issue.trim();
  const trimmedDescription = description.trim();

  if (!trimmedIssue || trimmedIssue.length <= 3) {
    setIsTrue(false);
    notifyError("Missing Issue");
    return;
  }
  if (!trimmedDescription || trimmedDescription.length <= 3) {
    setIsTrue(false);
    notifyError("Missing Ticket Description");
    return;
  }

  try {
    setIsTrue(true); 

    await InitilizeTicket(cookie, description, issue);
    setOpen1(false);
    setUpdata(true);  
    setIssue("");
    setdescription("");
  } catch (error: any) {
    notifyError(error?.data?.response?.message || "Error creating ticket");
    setUpdata(false);
  }
};

 
  const FetchAllTickets = async () => {
    try {
      const data: any = await GetTickets(cookie);
      if (!data.data) {
        //console.log("No Tickets");
      } else {
        setviewTicket(data.data);
        console.log("Tickets Set", data.data);
      }
    } catch (error) {
      //console.log("Error At Fetching Tickets", error);
    }
  };

  
  useEffect(() => {
    FetchAllTickets();
  }, [activeChat,updata]);

  return (
    <div className={style.ChatContainer}>
      <div className={style.Chatparts}>
      <div className={style.ChatpartsLeft}>
          <div className={style.Chathead}>
            <h4>Ticket</h4>
            <button onClick={handleOpen}>
              <AddIcon />
              New
            </button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Create Ticket
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <form>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Ticket Description"
                      variant="outlined"
                      value={description}
                      onChange={(e: any) => setdescription(e.target.value)}
                      className={style.Inputfield}
                      required
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                    <textarea
                      placeholder="Describe Your Issue"
                      className={style.InputTextfield}
                      rows={6}
                      value={issue}
                      onChange={(e: any) => setIssue(e.target.value)}
                      required
                      style={{ resize: "none" }}
                    ></textarea>
                    <p className={style.TypoButton} onClick={RaiseTicket}>
                      Create New Ticket
                    </p>
                  </form>
                  <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                   Cancel</button>
                </Typography>
              </Box>
            </Modal>
            </div>
            <div className={style.ChatpartsDisplmsg}>
            {viewTicket && viewTicket.length > 0 ? (
              viewTicket.map((ticket: any) => (
                <div
                key={ticket._id}
                  onClick={() =>{
                    setActiveChat(ticket.chatId)
                  }}
                  className={activeChat === ticket.chatId ? style.TicketDesc1 : style.TicketDesc}
                  style={{padding:'15px'}}
                >
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                  <p style={{marginBottom:'-4px',fontWeight:'500'}}>{ticket.title.slice(0, 16)}</p>
                  <span>{dayjs(ticket.createdAt).format('hh:mm A')}</span>

                  </div>
                  <span>{ticket.description.slice(0, 16)}</span>
                </div>
              ))
            ) : (
              <></>
            )}
          

          </div>

         
</div>
<div className={style.ChatpartsRight}>
         <Chats activechat={activeChat} sender=""/>
          </div>
    </div>
    </div>
  )
}

export default Chat
