import { useSelector } from "react-redux";
import styles from "./group.module.css";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { fetchCoachGroup } from "../../../../Api";
import { notifyError } from "../../../../Notify";
import Style from "../../../../../src/styles/group.module.css";
import GroupDetails from "./GroupDetails";
import Pagination from "../../../Pagination/Pagination";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};
const GeTab1 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[ acTab,setacTab]=useState(0)
  const[groupId,setGroupId]=useState('')
  const [myTimeOut, setMytimeOut] = useState<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState("");
  const [group, setGroup] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const decreasePage = () => {
    setGroup({ ...group, page: group.page - 1 });
  };

  const IncreasePage = () => {
    setGroup({ ...group, page: group.page + 1 });
  };

  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    if (myTimeOut) clearTimeout(myTimeOut);
    setMytimeOut(
      setTimeout(() => {
        fetchGroups();
      }, 500)
    );
  };
const fetchGroups=()=>{
  fetchCoachGroup(cookie,group.page,search)
  .then((result: any) => {
    let res = result.data;
    let x: any = { ...group };
    x = {
      ...x,
      next: res.next,
      prev: res.prev,
      history: [...res.history],
    };

    setGroup(x);
  })
  .catch((error: any) => {
    notifyError(error);
  });
}


  useEffect(() => {
    fetchGroups()
  }, [cookie,search,group.page]);

  return (
    <>
    {acTab== 0 && (
    <div className={styles.maincontainer}>
      <div className={styles.search}>
        <input
          className={styles.box1}
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="which Group are you looking for"
          style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
        />
      </div>
      <div className={Style.maindiv} style={{ overflowX: "auto" }}>
        <table className={Style.curvedbordertable}>
          <tr>
            <th>GROUP TOPIC </th>
            <th>TOTAL SESSION</th>
            <th>SESSION COMPLETED</th>
            <th>DATE </th>
            <th>TOTAL USERS</th>
            <th></th>
          </tr>

          {group.history.length > 0 ? (
            group.history.map((gp: any, index: number) => (
              <>
                <tr className={Style.tableTr1}>
                  <td className={Style.groupInfo}>
                    <span className={Style.groupIcon}>
                      {gp.groupTopic.slice(0, 2).toString().toUpperCase()}
                    </span>
                    {gp.groupTopic}
                  </td>
                  <td>Total Session</td>
                  <td>Completed Session</td>
                  <td>{gp.createdAt.slice(0, 10)}</td>
                  <td>{gp.coacheeIds.length}</td>
                  <td>
                    <img
                      src="/images/svg/Admin/viewIcon.svg"
                      onClick={() =>{ setGroupId(gp._id); setacTab(1)}}
                    />
                  </td>
                </tr>
              </>
            ))
          ) : (
            <p>No Group data available</p>
          )}
        </table>
      </div>
      <Pagination
          next={group.next}
          page={group.page}
          prev={group.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={group.total_page}
        />
    </div>
    )}
    {acTab== 1 && (<GroupDetails setacTab={setacTab} groupId={groupId}/>)}
    </>
  );
};

export default GeTab1;
