import { TextField } from "@mui/material";
import style from "./settings.module.css";
import styles from "../wrapper/wrapper.module.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UpdateAdmin } from "../../../../Api";
import { notifyError, notifySuccess } from "../../../../Notify";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

interface NewUser {
  pImg: File | null;
}

const SetTab1 = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
 const[upload,setUploading]=useState(false)
  const str: any = user?.name;
  const abc = str.split(" ");
  //console.log("user Profile",user?.profileImg)
  const formik:any = useFormik({
    initialValues: {
      firstName: abc[0],
      lastName: abc[1] || '', 
      email: user?.email || '',
      contactNumber: '', 
      pImg: user?.profileImg,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      contactNumber: Yup.string().matches(/^\d{10}$/, "Must be a valid 10-digit contact number").optional(),
    }),
    onSubmit: async (values) => {
      const formData:any=new FormData();
      formData.append("firstName",values.firstName)
      formData.append("lastName",values.lastName)
      formData.append("email",values.email)
      // formData.append("phoneno",values.contactNumber)
      formData.append("profile",values.pImg)
      setUploading(true)
      UpdateAdmin(formData,cookie)
      .then((res:any)=>{
        notifySuccess(res.data?.message || "Admin Data updated !")
        setUploading(false)
      })
      .catch((err:any)=>{
        notifyError(err.response?.data.message)
        setUploading(false)
        
      })
     
    },
  });

  const [previewImage, setPreviewImage] = useState("");

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    formik.setFieldValue("pImg", file); 

    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };

  return (
    <>
      <div className={style.changpassContent}>
        <div className={styles.ProgramProp}>
          <div className={style.onlineImg}>
            <div className={styles.onlinePro}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleProfile}
                id="fileInput"
                name="profile"
              />
              {!previewImage ? (
                <>
                  <img
                    src={user?.profileImg?`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`:"/images/Coach/shape-2x.png"}
                    className={styles.progProfile}
                    alt="Default Profile"
                  />
                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                      alt="Upload Profile"
                    />
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={previewImage}
                    alt="Preview"
                    className={styles.MainImgPre}
                  />
                  <div className={styles.IconImg}>
                    <img
                      src="/images/svg/Coach/group-8291.svg"
                      onClick={handleProfileClick}
                      alt="Upload Profile"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={style.textInputContent}>
            <div className={style.textInputleft}>
              <TextField
                type="text"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="firstName"
                className={style.Inputfield}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  },
                }}
              />
              <TextField
                type="email"
                label="Email Address"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                className={style.Inputfield}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </div>
            <div className={style.textInputright}>
              <TextField
                type="text"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="lastName"
                className={style.Inputfield}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  },
                }}
              />
              {/* <TextField
                type="text"
                label="Contact Number"
                variant="outlined"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="contactNumber"
                className={style.Inputfield}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                InputProps={{
                  className: `${styles.muiInput}`,
                }}
                sx={{
                  my: 1,
                  "& .MuiInputBase-input": {
                    width: "100%",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  },
                }}
              /> */}
            </div>
          </div>
          <div className={style.SaveBtnConte}>
            <button
             type="submit"
             onClick={formik.handleSubmit}
              className={styles.SaveButton}
              disabled={formik.isSubmitting}
            >
              {upload==true ?<>Loading...</>:<>Update</>}
              
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetTab1;
