import { useState } from "react";
import styles from "./reset.module.css";
import { ResetPass } from "../../Api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { notifyError } from "../../Notify";
import * as Yup from "yup";
type propType = {
  setResetSuccess: (val: boolean) => void;
};

const ResetPassword = ({ setResetSuccess }: propType) => {
  const user: any = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [newPassword, setnewPassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
        const result: any = await ResetPass(newPassword, cPassword, cookie);
        //console.log("Password reset successful:", result);
        setResetSuccess(true);
    } catch (error: any) {
       
        console.error("Error resetting password:", error);
        notifyError(error.response?.data?.message || "An error occurred while resetting the password.");
    }
};
  const cookie: any = user?.token;
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[@$!%*?&]/, "Password must contain at least one special character"),
    cPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref('newPassword')], "Passwords must match"),
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.InputText1}>
          <input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={(e: any) => setnewPassword(e.target.value)}
            placeholder="New Password"
          />
        </div>
        <div className={styles.InputText1}>
          <input
            type="password"
            name="cPassword"
            value={cPassword}
            onChange={(e: any) => setcPassword(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>
        <div>
          <button className={styles.btn} type="submit">
            Reset Password
          </button>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
