
import styles from "./css/Home.module.css";
import { fetchContactUs } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import style from "./wrapper/wrapper.module.css";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";

import { Box, Button, Modal } from "@mui/material";
import Pagination from "../../Pagination/Pagination";
const ContactUsList=()=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[data,setData]=useState<any[]>([]);
    const[msg,setMsg]=useState("");
    const [open1, setOpen1] = useState(false);
    const handleOpen = () => setOpen1(true);
    const handleClose = () => setOpen1(false);
    const[tPage,setTpage]=useState(0);
    const[page,setPage]=useState(1);
    const [pagedata, setPageData] = useState({
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      history: [],
    });
    useEffect(() => {
      const fetchData = async () => {
        try {
          const result:any = await fetchContactUs(cookie, pagedata.page);
          let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page || 1,
          history: [...res.history],
        };
          //console.log('ContactUs data:', result);
          setTpage(result.data.total_page);
          setPageData(x)
          setData(x.history);
        } catch (error) {
          console.error('Error fetching ContactUs data:', error);
        }
      };
  
      fetchData();
    }, [cookie, page]);
    
    const decreasePage = () => {
      setPageData({ ...pagedata, page: pagedata.page - 1 });
    };
  
    const IncreasePage = () => {
      setPageData({ ...pagedata, page: pagedata.page + 1 });
    };
return (
    <div className={styles.container}>

       <h3>Contact Us Info</h3>
       <table> 
       
       <tr>
       <th>Name</th>
       <th>Email</th>
       <th>Contact Number</th>
       <th>Message</th>
      
       </tr>
      
      {data.length>0? 
      (
      data.map((contact: any, index: number) => (<>
    
      <tr className={styles.ContactUsData}>
      
      <td > <img src="/images/svg/Coachee/myCoach.svg" className={styles.ContactUsImg} /> {contact.firstName} {contact.lastName}</td>
     <td>{contact.email}</td>
     <td className={styles.ContactUsIcons}>{contact.phoneno}</td>
     <td>{contact.message.length>=30?(
      <>{contact.message.substring(0, 40)}..<br/>
      <p onClick={() => { handleOpen(); setMsg(contact.message); }} className={styles.ContactUsBtn} >
             
              Read More..
              
              </p>
     </>):(<>
      
{contact.message.substring(0, 40)}..
     </>)}
     
     </td>
       </tr>
     
      </>)))
      :(<><p>No Contact Us Data found!</p></>)}
          
       </table>
      
       <Pagination
          next={pagedata.next}
          page={pagedata.page}
          prev={pagedata.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={pagedata.total_page}
        />
      



       {/*                     No entry */}
       <div className="addButton">
            <div className={style.upgradeButton}>
            
              <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
              >
                <Box className={style.BoxStyle}>
                  <span onClick={handleClose} className={style.HandleClose}>
                    <CloseTwoToneIcon className={style.HandleCloseIcon} />
                  </span>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    <div className={styles.thisData}>
                
                    <h6><SendIcon/>Message</h6>
                    </div>
                 
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className={style.typoDesc}
                  >
                   <div className={styles.thisMsg}>
                   <p> {msg}</p>
                   </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
            
          </div>
    </div>

)
}
export default ContactUsList;