import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  createOffProgram,
  fetchCard,
  fetchCoachByCoachee,
  fetchCoacheeProgramByID,
} from "../../../../Api";
import style from "./showPro.module.css";
import Modal from "@mui/material/Modal";
import EmailIcon from "@mui/icons-material/Email";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import styles from "../CSS/dashboardHome.module.css";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { notifyError, notifySuccess } from "../../../../Notify";
import dayjs from "dayjs";
import configuration from "../../../../config/configuration";
import FastRewindIcon from '@mui/icons-material/FastRewind';
type propType = {
  progId: any;
  setActiveTab: (val: (v: number) => number) => void;
};
const stripepromise = loadStripe(
  configuration.stripePublicKey
);

const PayOutMode = ({ progId,setActiveTab }: propType) => {
  const stripe = useStripe();
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [proImg, setProgImg] = useState("");
  const [pdate, setPdate] = useState("");
  const [desc, setDesc] = useState("");
  const [pname, setPname] = useState("");
  const [progType, setProgType] = useState();
  const [overallCost, setoverallCost] = useState("");
  const [discost, setdisCost] = useState("");
  const [cardId, setCardId] = useState("");
  const [cards, setCards] = useState<any[]>([]);
  const [activeTab, setActiveTabb] = useState(0);
  const [coachData, setCoachData] = useState<any[]>([]);
  useEffect(() => {
    fetchCoacheeProgramByID(cookie, progId).then((res: any) => {
      
      setProgImg(res.data.programImg);
      setPname(res.data.name);
      setPdate(res.data.createdAt);
      setoverallCost(res.data.overallCost);
      setDesc(res.data.description);
      setCoachData(res.data.coacheIds);
      setProgType(res.data.program_type);
      setdisCost(res.data.disCost);
    });
  }, [cookie, progId]);
  
  useEffect(() => {
    fetchCard(cookie)
    .then((result: any) => {
      setCards(result.data);
      //console.log("Card details", result);
    })
    .catch((error: any) => {
      //console.log("from Card", error);
    });
  }, [cookie]);
  
  const handleCardVal = (event: any) => {
    setCardId(event.target.value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const appType = "2";
    //  progId
    // coachIdMatch
    //  createOnProgram
    // cardId
    // pId: any,cardId:any,appType:any, cookie: string
    createOffProgram(progId, cardId, appType, cookie)
    .then(async (res: any) => {
      const { error, paymentIntent }: any = await stripe!.confirmCardPayment(
        res.data.clientSecret,
        {
          payment_method: cardId,
        }
      );
      if (paymentIntent && paymentIntent.status === "succeeded") {
        notifySuccess("Payment Successful");
        setActiveTab(()=>0)
        handleClose();
      }
      if (error && error.payment_intent.status === "succeeded") {
        notifySuccess("Payment Successful");
        handleClose();
      } else if (error) {
        //console.log(error);
        handleClose();
      }
    })
    .catch((err: any) => {
      notifyError(err.response.data.message);
    });
  };

  return (
    <>
      {activeTab === 0 && (
        <div className={style.Allcontent}>
          <FastRewindIcon style={{cursor:'pointer',color:'#0C5899'}} onClick={()=>setActiveTab(()=>0)}/>
          <div className={style.ProgContent}>
            <div className={style.ProgContentLeft}>
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`}
                className={style.ProgImg}
              />
              <div>
                <h6 style={{fontSize:'20px',fontWeight:'500',marginTop:'20px'}}>{pname}</h6>
                <p style={{color:'#0C5899'}}><AccessAlarmTwoToneIcon/> {dayjs(pdate).format('DD MMM,YYYY hh:mm A')}</p>
                
                <p style={{color:'#EF633E'}}> <MonetizationOnIcon/> {discost?<> <del style={{color:'gray'}}> {overallCost}</del> {discost}</>:overallCost}</p>
                <p></p>
              </div>
            </div>
            <div className={style.ProgContentRight}>
              <button onClick={handleOpen}>Enroll Now</button>
              <div className={styles.upgradeButton}>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box className={styles.BoxStyle}>
                    <span onClick={handleClose} className={styles.HandleClose}>
                      <CloseTwoToneIcon className={styles.HandleCloseIcon} />
                    </span>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h2">
                      {progType === 2 && <>Make Payment</>}
                      {progType === 1 && <>Enroll Live Class</>}
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      className={styles.typoDesc}>
                      {progType === 1 && (
                        <>
                          <h6>Select Coach</h6>
                          <div className={style.coachDataModal}>
                            {coachData.length > 0 ? (
                              coachData.map((coach: any) => (
                                <>
                                  <div className={style.ModalContent}>
                                    <img
                                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                                      className={style.ModalcoachingImg}
                                    />
                                    <h6>{coach.name}</h6>
                                    <p>
                                      <EmailIcon />{coach.email}
                                    </p>
                                  </div>
                                </>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                          <h6> Select Availiability Slot</h6>
                          <div className={style.ProgContentRight}>
                            <button>Next</button>
                          </div>
                        </>
                      )}
                      {progType === 2 && (
                        <>
                          <div className={style.enrolledRecord}>
                            <img
                              src={`https://dxe2g9i1k0e2c.cloudfront.net/${proImg}`}
                              className={style.enrolledRecordImg}
                            />
                            <div className={style.enrolledRecordData}>
                              <h6>{pname}</h6>
                              <p>{pdate.slice(0, 10)}</p>
                              <p>$ {overallCost}</p>
                            </div>
                          </div>
                          <div className={style.enrolledRecordPrice}>
                            <h4>Total Payment </h4>
                            <h6> $ {overallCost}</h6>
                          </div>
                          <Select
                            onChange={handleCardVal}
                            className={styles.cardDesignSelect}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Select Card">
                            {cards && cards.length > 0 ? (
                              cards.map((cinfo) => (
                                <MenuItem
                                  key={cinfo.id}
                                  value={cinfo.id}
                                  className={styles.MenuItems}>
                                  <div className={styles.MenuItems}>
                                    <p>
                                      <CreditCardIcon /> John Doe
                                    </p>
                                    <p className={styles.MenuItemsIC}>
                                      <FormatListNumberedIcon
                                        className={styles.MenuItemsIC}
                                      />{" "}
                                      .... .... .... {cinfo.last4}
                                    </p>
                                  </div>
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                <p className={styles.dataColors}>
                                  Please Add Cards.. Go To Settings!
                                </p>
                              </MenuItem>
                            )}
                          </Select>
                          <button
                            className={style.enrolledRecordBtn}
                            type="submit"
                            onClick={handleSubmit}>
                            Pay Now
                          </button>
                        </>
                      )}
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <hr />
          <div className={style.ProgDescr}>
            <div className={style.ProgramDesc}>
              <h6> Description </h6>
              <p>{desc}</p>
             
            </div>
            <div className={style.ProgEnroll}>
              <div className={styles.DashForRecom}>
                <div className={styles.Recommond}>
                  {coachData.length > 0 ? (
                    coachData.map((cdata: any) => (
                      <>
                        <div className={styles.coaching}>
                          <img
                            src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.profileImg}`}
                            className={styles.coachingImg}
                          />
                          <div className={styles.coachingTxt}>
                            <p>{cdata.name}</p>
                            <div className={styles.Description}>
                              <p> {cdata.email}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     
    </>
  );
};

function ShowProgram({ progId ,setActiveTab}: propType) {
  return (
    <Elements stripe={stripepromise}>
      <PayOutMode progId={progId} setActiveTab={setActiveTab}/>
    </Elements>
  );
}
export default ShowProgram;
