import { useSelector } from "react-redux";
import style from "./profiledata.module.css";
import styles from "../../CSS/dashboardHome.module.css";
import styled from "./../../../Admin/wrapper/wrapper.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { RootState } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, TextField, Typography } from "@mui/material";
import { fetchCoacheeDetails, UpdateCoacheePersonalInfo } from "../../../../../Api";
import { notifyError, notifySuccess } from "../../../../../Notify";
import * as Yup from 'yup';
import { useFormik } from "formik";
interface NewUser {
  pImg: File | null;
}
type propType={
  setUpdata:any;
}
const UserProfile = ({setUpdata}:propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [newUser, setNewUser] = useState<NewUser>({
    pImg: null,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required('First Name is required')
    .max(20, 'First name  must be at most 20 Character'),
    lname: Yup.string().required('Last Name is required')
    .max(20, 'Last Name must be at most 20 Character'),
    email: Yup.string()
    .email('Invalid email')
    .max(30, 'Email Address must be at most 30 Character')
    .matches(/^[^0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/, 'Email must not start with a number ')
    .required('Email is required'),
    mono: Yup.string()
     .matches(/^\d+$/, 'Contact Number must only contain numbers')
     .min(10, 'Contact Number must be at least 10 digits')
     .max(12, 'Contact Number must be at most 12 digits')
    .required('Contact Number is required'),
    address: Yup.string()
    .required('Address is required')
    .max(50, 'Address must be at most 50 Character')
    .matches(/^[^,]+,[^,]+,[^,]+,[^,]+$/, ` Address must street name, city, state, country add Comma(,),`)
    .test('Address must street name, city, state, country ', (value) => {
      const parts = value ? value.split(',') : [];
      return parts.length === 4;
    }),
  });

 

  const handleProfileClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleProfile = (e: any) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, pImg: file });
    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
  };
  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      mono: '',
      address: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { fname, lname, mono } = values;
      const splitAddress = values.address.split(',').map(part => part.trim());
     const address = JSON.stringify({streetName:splitAddress[0],city:splitAddress[1],state:splitAddress[2],country:splitAddress[3]});
     const profile:any =newUser.pImg;
     const formData = new FormData();
     formData.append("firstName",fname)
     formData.append('lastName',lname)
     formData.append("mono",mono)
     formData.append("location",address)
     formData.append("profile",profile)
     try {
        await UpdateCoacheePersonalInfo(formData, cookie);
        notifySuccess('Profile updated successfully');
        setUpdata(true)
        handleClose(); 
      } catch (err: any) {
        notifyError(err.response.data.message);
      }
    },
  });
  const fetchDetailsOfCoachee=()=>{
    fetchCoacheeDetails(cookie)
      .then((res: any) => {
        const userData = res.data;
        //console.log(userData)
        formik.setValues({
          fname: userData.name || userData?.uid?.name.split(" ")[0],
          lname: userData.name || userData?.uid?.name.split(" ")[1],
          email: userData.email || userData?.uid?.email,
          mono: userData.mono,
          address: `${userData?.location?.streetName},${userData?.location?.city},${userData?.location?.state},${userData?.location?.country}`,
        });
      });
  }
  useEffect(() => {
    fetchDetailsOfCoachee()
  }, [cookie]);
//console.log(formik.values.address)
  return (
    <>
      <div className={style.UserProfile}>
        <h6>Personal Info <EditOutlinedIcon className={style.editIcon} onClick={handleOpen} /></h6>
        <div className={style.UserDataheadContent}>
          <div className={style.UserDatahead}>
            <p>First Name</p>
            <p>Last Name</p>
            <p>Email</p>
            <p>Contact Number</p>
            <p>Address</p>
            <p>City</p>
          </div>
          <div className={style.UserData}>
            <p>{formik.values.fname}</p>
            <p>{formik.values.lname}</p>
            <p>{formik.values.email}</p>
            <p>{formik.values.mono}</p>
            <p>{formik.values.address}</p>
            <p>{formik.values.address.split(',')[1]}</p>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.BoxStyle}>
          <span onClick={handleClose} className={styles.HandleClose}>
            <CloseTwoToneIcon className={styles.HandleCloseIcon} />
          </span>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Update Your Profile
          </Typography>
          <Typography id="modal-modal-description" className={styles.typoDesc}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styled.ProgramProp}>
                <div className={styled.onlinePro}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleProfile}
                    id="fileInput"
                    name="profile"
                  />
                  
                  {!previewImage ? (
                    <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${user?.profileImg}`} className={styled.progProfile} />
                  ) : (
                    <img src={previewImage} alt="Preview" className={styled.MainImgPre} />
                  )}
                  <div className={styled.IconImg} onClick={handleProfileClick}>
                    <img src="/images/svg/Coach/group-8291.svg" />
                  </div>
                </div>
                <div className={styles.DisplaytextField}>
                  <TextField
                    type="text"
                    label="First Name"
                    variant="outlined"
                    name="fname"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fname && Boolean(formik.errors.fname)}
                    helperText={formik.touched.fname && formik.errors.fname}
                    className={styles.Inputfield}
                    style={{width:'100%',margin:'10px'}}
                    inputProps={{ maxLength: 30 }}
                    sx={{ my: 1 , "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    },}}
                  />
                  <TextField
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    name="lname"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lname && Boolean(formik.errors.lname)}
                    helperText={formik.touched.lname && formik.errors.lname}
                    className={styles.Inputfield}
                    style={{width:'100%',margin:'10px'}}
                    sx={{ my: 1, "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    }, }}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div className={styles.DisplaytextField}>
                  <TextField
                    type="text"
                    label="Email Address"
                    variant="outlined"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    className={styles.Inputfield}
                    inputProps={{ maxLength: 80 }}
                    style={{width:'100%',margin:'10px'}}
                    sx={{ my: 1, "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    }, }}
                  />
                  <TextField
                    type="text"
                    label="Contact Number"
                    variant="outlined"
                    inputProps={{ maxLength: 15 }}
                    name="mono"
                    value={formik.values.mono}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mono && Boolean(formik.errors.mono)}
                    helperText={formik.touched.mono && formik.errors.mono}
                    className={styles.Inputfield}
                    style={{width:'100%',margin:'10px'}}
                    sx={{ my: 1, "& .MuiInputLabel-root": {
                      backgroundColor: "#fff",
                    }, }}
                  />
                </div>
                <TextField
                  type="text"
                  label="Address (i.e., StreetName, City, State, Country)"
                  variant="outlined"
                  name="address"
                  inputProps={{ maxLength: 150 }}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  className={styles.Inputfield}
                  style={{width:'100%',margin:'10px'}}
                  sx={{ my: 1, width: 'calc(10ch)', "& .MuiInputLabel-root": {
                    backgroundColor: "#fff",
                  }, }}
                />
              </div>
              <button className={styled.SaveButton} type="submit">
                Update
              </button>
              <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
              Cancel</button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default UserProfile;
