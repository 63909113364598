import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AddComment, fetchCoacheeEnrollProgramDetails, fetchCoacheeProgramByID, fetchCoacheeSession } from "../../../../Api";
import styles from "./showPro.module.css"
import { Box, Modal, TextField, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import styled from "../CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import dayjs from "dayjs";
import ShowRecordProgram from "./ShowRecordProgram";
import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import { notifyError, notifySuccess } from "../../../../Notify";
type proptype={
    progId:any,
    program_type:any,
    setActiveTab:(val: (v: number) => number) => void,
}
const EnrollProg=({progId,program_type,setActiveTab}:proptype)=>{
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [programDetails,setprogramDetails]=useState<any>()
    const[chapterData,setChapterData]=useState<any[]>([])
    const[activeTabs,setActiveTabs]=useState(1)
    const [CoachingData,setCoachingData]=useState<any[]>([]);
    const[comment,setComment]=useState('')
    const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
useEffect(()=>{
    fetchCoacheeProgramByID(cookie,progId)
    .then((res:any)=>{
        //console.log("programInfo",res)
        setprogramDetails(res.data)
       
    })

},[cookie])
useEffect(()=>{
  fetchCoacheeSession(1,'',cookie)
  .then((result:any)=>{
   
    
    setCoachingData(result.history)
    
  })
  .catch((error:any)=>{
    //console.log("from Card",error)
  })
},[cookie])
useEffect(()=>{
    fetchCoacheeEnrollProgramDetails(cookie,progId)
    .then((res:any)=>{
        //console.log("program ChapterInfo",res)
        setChapterData(res.data.history)
       
    })

},[cookie])
const handleComment=(e:any)=>{
  e.preventDefault();
  AddComment(progId,comment,cookie)
  .then((res:any)=>{
    notifySuccess(res.message || "Comment Added Successfully")
    handleClose1()
  })
  .catch((err:any)=>{
    notifyError(err.response.data.message)
    handleClose1()
  })
}
  // useEffect(()=>{
  //   fetchCoachprogramByID(coachId,cookie)
  //   .then((res:any)=>{
  
  //     //console.log("data of program By coach",res.data)
  //     // setProgramData(res.data.history)
  //   })
  // },[cookie])
    return(<>
   
    {/* {progId}  */}
    <div className={styles.Allcontent}>
    <div className={styles.ProgContent}>
          <div className={styles.ProgContentLeft}>
            <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${programDetails?.programImg}`} className={styles.ProgImg} />
            <div>
              <h6>{programDetails?.name}</h6>
              <p>{dayjs(programDetails?.createdAt.slice(0, 10)).format('DD MMM YYYY')}</p>
            
              <p></p>
            </div>
          </div>
          <div className={styles.ProgContentRight}>
            {program_type==1 && (
              <div className={styles.ProgContentRightContent} style={{marginRight:'10px'}}><button onClick={()=>setActiveTab(()=>0)}>
                Back To Main
            </button>
            <button className={styles.ProgContentRightBtn} onClick={handleOpen1}> Add Comment</button>
            <div className={styled.upgradeButton}>
            
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll',overflowY: 'scroll'}}
            >
              <Box className={styled.BoxStyle}>
                <span onClick={handleClose1} className={styled.HandleClose}>
                  <CloseTwoToneIcon className={styled.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <div className={styled.thisData}>
                  <h5> Add Comment</h5>
                  
                  </div>
               
                </Typography>
                <Typography   id="modal-modal-description"  className={styled.typoDesc}
                 >
                  <TextArea placeholder="Add comment" rows={5} style={{resize:'none'}} value={comment}
                   onChange={(e)=>setComment(e.target.value)}>

                  </TextArea>
                  <div className={styles.ProgContentRight}>
                  <button onClick={(e)=>handleComment(e)}>
                    Add Comment

                  </button>
                  </div>
                    


                </Typography>
              </Box>
            </Modal>  
          </div>
            </div>)}
            {program_type==2 && (<>
             {activeTabs==1 && (<><button onClick={()=>setActiveTabs(2)}>Continue Learning </button></>)} 
             {activeTabs ==2 && (<><button onClick={()=>setActiveTabs(1)}>Back To Main </button></>)}
             </>)}
            
            </div>
          </div>


          <div className={styles.ProgDescr}>
          <div className={styles.ProgramDesc}>
            <br/>
            <br/>
          <h6> Description </h6>
          <p>{programDetails?.description}</p>
            </div>
            </div>
{program_type==1 && (<h6>Selected Coach </h6>)}
  {program_type==1 &&(
   programDetails?.coacheIds?.length >0?(programDetails?.coacheIds?.map((details:any)=>(
    <div className={styled.coaching} key={details.pId} style={{maxHeight:"200px"}}>
      <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${details.profileImg}`}
                className={styled.coachingImg}
              />
               <div className={styled.coachingTxt}>
               <p>{details.name}</p>
                <div className={styled.coachEmail}>
                  {/* <span>{details.email.slice(0, 14)}..</span> */}
                  </div>
                  </div>

      </div>
   ))):''
  )
}
<br/>
            <br/>

{activeTabs==1 && (<>
    <h5>Upcoming Session</h5>
   {program_type==1 &&(<>
   
    <div className={styled.Recommond}>

    {
  CoachingData.length > 0 ? (
    CoachingData.some(cdata => cdata.pId === progId) ? (
      CoachingData.map((cdata: any) => (
        <>
          {cdata.pId === progId && (
            <div className={styled.coaching} key={cdata.pId}>
              <img
                src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`}
                className={styled.coachingImg}
              />
              <div className={styled.coachingTxt}>
                <p>{cdata.coach?.name}</p>
                <div className={styled.coachEmail}>
                  <span>{cdata.coach?.email.slice(0, 14)}..</span>
                  <Button className={styled.coachButtonJoin1}>
                    <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">Join</a>
                  </Button>
                </div>
                <div className={styled.coachtime}>
                  <span>
                    <AccessTimeIcon style={{ fill: "#FF5733" }} />
                    {cdata.starttime}
                  </span>
                  <span>
                    <CalendarMonthIcon style={{ fill: "#FF5733" }} />
                    {dayjs(cdata.date.slice(0, 10)).format('DD MMM YYYY')}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ))
    ) : (
      <p>No appointment scheduled</p> 
    )
  ) : (
    <p>No Any Session selected!</p>
  )
}
</div>
   </>
)}

{program_type==2 &&(<>
<table>
    <tr>
        <th>SESSION TOPIC</th>
        <th>WHAT ARE WE GOING TO LEARN</th>
        {/* <th>EXPECTED DURATION</th> */}
        <th>CREATED DATE</th>
        <th>STATUS</th>

    </tr>
  
          {chapterData.length>0?(chapterData.map((chapter:any)=>(
 <tr>
<td className={styles.chapterHead}><p className={styles.chapterHeadIcon}>{(chapter.title.split(" ").length>1?(chapter.title.split(" ").map((word:any) => word.charAt(0)).join("").substring(0,2).toUpperCase() ):(chapter.title.slice(0,2).toUpperCase()))}  </p> <p>{chapter.title}</p></td>
<td>{chapter.expected.slice(0,30)}</td>
<td>{dayjs(chapter.createdAt.slice(0,10)).format('DD MMM YYYY')}</td>
{/* <td>{chapter.duration} Hrs</td> */}


<td>{chapter.isCompleted==false ? (<p onClick={()=>setActiveTabs(2)}  className={styles.pendingState}><WatchLaterIcon/> Continue</p>):(<p onClick={()=>setActiveTabs(2)}  className={styles.completeState}><CheckCircleIcon/> Completed</p>)}</td>


</tr>
      ))):(<>No Chapter Created !</>)}
  
</table>
</>)}
</>)}
{activeTabs ==2 && <ShowRecordProgram progId={progId}/>}


    </div>

    
    </>)
}
export default EnrollProg;      