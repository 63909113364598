import style from "../styles/login.module.css";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleLoginapi, LoginApi } from "../Api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useEffect, useState } from "react";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { login } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { UserType } from "../@types/user";
import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import { notifySuccess, notifyError } from "../Notify";
import { useGoogleLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import * as yup from "yup";
import { FacebookLoginButton } from "react-social-login-buttons";

import CustomButton from "../components/Button/CustomButton";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginBtn, setloginBtn] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [role, setRole] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleRoleClick = (selectedRole: any) => {
    setRole((prevRole) => (prevRole === selectedRole ? 0 : selectedRole));
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email is in Invalid Format")
      .required("Email is Missing"),
    password: yup.string().required("Password is required"),
    // .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
    // .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
    // .matches(/(?=.*\d)/, 'Password must include at least one number')
    // .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
    // .min(8, 'Password must be at least 8 characters long')
    // .max(50, 'Password must be at most 50 characters long'),
  });

  const rememberMeFun = (user: any) => {
    rememberMe
      ? localStorage.setItem("user", JSON.stringify(user))
      : sessionStorage.setItem("user", JSON.stringify(user));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setloginBtn(true);
      const { email, password } = values;
      try {
        const result: any = await LoginApi(email, password, role);
        let user: UserType = {
          ...result.data.user,
          token: result.data.token,
        };
        rememberMeFun(user);
        dispatch(login(user));
        notifySuccess("Login Successfully");
        setloginBtn(false);
      } catch (error: any) {
        setloginBtn(false);
        notifyError(error.response.data.message);
      }
    },
  });

  const googleSuccess = (res: any) => {
    googleLoginapi({ access_token: res.access_token, role: role })
      .then((response: any) => {
        let user: UserType = {
          ...response.data.user,
          token: response.data.token,
        };
        rememberMeFun(user);
        dispatch(login(user));
        notifySuccess("Login Successfully");
      })
      .catch((error: any) => {
        notifyError(error.response.data.message);
      });
  };

  const googleFailure = (err: any) => {
    notifyError("Google Login Failed");
  };

  const Glogin = useGoogleLogin({
    onSuccess: googleSuccess,
    onError: googleFailure,
  });

  const slides = [
    {
      imageUrl: "./images/Coach/login/slider-pic.png",
      uname: "Jane Doe",
      desig: "Intern at Nike ",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
    },
    {
      imageUrl: "./images/Coach/login/slider-pic.png",
      uname: "Jane Doe",
      desig: "Intern at Nike",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
    },
    {
      imageUrl: "./images/Coach/login/slider-pic.png",
      uname: "Jane Doe",
      desig: "Intern at Nike",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const responseFacebook = () => {
    const url = `https://dev-dsbs-api.flynautstaging.com/api/v1/auth/facebook?role=${role}`;
    window.location.href = url;
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleForget = () => {
    navigate("/forget-password");
  };

  return (
    <NotAuthenticatedLayout>
      <div className={style.section}>
        <div className={style.leftsection}>
          <div className={style.slidercontent}>
            <div className={style.slider}>
              <Carousel
                showThumbs={false}
                autoPlay={true}
                interval={3000}
                selectedItem={currentIndex}
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                infiniteLoop={true}>
                {slides.map((slide, index) => (
                  <div key={index} className={style.slider}>
                    <p className={style.slidertext}>{slide.text}</p>
                    <div className={style.profileimg}>
                      <div className={style.imgsrc}>
                        <img src={slide.imageUrl} alt={`Slide ${index + 1}`} />
                      </div>
                      <p>
                        <span id={style.uname}>{slide.uname}</span>
                        <br />
                        <span id={style.desig}>{slide.desig}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className={style.arrows}>
                <button onClick={handlePrev}>
                  <img src="./images/Coach/login/right-arrow.svg" />
                </button>
                <button onClick={handleNext}>
                  <img
                    src="./images/Coach/login/left-arrow.svg"
                    className={style.leftarrow}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={style.rightsection}>
          <div className={style.loginform}>
            <div className={style.cardplain}>
              <div className="top-logo text-start">
                <img
                  src="./images/Coach/login/color-logo.png"
                  className={style.colorpg}
                />
              </div>
              <div>
                <h4 className="font-weight-bolder" id={style.loginname}>
                  Log In to Your Account
                </h4>
                <div className={style.UserSelect}>
                  <div className={style.Userhead}>
                    <h6
                      onClick={() => handleRoleClick(1)}
                      className={`${role === 1 ? style.setliactive : ""}`}>
                      Coach
                    </h6>
                  </div>
                  <div className={style.Userhead1}>
                    <h6
                      onClick={() => handleRoleClick(2)}
                      className={`${role === 2 ? style.setliactive : ""}`}>
                      Coachee
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className={style.formbox}>
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                          name="email"
                          type="email"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          sx={{ m: 0, width: "100%" }}
                          variant="standard"
                          InputProps={{
                            endAdornment: (
                              <MailOutlineIcon
                                sx={{ color: "#B5B5BE", mr: 0.5, my: 0.5 }}
                              />
                            ),
                          }}
                        />
                      </Box>
                    </div>
                    <div className={style.formbox}>
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          label="Password"
                          sx={{ m: 0, width: "100%",cursor:'pointer' }}
                          variant="standard"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <div style={{ color: "#B5B5BE", marginRight: 1, marginBottom: 0.5,cursor:'pointer' }}  onClick={handleTogglePasswordVisibility}>
                
                                 {showPassword ? (
              <VisibilityOffIcon style={{ color: '#B5B5BE' }} />
            ) : (
              <VisibilityIcon style={{ color: '#B5B5BE' }} />
            )}
                              </div>
                              // <img src="/images/landing/lockIcon.svg"  />
                            ),
                          }}
                        />
                      </Box>
                    </div>
                    <div className={style.forgetarea}>
                      <div className={style.formcheck}>
                        <label className={style.formchecklabel}>
                          <input
                            type="checkbox"
                            className={style.checkbox}
                            checked={rememberMe}
                            onChange={(e: any) =>
                              setRememberMe(e.target.checked)
                            }
                          />
                          Remember me
                        </label>
                      </div>
                      <div className={style.forgot} onClick={handleForget}>
                        Forget Password?
                      </div>
                    </div>
                    <button
                      disabled={loginBtn}
                      value="Submit"
                      className="btn btn-lg btn-green btn-lg w-100 mt-4  mb-0 bg"
                      id={style.btngreen}

                    >Login</button>

                  </form>
                  <div className={style.instantlogin}>
                    <div className={style.title}>
                      <h4>
                        <span> Instant Login</span>
                      </h4>
                    </div>
                    <div className="button-box">
                      <button
                        className={style.btngoogle}
                        onClick={() => Glogin()}>
                        <img
                          src="./images/Coach/login/google-icon.png"
                          className={style.icons}
                        />
                        Continue with Google
                      </button>

                      <button className={style.btnfacebook} onClick={responseFacebook}>
                        <img
                          src="./images/Coach/login/facebook-icon.png"
                          className={style.icons}
                        />
                        Continue with Facebook
                      </button>
                      <Link to={"/signup"}>
                        <button
                          type="submit"
                          value="Submit"
                          className="btn btn-lg btn-green btn-lg w-100 mt-4 mb-0"
                          id={style.btngreencreate}
                          onClick={handleSignUp}>
                          Create Account
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.copyright}>
                <p>
                  <br />
                  <br />
                  CopyRight 2022{" "}
                  <img src="./images/Coach/login/copy-right.png" /> DreamSmart
                  Behavioural Solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NotAuthenticatedLayout>
  );
};

export default Login;
