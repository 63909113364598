import React from 'react'

const NotFound = () => {
  return (
    <div style={{height:'100vh', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center',}}>
       {/* <img src="./images/" */}
       <h1>404 - Page Not Found</h1>
       <p>The page you are looking for does not exist.</p>
    </div>
  )
}

export default NotFound
