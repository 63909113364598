import { useNavigate } from "react-router-dom";
import style from "./wrapper/landing.module.css";
import { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import CardSlider from "./Model/CardSlider";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  
};


const Home = ({ setActiveTab  }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?._id;
 
  const [activeImg, setActiveImg] = useState(1);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/signup");
  };
 
  const handleNavi = () => {
    navigate("/login");
  };
  // const isMinWidth789px = useMediaQuery({ query: '(max-width: 768px)' });
  
  return (
    <>
      <div className={style.HomeContainer}>
     
        <div className={style.headerContainer}>
          <header className={style.headerTxt}>

            
              <span className={style.DSHead}>Dream Smart Solution</span> lorem
              ipsom is simple dummy text that can used on that can on people
              goes under
           
              
          </header>
        </div>
        <div className={style.headerpara}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. Lorem Ipsum has been the industry standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to on people goes under takern goes on that can help
            people had make a type specimen book.
          </p>
        </div>
       
        <div className={style.headButton}>
     {cookie ? <button onClick={handleNavigate} className={style.startNowButton}>
            Continue To Home
          </button> :(<>
          <button onClick={handleNavigate} className={style.startNowButton}>
            Start Now !
          </button>
          <button onClick={handleNavi} className={style.signUpButton}>
            Login Now
          </button></>)}
        </div>
        <div className={style.navBar}>
          <ul>
            <li
              onClick={() => setActiveImg(1)}
              className={` ${activeImg === 1 && style.Activate1}`}
            >
              
              {activeImg === 1 ? (
                <img
                  src="./images/Coach/login/dashboard.svg"
                  className={style.dashIcon} alt=""
                />
              ) : (
                <img
                  src="./images/Coach/login/dashboardwhite.svg"
                  className={style.dashIcon} alt=""
                />
              )}
              <span>DashBoard</span>
            </li>
            <li
              onClick={() => setActiveImg(2)}
              className={` ${activeImg === 2 && style.Activate1}`}
            >
              
              {activeImg === 2 ? (
                <img
                  src="/images/svg/Coachee/myCoach.svg"
                  className={style.dashIcon} alt=""
                />
              ) : (
                <img
                  src=" /images/svg/Coachee/myCoachWhite.svg"
                  className={style.dashIcon} alt=""
                />
              )}
              <span>Easy Coaching</span>
            </li>
            <li
              onClick={() => setActiveImg(3)}
              className={` ${activeImg === 3 && style.Activate1}`} 
            >
              
              {activeImg === 3 ? (
                <img
                  src="./images/Coach/login/session.svg"
                  className={style.dashIcon} alt=""
                />
              ) : (
                <img
                  src="./images/Coach/login/sessionwhite.svg"
                  className={style.dashIcon} alt=""
                />
              )}
              <span>Educated Coach</span>
            </li>
            <li
              onClick={() => setActiveImg(4)}
              className={` ${activeImg === 4 && style.Activate1}`}
            >
              {activeImg === 4 ? (
                <img
                  src="/images/svg/Coachee/report.svg"
                  className={style.dashIcon} alt=""
                />
              ) : (
                <img
                  src="/images/landing/myReport.png"
                  className={style.dashIcon} alt=""
                />
              )}
              <span>My Reports</span>
            </li>
            <li
              onClick={() => setActiveImg(5)}
              className={` ${activeImg === 5 && style.Activate1}`}
            >
          
              {activeImg === 5 ? (
                <img
                  src="/images/svg/Coachee/myCertificate.svg"
                  className={style.dashIcon} alt=""
                />
              ) : (
                <img
                  src="/images/svg/Coachee/whiteMyCerti.svg"
                  className={style.dashIcon} alt=""
                />
              )}
              <span>Course Certificate</span>
            </li>
          </ul>
        </div> 
        <div className={style.activeImgContentUpper}>
          <div className={style.activeImgContent}>
            {activeImg === 1 && (
              <img
                src="/images/landing/LaptopMob.png"
                className={style.activeImg} alt=""
              />
            )}
            {activeImg === 2 && (
              <img
                src="/images/landing/LaptopMob2.png"
                className={style.activeImg} alt=""
              />
            )}
            {activeImg === 3 && (
              <img
                src="/images/landing/LaptopMob3.png"
                className={style.activeImg} alt=""
              />
            )}
            {activeImg === 4 && (
              <img
                src="/images/landing/LaptopMob4.png"
                className={style.activeImg} alt=""
              />
            )}
            {activeImg === 5 && (
              <img
                src="/images/landing/LaptopMob5.png"
                className={style.activeImg} alt=""
              />
            )}
          </div>
        </div>
        
        <div className={style.UpcomingSection}>
          <div className={style.UpComingSect1}>
            <div className={style.LeftUpcome}>
              <img src="/images/landing/UpcomingImg.png" alt=""/>
            </div>
            <div className={style.RightUpcome}>
              <h6 className={style.DsbsBlue}>Who we are</h6>
              <h3 className={style.DsbsRBlue}>
                What In The World Are
                <span className={style.DsbsBlue}>
                  <br />
                  Dream Smart Solution?
                </span>
              </h3>
              <p>
                Your Behavioral SuperPowers are what make you unique. DreamSmart
                Academy (DSA), believes everyone has a unique set of Behavioral
                Fingerprints. DSA refers to your unique fingerprints as your
                Behavioral SuperPowers.
              </p>
              <p>
                Your Behavioral SuperPowers consist of discovery exercises that
                consider one’s living environments, expectations,(good or bad),
                life experiences, education, passions, values, behavioral
                talents, communication preferences, and financial temperament.
              </p>
            </div>
          </div>
        </div>
        <div className={style.OfferContent}>
          <h2>What We Offer</h2>
          <p className={style.OfferPara}>
            Lorem Ipsum is simply dummy text of the printing and type setting
            industry. has been the industry standard dummy text ever since the
          </p>
        </div>
        <div className={style.CardContentContainer}>
          <div className={style.cardsMasterContent}>
          <div className={style.CardContent}>
            <div className={style.CardDesign1} data-id="Card1">
              <img
                src="./images/Coach/login/sessionwhite.svg"
                className={style.OfferICon} alt=""
              />
              <h6>Find Favorites Coaches</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card2">
              <img
                src=" /images/svg/Coachee/myCoachWhite.svg"
                className={style.OfferICon} alt=""
              />
                <h6>Schedule appointment</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card1">
              <img
                src="/images/landing/myReport.png"
                className={style.OfferICon} alt=""
              />
              <h6>Join Webinars</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
            <div className={style.CardDesign1} data-id="Card2">
              <img
                src="/images/svg/Coachee/whiteMyCerti.svg"
                className={style.OfferICon} alt=""
              />
              <h6>Get your Certificate</h6>
              Lorem Ipsum is simply dummy text of the printing and type setting
              industry. has been the industry standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled
              it to on people goes under takern goes on that can help people had
              make a type an
            </div>
          </div>
        </div>
        <div className={style.WhatContent}>
          <div className={style.WhatInnerContent}>
            <p className={style.WhatInnerp}>How it works?</p>
            <h2>What's in Dream Smart Solution</h2>
            <div className={style.WhatFeature}>
              <div className={style.WhatFeatureContent}>
              
              <div className={style.WFDiv}>
                    <h3 className={style.IndexNumber}> 1.</h3>
                    <h4>
                      Loram ipsom is simple dummy text that can goes people on
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry. has been the industry standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to on people goes under
                      takern goes on that can help people had make a type an had
                      printer took a galley of type and scrambled it to on
                      people goes under takern goes on that can specimen to on
                      people goes under takern goes on that book.
                    </p>
                  </div>
                  <div className={style.WFDiv}>
                    <img src="/images/landing/personalInfo.png" alt=""/>
                  </div>

              </div>
              <div className={style.WhatFeatureContent}>
              <div className={style.WFDiv}>
                    <h3 className={style.IndexNumber}> 2.</h3>
                    <h4>
                      Loram ipsom is simple dummy text that can goes people on
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry. has been the industry standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to on people goes under
                      takern goes on that can help people had make a type an
                      unknown printer took a galley of type and scrambled it to
                      on people goes under takern goes on that can help people
                      had printer took a galley.
                    </p>
                    <img src="/images/landing/coachShow.png" alt=""/>
                  </div>
                  <div className={style.WFDiv}>
                    <h3 className={style.IndexNumber}> 3.</h3>
                    <h4>
                      Loram ipsom is simple dummy text that can goes people on
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry. has been the industry standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to on people goes under
                      takern goes on that can help people had make a type an
                      unknown printer took a galley of type and scrambled it to
                      on people goes under takern goes on that can help people
                      had printer took a galley.
                    </p>
                    <img src="/images/landing/CoachDetail.png" alt=""/>
                  </div>
             
              </div>
              <div className={style.WhatFeatureContent}>
                  <div className={style.WFDiv}>
                    <img src="/images/landing/SchedApp.png" alt=""/>
                  </div>
                  <div className={style.WFDiv}>
                    <h3 className={style.IndexNumber}> 4.</h3>
                    <h4>
                      Loram ipsom is simple dummy text that can goes people on
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry. has been the industry standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to on people goes under
                      takern goes on that can help people had make a type an
                      unknown printer took a galley of type and scrambled it to
                      on people goes under takern goes on that can help people
                      had printer took a galley.
                    </p>
                  </div>
                  </div>
                  <div className={style.WhatFeatureContent}>
                 
                  <div className={style.WFDiv}>
                    <h3 className={style.IndexNumber}> 5.</h3>
                    <h4>
                      Loram ipsom is simple dummy text that can goes people on
                    </h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and type
                      setting industry. has been the industry standard dummy
                      text ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to on people goes under
                      takern goes on that can help people had make a type an
                      unknown printer took a galley of type and scrambled it to
                      on people goes under takern goes on that can help people
                      had printer took a galley.
                    </p>
                  </div>
                  <div className={style.WFDiv}>
                    <img src="/images/landing/CallAttend.png" alt=""/>
                  </div>
                  </div>
            </div>
            </div>
          </div>
        </div>
        <div className={style.SimpleSoft}>
          <div className={style.SimpleSoftContent}>
            <div className={style.SimpleSoftLeft}>
              <h3>Simple Software that is easy to use</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and type
                setting industry has been the industry standard dummy text of
                the that to printing and type setting industry text ever since
              </p>
              <div className={style.TotSimpleSoft}>
                
                <span>
                  <h2 className={style.WhatInnerp}>+45k</h2>
                  <p>Satisfied Global Users</p>
                </span>
                <span>
                  <h2 className={style.WhatInnerp}>+185k</h2>
                  <p>Sessions Completed</p>
                </span>
              </div>
            </div>
            <div className={style.SimpleSoftRight}>
              <img src="/images/landing/AttendSched.png" alt=""/>
            </div>
          </div>
        </div>
        <div>
          <CardSlider/>
        </div>
      </div>
   
    </>
  );
};

export default Home;
