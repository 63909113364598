import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  isError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { isError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { isError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Caught Error At ErrorBoundary", error, errorInfo);
  }

  render() {
    if (this.state.isError) {
      //console.log("Caught Error");
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
