
import styles from "./programs.module.css";
import style from "../wrapper/wrapper.module.css";
import TimerIcon from '@mui/icons-material/Timer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { Box, duration, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import TextArea from "antd/es/input/TextArea";
import { CreateOffSession, DeleteChapter, DeleteProgram, fetchChapter, fetchPrgId, UpdateProgram, UpdateSession } from "../../../../Api";
import { notifyError, notifySuccess } from "../../../../Notify";
import dayjs from "dayjs";
import { Cancel } from "@mui/icons-material";
type propType = {
  setActivescreen: (val: (v: number) => number) => void;
  programId: any;

};
interface NewUser {
  upImg: File | null;
  
}
const OfflineProgram = ({ setActivescreen, programId }: propType) => {
  const [program, setProgram] = useState<string[]>([]);
  const [pname, setPname] = useState("");
  const [overcost, setOvercost] = useState("")
  const [createdAt, setCreate] = useState("");
  const [desc, setDesc] = useState("")
  const [coachNames, setCoachNames] = useState<string[]>([]);
  const [programImg, setprogramImg] = useState('')
  const [previewImage, setPreviewImage] = useState("");
  //  title,expected,durat,resource
  const [title, setTitle] = useState("");
  const [expected, setExpected] = useState("")
  const [durat, setDurat] = useState("")
  const [cId, setCid] = useState('')
  const [resource, setResource] = useState('')
  const [open1, setOpen1] = useState(false);
  const [updata, setUpdata] = useState(false)
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [open, setOpen] = useState(false);
  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false)
  const [chapter, setChapter] = useState<string[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState('');
  const [overallCost, setoverallCost] = useState("");
  const [disCost, setdisCost] = useState('');
  const [distCost, setdistCost] = useState('');
  const [ptype, setPtype] = useState('')
  const user = useSelector((state: RootState) => state.user.user);
  //console.log("profile", user?.profileSetup);
  const cookie: any = user?.token;
  const [newUser, setNewUser] = useState<NewUser>({
     upImg: null
    });
    const [data, setData] = useState({
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      history: [],
    });
    const [modalData, setModalData] = useState({
      title: "",
      desc: "",
      duration: "",
      createdAt: ""
    });
    const handleProfile = (e: any) => {
      const file = e.target.files[0];
      //console.log("files", file);
      setNewUser({ ...newUser, upImg: file });
  
      const imageURL = URL.createObjectURL(file);
      setPreviewImage(imageURL);
    };
    const handleProfileClick = () => {
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.click();
      }
    };
  useEffect(() => {
    fetchPrgId(cookie, programId)
      .then((result: any) => {

        setProgram(result.data.history);
        setPname(result.data.name);
        setName(result.data.name);
        setOvercost(result.data.overallCost);
        setCreate(result.data.createdAt.substring(0, 10));
        setDesc(result.data.description);
        setCoachNames(result.data.coacheIds);
        setprogramImg(result.data.programImg)
        setDescription(result.data.description)
        setoverallCost(result.data.overallCost)
        setPtype(result.data.program_type)
        setdisCost(result.data?.disCost)
        setdistCost(result.data?.disCost)
       

      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);

      });
 
  }, [updata]);

  useEffect(() => {
    fetchChapter(programId, cookie)
      .then((result: any) => {
        setChapter(result.data.history)
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);

      });
  }, [cookie,updata])
  const handleOpening = (
    e: any,
    title: any,
    desc: any,
    duration: any,
    cDate: any
  ) => {
    e.preventDefault();
    handleOpen2();
    setModalData({ title, desc, duration, createdAt: cDate })



  };
  
  const handleDeleteProg = (e: any) => {
    e.preventDefault()
    DeleteProgram(programId, cookie)
      .then((res: any) => {
        notifySuccess(res.message || 'program deleted Sucessfully')
        handleClose5()
        setActivescreen(() => 1)
      })
      .catch((err: any) => {
        notifyError(err.response.data.message || 'Program not found !')
        handleClose5()
      })
  }
  const DeleteSessionByID = (e: any) => {
    e.preventDefault()
    setUpdata(true)
    DeleteChapter(cId, cookie)
      .then((res: any) => {
        notifySuccess(res.message)
        setUpdata(false)
        handleClose4()
      })
      .catch((err: any) => {
        notifyError(err.response?.data.message)
        handleClose4()
        setUpdata(false)
      })
  };
 
  const handlSubmit = (e: any) => {
    e.preventDefault();
    setUpdata(true)
    const formData=new FormData();
    formData.append("pId", programId)
    formData.append("ptype", ptype);
    formData.append("name",name||pname);
    formData.append("overallCost", overallCost||overcost);
    formData.append("disCost",distCost || disCost);
    formData.append("programImg",newUser.upImg||programImg);
    formData.append("description",description)
    UpdateProgram(formData,cookie)
    .then((res:any)=>{
      //console.log(res)
      notifySuccess("Program details updated successfully")
      setUpdata(false)
      handleClose()
      
    })
    .catch((err:any)=>{
      notifyError(err?.response?.data.message)
      setUpdata(false)
      handleClose()
    })
  }
  const handleTypoSubmit = (e: any) => {
    e.preventDefault();
    setUpdata(true)
    CreateOffSession(programId, title, expected, durat, resource, cookie)
    .then((res: any) => {
        setUpdata(false)       
        handleClose1()
        setTitle('')
        setExpected('')
        setDurat('')
        setResource('')

        notifySuccess(res.data.message)
      })

    //console.log("create Chapter Data is ", programId, title, expected, durat, resource)
    setUpdata(false)
  }
  const updatehandleUpdate = (e: any, cId: any, title: any,
    desc: any,
    duration: any,
    cDate: any) => {
    e.preventDefault()
    handleOpen3();
    setCid(cId)
    setModalData({ title, desc, duration, createdAt: cDate })
  }
  const handleUpdate = (e: any) => {
    e.preventDefault()
    setUpdata(true)
    UpdateSession(cId, programId, modalData.title, modalData.desc, modalData.duration, 1, cookie)
    .then((res: any) => {
        setUpdata(false)
        notifySuccess(res.data.message||"data updated sucessfully")
        handleClose3()
      })
      .catch((err: any) => {
        notifyError(err.response?.data?.message)
        setUpdata(false)
        handleClose3()
      })
   
  }

  const getDeleteSession = (e: any, cId: any) => {
    e.preventDefault()
    handleOpen4()
    setCid(cId)
  }
  return (<>
    <div className={styles.recorded}>

      <div className={styles.EditLeft}>
        {programImg ? (<img src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`} className={styles.EditData} />) :
          (<p className={styles.EditData}>{pname.slice(0, 2)}</p>
          )}
        <div className={styles.EditLeftData}>
          <h5 style={{fontSize:'25px'}}>{pname}</h5>
          <p className={styles.EditLeftDataP1} style={{display:'flex',marginRight:'5px',fontWeight:'500'}}><TimerIcon style={{marginRight:'5px'}}/> {dayjs(createdAt).format('DD MMM,YYYY')} </p>

          <p className={styles.EditLeftDataP2} style={{display:'flex',marginRight:'5px',fontWeight:'500'}}><MonetizationOnIcon />{disCost?<><del style={{color:'gray',marginRight:'5px',marginLeft:'5px'}}>{overcost}</del> {disCost}</>: overcost} </p>
        </div>
      </div>
      <div className={styles.EditRightData}>


        <div className={style.addButton}>
          <div className={styles.upgradeButton}>

            <button className={styles.EditRightBtn1} onClick={handleOpen}><BorderColorIcon />Edit</button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  <h3> Edit Program</h3>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className={style.typoDesc}
                >
                  <div className={style.ProgramProp}>
                    <div className={style.onlinePro} >
                    <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleProfile}
                          id="fileInput"
                          name="profile"
                        />

                        {!previewImage ? (
                          <>
                            <img
                              src={`https://dxe2g9i1k0e2c.cloudfront.net/${programImg}`}
                              className={style.progProfile}
                            />
                            <div className={style.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={previewImage}
                              alt="Preview"
                              className={style.MainImgPre}
                            />
                            <div className={style.IconImg}>
                              <img
                                src="/images/svg/Coach/group-8291.svg"
                                onClick={handleProfileClick}
                              />
                            </div>
                          </>
                        )}

                      <form className={style.programForm} onSubmit={handlSubmit}>
                        <TextField
                          type="text"
                          id="outlined-basic"
                          label="Program Topic"
                          variant="outlined"
                          name="name"
                          value={name}
                          onChange={(e: any) => setName(e.target.value)}
                          className={style.Inputfield}
                          InputProps={{
                            className: `${style.muiInput}`,

                          }}
                          sx={{
                            my: 1,
                            "& .MuiInputBase-input": {
                              width: "100%",

                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                        />

                        <TextArea
                          id="outlined-basic"
                          placeholder="Description"
                          name="description"
                          value={description}
                          onChange={(e: any) => setDescription(e.target.value)}

                          rows={5}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          label="Overall Cost"
                          variant="outlined"

                          name="overallCost"
                          value={overallCost}
                          onChange={(e: any) => setoverallCost(e.target.value)}
                          className={style.Inputfield}
                          InputProps={{
                            className: `${styles.muiInput}`,
                          }}
                          sx={{

                            my: 1,
                            borderRadius: "20px",
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          label="Discount Cost"
                          variant="outlined"
                          name="distCost"
                          value={distCost}
                          onChange={(e: any) => setdistCost(e.target.value)}
                          className={style.Inputfield}
                          InputProps={{
                            className: `${styles.muiInput}`,
                          }}
                          sx={{

                            my: 1,
                            borderRadius: "20px",
                            "& .MuiInputBase-input": {
                              width: "100%",
                            },
                            "& .MuiInputLabel-root": {
                              backgroundColor: "#fff",
                            },
                          }}
                        />
                        <button className={style.SaveButton}>
                          Update

                        </button>
                        <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                        Cancel</button>
                      </form>

                    </div>


                  </div>
                </Typography>

              </Box>
            </Modal>
          </div>
        </div>
        <button className={styles.EditRightBtn2} style={{marginRight:'30px'}} onClick={handleOpen5}>
            <DeleteIcon />
            Delete
          </button>
          <Modal
            open={open5}
            onClose={handleClose5}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: "scroll", overflowY: "scroll" }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <h3> Delete Program</h3>
              </Typography>
              <Typography id="modal-modal-description" className={style.typoDesc}>
                Are you Sure delete Program?<br />
                <button className={styles.EditRightBtn2} onClick={handleDeleteProg}>
                  <DeleteIcon />
                  Delete
                </button>
                <button className={styles.EditRightBtn3} onClick={handleClose5}>
                  <CloseTwoToneIcon />
                  Cancel
                </button>

              </Typography>
            </Box>
          </Modal>
      </div>

    </div>
    <div className={styles.EditDescription}>
      <hr />
      <br /><br />
      <h5>Description</h5>

      <p>{desc}
      </p>
    </div>

    <div className={styles.recorded}>
      <h5>Total Session</h5>
      <button className={styles.EditRightBtn1} onClick={handleOpen1}><AddBoxSharpIcon /> Add New Session</button>
      <Modal
        open={open}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
      >
        <Box className={style.BoxStyle}>
          <span onClick={handleClose1} className={style.HandleClose}>
            <CloseTwoToneIcon className={style.HandleCloseIcon} />
          </span>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            <h3> Create New Session</h3>
          </Typography>
          <Typography
            id="modal-modal-description"
            className={style.typoDesc}
          >
            <div className={style.ProgramProp}>
              <div className={style.onlinePro} >

                <form className={style.programForm} onSubmit={handleTypoSubmit}>
                  <TextField
                    type="text"
                    id="outlined-basic"
                    label="Session Topic"
                    variant="outlined"
                    name="name"
                    value={title}
                    onChange={(e: any) => setTitle(e.target.value)}
                    className={style.Inputfield}
                    InputProps={{
                      className: `${style.muiInput}`,

                    }}
                    sx={{
                      my: 1,
                      "& .MuiInputBase-input": {
                        width: "100%",

                      },
                      "& .MuiInputLabel-root": {
                        backgroundColor: "#fff",
                      },
                    }}
                  />

                  <TextArea
                    id="outlined-basic"
                    placeholder="What are you going to learn"
                    name="description"

                    value={expected}
                    onChange={(e: any) => setExpected(e.target.value)}

                    rows={5}
                  />
                  <TextField
                    type="number"
                    id="outlined-basic"
                    label="Expected Duration of Session (Hours)"
                    variant="outlined"
                    name="overallCost"
                    value={durat}
                    onChange={(e: any) => setDurat(e.target.value)}
                    className={style.Inputfield}
                    InputProps={{
                      className: `${styles.muiInput}`,
                    }}
                    sx={{

                      my: 1,
                      borderRadius: "20px",
                      "& .MuiInputBase-input": {
                        width: "100%",
                      },
                      "& .MuiInputLabel-root": {
                        backgroundColor: "#fff",
                      },
                    }}
                  />

                  <div className={styles.Filetext}>
                    <FileCopyIcon className={styles.FileIcon} />
                    <h6>Upload File/Video</h6>
                    <p className={styles.Filep}>Lorem ipsom is simple dummy text that can people can that <br /> can under tool so can goes <br />
                      dotted on hiligter ipsom so not needed.</p>
                    {/* <TextField
            type="file"/> */}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleProfile}
                      id="fileInput"
                      name="profile"
                      multiple
                    />
                    <p className={style.SaveButton1} onClick={handleProfileClick}>{previewImage ? newUser.upImg ? newUser.upImg.name :'ReUpload File' : <>File Select</>}</p>
                    
                    <p>{previewImage ? <>File Selected</> : <> File Select</>}</p>
                  </div>
                  <button className={style.SaveButton}>
                    Save

                  </button>

                </form>

              </div>


            </div>
          </Typography>

        </Box>
      </Modal>

    </div>
    <br />
    <table>
      <tr>
        <th>SESSION TOPIC</th>
        <th>WHAT ARE WE GOING TO LEARN</th>
        <th>SESSION DURATION</th>
        <th>DATE</th>
        <th></th>
      </tr>

      {chapter.length > 0 ? (
        chapter.map((lesson: any, index: number) => (<>
          <tr> <td style={{ textAlign: 'left' }}><span className={styles.groupIc}> {(lesson.title.split(" ").length > 1 ? (lesson.title.split(" ").map((word: any) => word.charAt(0)).join("").substring(0, 2)) : (lesson.title.slice(0, 2)))}</span> {lesson.title} </td>
            <td>{lesson.expected.slice(0,16)}</td>
            <td>{lesson.duration}</td>
            <td>{dayjs(lesson.createdAt.substring(0, 10)).format('DD MMM YYYY')}</td>
            <td className={styles.editIcon}>

              <img src="/images/svg/Admin/viewIcon.svg" onClick={(e:any)=>handleOpening(e,lesson.title,lesson.expected,lesson.duration,lesson.createdAt)}/>
              <img src="/images/svg/Admin/EditIcon.svg" onClick={(e:any)=>updatehandleUpdate(e,lesson._id,lesson.title,lesson.expected,lesson.duration,lesson.createdAt)}/>
              <img src="/images/svg/Admin/deletIcon.svg" onClick={(e:any)=>getDeleteSession(e,lesson._id)}/>
            </td>
          </tr>
        </>
        ))
      ) : (
        <p>No coach data available</p>
      )}
 <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose2} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <h3> About Program</h3>
            </Typography>
            <Typography
              id="modal-modal-description"
              className={style.typoDesc}
            >
              <div className={style.sessionModalInfo}>

                <h6>Session Title</h6>  <p>{modalData.title}</p>
              </div>
              <div className={style.sessionModalInfo}>

                <h6>Session Duration</h6>  <p>{modalData.duration} Hrs</p>
              </div>
              <div className={style.sessionModalInfo}>

                <h6>Session Description</h6>  <p className={modalData.desc.length > 50 ? style.sessionModalInfoDesc1 : style.sessionModalInfoDesc}>{modalData.desc}</p>
                <button  onClick={handleClose} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                Cancel</button>
              </div>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Box className={style.BoxStyle}>
            <span onClick={handleClose3} className={style.HandleClose}>
              <CloseTwoToneIcon className={style.HandleCloseIcon} />
            </span>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              <h3> Edit Session</h3>
            </Typography>
            <Typography id="modal-modal-description" className={style.typoDesc}>
              <div className={style.ProgramProp}>
                <div className={style.onlinePro}>
                  <form className={style.programForm} onSubmit={handleUpdate}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Session Topic"
                      variant="outlined"
                      name="title"
                      value={modalData.title}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          title: e.target.value,
                        }))}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${style.muiInput}`,
                      }}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <TextArea
                      id="outlined-basic"
                      placeholder="What are you going to learn"
                      name="description"
                      value={modalData.desc}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          desc: e.target.value,
                        }))}
                      rows={5}
                    />
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Expected Duration of Session (Hours)"
                      variant="outlined"
                      name="duration"
                      value={modalData.duration}
                      onChange={(e: any) =>
                        setModalData((prevState) => ({
                          ...prevState,
                          duration: e.target.value,
                        }))}
                      className={style.Inputfield}
                      InputProps={{
                        className: `${styles.muiInput}`,
                      }}
                      sx={{
                        my: 1,

                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />

                    <button className={style.SaveButton}>Update</button>
                    <button  onClick={handleClose3} id="cancelTypo" value="cancel" style={{backgroundColor:'#0B3269',width:'100%',color:'white',borderRadius:'10px',padding:'10px',border:'none'}} >  
                    Cancel</button>
                  </form>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
        <div className={style.upgradeButton}>

          <Modal
            open={open4}
            onClose={handleClose4}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
          >
            <Box className={style.BoxStyle}>
              <span onClick={handleClose4} className={style.HandleClose}>
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                <div className={style.thisData}>
                  <h5> Delete Session</h5>

                </div>

              </Typography>
              <Typography id="modal-modal-description"
              >

                Are you sure Delete Session? <br />
                <button className={styles.delete} onClick={(e: any) => DeleteSessionByID(e)}><DeleteIcon /> Delete </button>
                <button className={styles.DontDelete} onClick={handleClose4}><Cancel /> Cancel </button>
              </Typography>
            </Box>
          </Modal>
        </div>
    </table>
  </>)
}
export default OfflineProgram;
