import { useEffect, useState } from "react";
import style from "../../../../styles/setting.module.css";
import { administrationPatch, FetchCoachInfo } from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError, notifySuccess } from "../../../../Notify";
const SetTab4 = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [data, setData] = useState({
    Mprocess: "",
    Wprocess: "",
  });
  const[updata,setUpdata]=useState(false)

  const fetchCoachDetails = () => {
    FetchCoachInfo(cookie)
      .then((result: any) => {
        const { Mprocess, Wprocess }: any = result.data;
        setData({ Mprocess, Wprocess });
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchCoachDetails();
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,  
    }));
  };

  const handlSubmit=(e:any)=>{
    setUpdata(true)
    e.preventDefault()
    administrationPatch(data.Mprocess,data.Wprocess,cookie)
    .then((res:any)=>{
      notifySuccess("Data is Updated Successfully!")
      setUpdata(false)
    })
    .catch((err:any)=>{
      notifyError(err.response.data.message)
      setUpdata(false)
    })

  }
  return (
    <>
      <div className={style.tabcontainer}>
        <div className={style.tabheader}>Administration</div>
        <form onSubmit={handlSubmit}>
        <div className={style.tab4container}>
          <textarea
            placeholder="Process to maintain client confidentiality"
            value={data.Mprocess} onChange={handleChange}  name="Mprocess" ></textarea>

          <textarea
            placeholder="What process do you use to follow timely contractor required submissions (i.e., invoices, reports…)style"
            value={data.Wprocess} onChange={handleChange}  name="Wprocess" ></textarea>
        </div>
        <div className={style.formbutton}>
          <button type="submit" disabled={updata}>{updata==true?'Loading..':'Update'} </button>
        </div>
        </form>
      </div>
    </>
  );
};

export default SetTab4;
