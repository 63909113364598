import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import dayjs from "dayjs";
import { cdn_Link } from "../../../config";
import { FetchProgramsCoach } from "../../../Api";
import style from "../../../styles/program.module.css";
import styles from "../Coachee/CSS/dashboardHome.module.css"
import ProgramById from "./programs/ProgramById";
import Pagination from "../../Pagination/Pagination";

const Programs = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [pid,setProgId]=useState('')
const[activeTab,setActiveTab]=useState(0);
const[coacheelist,setcoacheeList]=useState<any>([])
const [search, setSearch] = useState("");
const[coacheeData,setCoacheeData]=useState<any>([])
const [myTimeOut, setMytimeOut] = useState<NodeJS.Timeout | null>(null);
  const [programData, setprogramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchProgram = () => {
    FetchProgramsCoach({
      cookie,
      page: programData.page,
      search: search,
    })
      .then((result: any) => {
        let res = result.data;
        //console.log(res)
        setCoacheeData(res.history)
        setcoacheeList(res.history)
        let x: any = { ...programData };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setprogramData(x);
      })
      .catch((error: any) => {
        //console.log(error);
      });
  };
  const handleSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    if (myTimeOut) clearTimeout(myTimeOut);
    setMytimeOut(
      setTimeout(() => {
        fetchProgram();
      }, 500)
    );
  };


  const decreasePage = () => {
    setprogramData({ ...programData, page: programData.page - 1 });
  };

  const IncreasePage = () => {
    setprogramData({ ...programData, page: programData.page + 1 });
  };
  useEffect(() => {
    fetchProgram();
  }, [cookie,search,programData.page]);


  return (
    <>
  {activeTab==0 && (<>  <div className={style.ProContainer}>
        <input
            className={style.container2}
            type="text"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Which Program are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
            <div className={styles.DashForRecom}>
        <div className={styles.Recommond}>
        {programData.history.length > 0 ?
          programData.history.map((pdata: any) => (
            <div className={styles.coaching}>
              <img
                src={`${cdn_Link}/${
                  pdata.programImg || pdata.Program_Details?.programImg
                }`}
                className={styles.coachingImg}
              />

              <div className={styles.coachingTxt} onClick={()=>{setProgId(pdata._id || pdata.Program_Details?._id);setActiveTab(1);}}>
                <h6
                  style={{
                    fontWeight:'light',
                    fontSize: "20px",
                   
                  }}>
                 {pdata.name || pdata.Program_Details?.name}
                </h6>
                <p style={{fontWeight:'normal',color:'gray',fontSize:'13px'}}>{pdata.description.slice(0,90) || pdata.Program_Details?.description.slice(0,90)}</p>
                <div>
                  <p style={{ color: "#0C5899",position:'absolute',bottom:0 }}>
                    <CheckCircleIcon />{" "}
                    {dayjs(pdata.createdAt.slice(0, 10)).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
            </div>
          )):'No Programs Available!'}
      </div></div>
  <div style={{bottom:0,position:'absolute',right:0,margin:'20px'}}>
  <Pagination
          next={programData.next}
          page={programData.page}
          prev={programData.prev}
          decreasePage={decreasePage}
          IncreasePage={IncreasePage}
          total_page={programData.total_page}
        />
  </div>
  
   
          </div>
    </>)}
    {activeTab==1 && (<ProgramById setActiveTab={setActiveTab} pid={pid} coacheeData={coacheeData}/>)}
    </>
  );
};
export default Programs;
